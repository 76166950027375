import axios from "axios";
import { getToken } from "../../helpers/get-token";

export default async function loadMultipleImages(imageIds) {
    if (!Array.isArray(imageIds) || imageIds.length === 0) {
        console.error("Invalid or empty image ID array");
        return;
    }

    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;

        const requestBody = { ids: imageIds };

        const response = await axios.get(
            process.env.REACT_APP_HUB_URL + '/api/files/images/by-ids',
            {
                params: requestBody,
                paramsSerializer: {
                    indexes: true,
                }
            },

        );

        const fetchedUrls = response.data.data
        return fetchedUrls;
    } catch (error) {
        console.error("Error loading images:", error);
    }
}
import React from 'react';
import { mixpanelInstance } from "../../mixpanel/instance";
import { Button } from 'react-bootstrap';

const TrackableButton = ({ onClick, campaignId, buttonName, children, ...props }) => {
  const handleClick = (e) => {
    mixpanelInstance.track('Button Click', {
        buttonName, 
        campaignId,
    });
    if (onClick) onClick(e);
  };

  return (
    <Button onClick={handleClick} {...props}>
      {children}
    </Button>
  );
};

export default TrackableButton;

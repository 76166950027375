import { convertToCamelCaseArray } from "./convert-to-camel-case";
import { createColumnDefinitionsClusterPPC } from "./create-column-definitions-cluster-ppc";

export async function getServerSideDatasourceClusterPPC(getData, setColumns, search, customColumns, campaignId) {    
    const response = await getData({ search }, campaignId);
    
    if (search) {
        setTimeout(() => {
            if (response.success) {
                const transformedData = convertToCamelCaseArray(response.data);
                const classifiedColumns = response.data.columns;
                const columnDefs = createColumnDefinitionsClusterPPC(classifiedColumns, customColumns);

                if (columnDefs) {
                    setColumns(columnDefs);
                }

                let rows = transformedData //buildTreeBasedOnPath(transformedData);

                return rows;
            } else {
                return [];
            }
        }, 200);
    } else {
        if (response.success) {
            const transformedData = convertToCamelCaseArray(response.data);
            const classifiedColumns = response.data.columns;
            const columnDefs = createColumnDefinitionsClusterPPC(classifiedColumns, customColumns);

            if (columnDefs) {
                setColumns(columnDefs);
            }

            let rows = transformedData //buildTreeBasedOnPath(transformedData);

            return rows;
        } else {
            return [];
        }
    }
};

import React from "react";
import RecordCreate from "../../components/RecordCreate";

export default function InventoryCampaignCreate() {
  return (
    <RecordCreate
        title="Create Inventory Campaign"
        table_name="inventory_campaigns"
        breadcrumb="Queue"
        redirect="/pub/inventory/queue"
        item_name="Inventory Campaign"
    />
  )
}
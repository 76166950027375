import React, { useEffect, useState, useLayoutEffect } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link, useLocation } from "react-router-dom";
import { Row } from "react-bootstrap";
import { startOfDay, endOfDay, addDays, subDays } from "date-fns";
import AdvertiserTotals from "./campaign/CampaignTotals";
import CampaignImpressionsAndClicks from "./campaign/CampaignImpressionsAndClicks";
import CampaignInventoryStats from "./campaign/CampaignInventoryStats";
import CampaignMap from "./campaign/CampaignMap";
import DatePicker from "../../pub/components/DatePicker";
import { verifyCustomerToken } from "../../services/micro";
import { modules } from "../../modules";
import { blankPageMap } from "../../modules/maps";
import { getCampaign } from "../../services/adv";
import { useSelector } from "react-redux";
import TrackableButton from "../../mixpanel/components/TrackableButton";
import { mixpanelInstance } from "../../mixpanel/instance";
import { CookiesKeyNames, isCookiesEnabled, useCookiesContext } from "../../contexts/CookiesContext";
import Cookies from "universal-cookie";

const Ranges = [
  {
    label: "today",
    value: [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    label: "yesterday",
    value: [
      startOfDay(addDays(new Date(), -1)),
      endOfDay(addDays(new Date(), -1)),
    ],
  },
  {
    label: "Last 30 Days",
    value: [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())],
  },
];

const PUBLISHER = "PUBLISHER";
const HTR_PUBLISHER_ID = 67;
const HTR_STAGE_PUBLISHER_ID = 10;

export default function AdvertiserAnalytics({ isIframe }) {
  const location = useLocation();
  const [campaignId, setCampaignId] = useState(new URLSearchParams(location.search).get("campaignId"));
  const isIframed = window !== window.parent;

  const user = useSelector((state) => state.user);
  const advertiser = useSelector((state) => state.advertiser);
  const userRole = user?.role;
  const [publisherId, setPublisherId] = useState(null);
  const token = new URLSearchParams(location.search).get("token");
  const [campaign, setCampaign] = useState({});
  const { updateCookiesProperty, cookiesData } = useCookiesContext();
  const cookies = new Cookies();

  const [currentPeriod, setCurrentPeriod] = useState([null, null]);
  const [previousPeriod, setPreviousPeriod] = useState([null, null]);

  useLayoutEffect(() => {
    getCampaign(campaignId).then(res => {
      setCampaign(res.data);
    });
  }, [campaignId]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setCampaignId(urlParams.get("campaignId"));
  }, [location.search]);

  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const [isTokenVerified, setIsTokenVerified] = useState(null);

  const loadDateRange = () => {
    const storedCurrentPeriod = isCookiesEnabled ? cookiesData[CookiesKeyNames.currentPeriod] : JSON.parse(cookiesData[CookiesKeyNames.currentPeriod]);
    const storedPreviousPeriod = isCookiesEnabled ? cookiesData[CookiesKeyNames.previousPeriod] : JSON.parse(cookiesData[CookiesKeyNames.previousPeriod]);

    // Parse dates from cookies, if available
    if (storedCurrentPeriod && storedPreviousPeriod) {
      return {
        currentPeriod: storedCurrentPeriod.map(date => new Date(date)),
        previousPeriod: storedPreviousPeriod.map(date => new Date(date)),
      };
    }

    // Fallback to the last 30 days if nothing is stored in cookies
    const defaultCurrentPeriod = [subDays(new Date(), 30), new Date()];
    const defaultPreviousPeriod = [subDays(new Date(), 60), subDays(new Date(), 30)];
    return {
      currentPeriod: defaultCurrentPeriod,
      previousPeriod: defaultPreviousPeriod,
    };
  };


  useEffect(() => {
    if (token) {
      verifyCustomerToken(token).then((response) => {
        if (response && response.success) {
          updateCookiesProperty(CookiesKeyNames.token, token);
          setPublisherId(response?.customer?.publisher_id);

          const pubId = cookiesData[CookiesKeyNames.publisherId];
          if (!pubId) {
            updateCookiesProperty(CookiesKeyNames.publisherId, response?.customer?.publisher_id);
            window.location.reload();
          }

          setIsTokenVerified(true);
        } else {
          setIsTokenVerified(false);
        }
      });
    }
  }, [token]);

  useEffect(() => {
    const { currentPeriod, previousPeriod } = loadDateRange();
    setCurrentPeriod(currentPeriod);
    setPreviousPeriod(previousPeriod);
  }, []);

  useEffect(() => {
    if (currentPeriod && currentPeriod.length === 2 && currentPeriod[0] && currentPeriod[1]) {
      updateCookiesProperty(CookiesKeyNames.currentPeriod, currentPeriod);
      updateCookiesProperty(CookiesKeyNames.previousPeriod, previousPeriod);
    }
  }, [currentPeriod, previousPeriod]);


  const formatDate = (date) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Intl.DateTimeFormat("en-UK", options).format(date);
  };

  const handleOpenDateRangePicker = () => {
    setIsDateRangePickerOpen(true);
  };

  const handleDateRange = (e) => {
    const curBegin = new Date(e[0]);
    const curEnd = new Date(e[1]);

    curEnd.setHours(23, 59, 59, 999);

    const diff = curEnd - curBegin;

    const prevBegin = new Date(curBegin - diff);
    const prevEnd = new Date(curEnd - diff);

    setPreviousPeriod([prevBegin, prevEnd]);
    setCurrentPeriod([curBegin, curEnd]);

    mixpanelInstance.track('Date Range Change', {
      currentPeriodFrom: JSON.stringify(curBegin),
      currentPeriodTo: JSON.stringify(curEnd),
      campaignId,
    });
  };

  const currentSkin = cookiesData[CookiesKeyNames.skinMode] ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  if (isIframe && isTokenVerified === false) {
    return modules(10, blankPageMap);
  }

  if (isIframe && !campaignId) {
    return modules(10, blankPageMap);
  }

  if ((isIframe && isTokenVerified === true) || !isIframe) {
    let customStyle = {};

    if (publisherId === HTR_PUBLISHER_ID || publisherId === HTR_STAGE_PUBLISHER_ID) {
      customStyle = {
        paddingLeft: "46px",
        paddingRight: "46px",
        paddingTop: "15px",
        paddingBottom: "15px",
      };
    }

    return (
      <React.Fragment>
        {isIframe ? <></> : <Header onSkin={setSkin} />}

        <div className={isIframe ? "" : "main main-app p-3 p-lg-4"} style={isIframe ? customStyle : {}}>
          <div
            className={
              isIframe
                ? "d-flex align-items-center justify-content-end mb-4"
                : "d-flex align-items-center justify-content-between mb-4"
            }
          >
            {isIframe ? (
              <></>
            ) : (
              <div>
                <ol className="breadcrumb fs-sm mb-1">
                  <li className="breadcrumb-item">
                    <Link href="/adv/home" to={"/adv/home"}>ADV Home</Link>
                  </li>
                  <li className="breadcrumb-item">{campaign?.name}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Campaign Analytics
                  </li>
                </ol>
                <h2 className="main-title mb-0">Campaign Analytics</h2>
              </div>
            )}

            <div className="d-flex gap-2 mt-3 mt-md-0">
              <TrackableButton
                variant="btn-outline-primary"
                className={`d-flex align-items-center gap-2 btn btn-outline-primary`}
                onClick={handleOpenDateRangePicker}
                campaignId={campaignId}
                buttonName={"Date Range in adv analytics"}
              >
                <span className="fw-semibold">Date Range</span>
                <i className="ri-arrow-right-s-line"></i>
                {currentPeriod && currentPeriod.length === 2
                  ? ` ${formatDate(currentPeriod[0])} - ${formatDate(
                    currentPeriod[1]
                  )}`
                  : ""}
              </TrackableButton>
              {isDateRangePickerOpen && (
                <DatePicker
                  isDateRangePickerOpen={isDateRangePickerOpen}
                  setIsDateRangePickerOpen={setIsDateRangePickerOpen}
                  ranges={Ranges}
                  onChange={handleDateRange}
                  currentPeriod={currentPeriod}
                />
              )}
            </div>
          </div>

          <Row className="g-3">
            <AdvertiserTotals
              currentPeriod={currentPeriod}
              previousPeriod={previousPeriod}
              campaignId={campaignId}
            />
            <CampaignImpressionsAndClicks
              currentPeriod={currentPeriod}
              campaignId={campaignId}
            />
            <CampaignInventoryStats
              currentPeriod={currentPeriod}
              previousPeriod={previousPeriod}
              campaignId={campaignId}
              isIframe={isIframe}
              skin={skin}
              view={(isIframe || advertiser.id) ? 'ADV' : 'PUB'}
            />

            {(isIframe || userRole !== PUBLISHER || advertiser.id) ? (
              <></>
            ) : (
              <CampaignMap
                currentPeriod={currentPeriod}
                previousPeriod={previousPeriod}
                campaignId={campaignId}
              />
            )}
          </Row>

          {isIframe ? <></> : <Footer />}
        </div>
      </React.Fragment>
    );
  }
}

import { toCamelCase } from "./to-camel-case";

export function convertRowsToCamelCaseArray(data) {
    const { rows, columns } = data;

    const camelCaseColumns = columns.map(toCamelCase);

    const result = rows.map(row => {
        return row.reduce((obj, value, index) => {
            obj[camelCaseColumns[index]] = value;
            return obj;
        }, {});
    });

    return result;
}

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import siteLogo from "../assets/svg/revbox-logo.svg";
import { register } from "../services/micro";
import { setUser } from '../features/user';
import { loadersModule } from "../modules/loaders";
import { GetPubAbbr } from "../helpers/get-pub-abbr";
import { CookiesKeyNames, useCookiesContext } from "../contexts/CookiesContext";

export default function Signup() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cookiesData, updateCookiesProperty } = useCookiesContext();

  const publisherIdByCookies = cookiesData[CookiesKeyNames.publisherId];
  const publisherIdByAbbr = GetPubAbbr()
  const publisherId = publisherIdByCookies || publisherIdByAbbr;

  const logoGif = loadersModule(publisherId);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fistName = name.split(" ")[0];
    const lastName = name.split(" ")[1];

    if (!fistName || !lastName) {
      toast.error("Please enter your full name.");
      return;
    }

    if (!email || !password) {
      toast.error("Please enter your email and password.");
      return;
    }

    setIsLoading(true);

    toast.success("Registeration started");

    setTimeout(() => {
      toast.success("Creating the new publisher...");
    }, 1000);

    setTimeout(() => {
      toast.success("Creating the system tables...");
    }, 3000);

    setTimeout(() => {
      toast.success("Creating the info columns...");
    }, 5000);

    setTimeout(() => {
      toast.success("Preparing...");
    }, 6500);

    setTimeout(() => {
      toast.success("Redirecting...");
    }, 9000);

    register(fistName, lastName, email, password)
      .then((res) => {
        if (res && res.data) {
          dispatch(setUser(res.data));

          updateCookiesProperty(CookiesKeyNames.token, res.data.token);

          navigate("/pub/dashboard/revenue");
        } else {
          setIsLoading(false);
          toast.error("User Already Exist. Please Login");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Registration failed.");
        console.log("Error", err);
      });
  };


  if (isLoading) {
    return (
      <Form className="page-sign">
        <img className="w-20 logoGif" src={logoGif} alt="loading" />
      </Form>
    )
  }

  return (
    <Form className="page-sign" onSubmit={handleSubmit}>
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo">
            <object type="image/svg+xml" data={siteLogo} className="w-100" aria-label="svg image"></object>
          </Link>
          <Card.Title>Sign Up</Card.Title>
          <Card.Text>It's free to signup and only takes a minute.</Card.Text>
        </Card.Header>
        <Card.Body>
          <div className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control onChange={(e) => { setEmail(e.target.value) }} value={email} type="email" placeholder="Enter your email address" />
          </div>
          <div className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control onChange={(e) => { setPassword(e.target.value) }} value={password} type="password" placeholder="Enter your password" />
          </div>
          <div className="mb-3">
            <Form.Label>Full name</Form.Label>
            <Form.Control onChange={(e) => { setName(e.target.value) }} value={name} type="text" placeholder="Enter your full name" />
          </div>
          <div className="mb-4">
            <small>By clicking <strong>Create Account</strong> below, you agree to our terms of service and privacy statement.</small>
          </div>
          <Button type="submit" variant="primary" className="btn-sign">Create Account</Button>
        </Card.Body>
        <Card.Footer>
          Already have an account? <Link to="/pages/signin">Sign In</Link>
        </Card.Footer>
      </Card>
    </Form>
  );
}
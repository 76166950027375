import Cookies from "universal-cookie";
import { isCookiesEnabled } from "../contexts/CookiesContext";

export const getToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const cookies = new Cookies();
    const tokenParam = urlParams.get("token");
    const isIframe = tokenParam ? true : false;

    let token = urlParams.get("token")

    if (!token) {
        if (isCookiesEnabled && !isIframe) {
            token = cookies.get('token')
        } else {
            token = localStorage.getItem('token')
        }
    }
    
    return token
}

import React from "react";
import RecordCreate from "../../components/RecordCreate";

export default function PublisherInvoiceCreate() {
    return (
        <RecordCreate
            title="Create Publisher Invoice"
            table_name="client_invoices"
            breadcrumb="Invoice"
            redirect="/pub/invoices"
        />
    )
}
import { convertRowsToCamelCaseArray } from "./convert-rows-to-camel-case";
import { processTableData } from "./process-table-data";
import { toCamelCase } from "./to-camel-case";

export function getServerSideDatasourceInvStats(
    getData,
    hiddenColumns,
    colWidth,
    setColumns,
    search,
    currentPeriod,
    previousPeriod,
    campaignId,
    token,
    view) {
    return {
        getRows: async (params) => {
            const currentPage = params.api.paginationGetCurrentPage() + 1;
            const response = await getData(
                currentPeriod,
                previousPeriod,
                campaignId,
                token,
                view,
                currentPage,
                search,
            );

            setTimeout(() => {
                if (response.success) {

                    const transformedData = convertRowsToCamelCaseArray(response.data);
                    
                    const columns = response.data.columns.map((column)=>{
                        return {
                            headerName: column,
                            field: toCamelCase(column),
                            sortable: true,
                            width: colWidth[toCamelCase(column)],
                            minWidth: colWidth[toCamelCase(column)],
                            hide: hiddenColumns.includes(column),
                        }
                    })

                    if (columns) {
                        setColumns(columns);
                    }
                    params.success({
                        rowData: transformedData,
                        rowCount: response.data.rows.length,
                    });
                } else {
                    params.fail();
                }
            }, 200);
        },
    };
};

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function IconWithTooltip({ text }) {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
            <i className="ri-information-line align-middle text-secondary" style={{ cursor: 'pointer' }}></i>
        </OverlayTrigger>
    );
}
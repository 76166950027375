export const switchSkin = (skin) => {
    if (skin === "dark") {
        const btnWhite = document.getElementsByClassName("btn-white");

        for (const btn of btnWhite) {
            btn.classList.add("btn-outline-primary");
            btn.classList.remove("btn-white");
        }
    } else {
        const btnOutlinePrimary = document.getElementsByClassName(
            "btn-outline-primary"
        );

        for (const btn of btnOutlinePrimary) {
            btn.classList.remove("btn-outline-primary");
            btn.classList.add("btn-white");
        }
    }
};
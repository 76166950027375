export const getUserProfile = (user) => {
    const userFirstName = user.first_name;
    const userLastName = user.last_name;
    const userEmail = user.email;
    const userId = user.id;

    return {
        id: userId,
        firstName: userFirstName,
        lastName: userLastName,
        email: userEmail
    }
}

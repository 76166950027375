export function distributeAmount(totalAmount, items, colors, publisher_id) {
    return items
        .sort((a, b) => a.position - b.position)
        .map((item, index) => {
            if (item.position === 1) {
                return {
                    name: item.name,
                    clicks: item.clicks,
                    percentValue: (item.clicks / totalAmount) * 100,
                    position: item.position,
                    percent: ((item.clicks / totalAmount) * 100).toFixed(2) + "%",
                    missedClicks: '-',
                    opportunity: '-',
                    color: colors[index],
                };
            }

            return {
                name: item.name,
                clicks: item.clicks,
                position: item.position,
                percentValue: (item.clicks / totalAmount) * 100,
                percent: ((item.clicks / totalAmount) * 100).toFixed(2) + "%",
                missedClicks: item.missedClicks,
                opportunity: item.opportunity,
                color: colors[index],
            };
        });
}

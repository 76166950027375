import React from "react";
import { Button } from "react-bootstrap";

export function ClusterActionRenderer({data, value}) {
  if (!value) {
    return null;
  }
  
  const status = value.split("@")[0].toUpperCase();
  const parentBidIsDisactivated = value.split("@")[1] == "true";
  
  const urlParams = new URLSearchParams(window.location.search);
  const campaignId = urlParams.get("campaignId");

  const openStats = () => {
    window.open(`/adv/campaign/ppc/clustering?inventoryId=${data.ID}&campaignId=${campaignId}`, "_blank");
  }

  if (status === "INACTIVE" || status === "DRAFT") {
    return (
        <div>
            <Button variant="outline-secondary" size="sm" class="fs-11" style={{ width: '100px'}} onClick={openStats}>
              View Stats
            </Button>
            {/* <button class="btn bg-success btn-sm text-white" style={{ width: '100px'}} onClick={openStats}>
              View Stats
            </button> */}
            {/* <Button variant="outline-success" size="sm" class="fs-11" style={{ width: '100px'}} onClick={openStats}>
                View Stats
            </Button> */}
            <button class="btn bg-success btn-sm text-white" style={{marginLeft: '5px', width: '100px'}} disabled={parentBidIsDisactivated}>
                Start Bidding
            </button>
        </div>
    );
  } else {
    return (
      <div>
        {/* <button class="btn bg-success btn-sm text-white" style={{ width: '100px'}} onClick={openStats}>
          View Stats
        </button> */}
        <Button variant="outline-secondary" size="sm" class="fs-11" style={{ width: '100px'}} onClick={openStats}>
          View Stats
        </Button>
        <Button variant="outline-primary" size="sm" class="fs-11" style={{marginLeft: '5px', width: '100px'}} disabled={parentBidIsDisactivated}>
          Update Bid
        </Button>
      </div>
    );
  }
}

export function ClusterActionCellTooltipRenderer({ value }) {
  if (!value) {
    return null;
  }
  
  const status = value.split("@")[0].toUpperCase();
  const parentBidIsDisactivated = value.split("@")[1] == "true";

  if (parentBidIsDisactivated) {
    return "You can't bid on this cluster because the parent bid is not activated";
  }

  if (status === "INACTIVE" || status === "DRAFT") {
    return "Start bidding";
  } else {
    return "Update your bid";
  }
}
  

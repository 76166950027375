import { Tooltip } from "react-bootstrap";

export class CustomTooltip  {
    eGui;
    init(params) {
        const eGui = (this.eGui = document.createElement('div'));
        const color = params.color || '#000';

        eGui.classList.add('custom-tooltip');
        //@ts-ignore
        eGui.style['background-color'] = color;
        //@ts-ignore
        eGui.style['color'] = 'white';
        //@ts-ignore
        eGui.style['padding'] = '5px';
        //@ts-ignore
        eGui.style['border-radius'] = '5px';
        //@ts-ignore
        eGui.style['border'] = '1px solid black';
        //@ts-ignore
        eGui.style['max-width'] = '350px';
        //@ts-ignore
        eGui.style['font-family'] = 'Arial, sans-serif';

        eGui.innerHTML = `
            <div>
                ${params.value}
            </div>
        `;

        // eGui.innerHTML = <Tooltip placement="bottom">
        //     {params.value}
        // </Tooltip>
    }

    getGui() {
        return this.eGui;
    }
}
import { processTableData } from "./process-table-data";

const statusMap = {
    1: "liveRecords",
    2: "rejectedRecords",
    3: "reviewRecords",
    4: "draftRecords",
    null: "total",
}

const inventoryTypeMap = {
    1: "departmentRecords",
    2: "specificRecords",
    3: "homepageRecords",
    4: "bannerRecords",
    5: "clusterRecords",
    null: "total"
}

export function getServerSideDatasource(getData, hiddenColumns, colWidth, rowFields, setColumns, status, setCountByStatus, search, customColumns, additionalInfo, sortingParams) {
    return {
        getRows: async (params) => {
            const currentPage = params.api.paginationGetCurrentPage() + 1;
            const response = await getData({ page: currentPage, status, search, column_name: sortingParams?.columnName, column_order: sortingParams?.sortOrder });

            setTimeout(() => {
                if (response.success) {
                    if (typeof setCountByStatus === 'function') {
                        const useStatusMap = response.liveRecords !== undefined ||
                            response.rejectedRecords !== undefined ||
                            response.reviewRecords !== undefined ||
                            response.draftRecords !== undefined;

                        setCountByStatus(useStatusMap ? {
                            liveRecords: response.liveRecords ?? 0,
                            rejectedRecords: response.rejectedRecords ?? 0,
                            reviewRecords: response.reviewRecords ?? 0,
                            draftRecords: response.draftRecords ?? 0,
                            total: response.total ?? 0
                        } : {
                            departmentRecords: response.departmentRecords ?? 0,
                            specificRecords: response.specificRecords ?? 0,
                            homepageRecords: response.homepageRecords ?? 0,
                            bannerRecords: response.bannerRecords ?? 0,
                            clusterRecords: response.clusterRecords ?? 0,
                            total: response.total ?? 0
                        });
                    }


                    const { columns: newColumns, data: newData } = processTableData(
                        response,
                        hiddenColumns,
                        colWidth,
                        rowFields,
                        customColumns,
                        additionalInfo
                    );

                    if (newColumns) {
                        setColumns(newColumns);
                    }

                    const statusKey = status === null ? "null" : status;

                    params.success({
                        rowData: newData,
                        rowCount: status
                            ? response[statusMap[statusKey] || inventoryTypeMap[statusKey]]
                            : response.total,
                    });
                } else {
                    params.fail();
                }
            }, 200);
        },
    };
};

import { formatThousand } from "../../../../helpers/thousand-number-format";


export function CurrentBudgetRenderer({data}){    
    let ppc_monthly_spend = Number(data.budget.split("/")[0]);
    let ppc_monthly_budget = Number(data.budget.split("/")[1]);
    let spendPercentage = (ppc_monthly_spend / ppc_monthly_budget) * 100;

    if (ppc_monthly_budget > 0) spendPercentage = (ppc_monthly_spend / ppc_monthly_budget) * 100;
    if (ppc_monthly_budget === 0) spendPercentage = 0;
    if (ppc_monthly_spend === 0) spendPercentage = 0;

    return (
        <div className="revenue-item">
          <div className="progress mt-1" style={{ height: '6px' }}>
            {spendPercentage > 100 ? (
              <div
                role="progressbar"
                className="progress-bar bg-danger"
                aria-valuenow="70"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${spendPercentage}%` }}
              ></div>
            ) : spendPercentage > 75 ? (
              <div
                role="progressbar"
                className="progress-bar bg-warning"
                aria-valuenow="70"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${spendPercentage}%` }}
              ></div>
            ) : (
              <div
                role="progressbar"
                className="progress-bar bg-success"
                aria-valuenow="70"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${spendPercentage}%` }}
              ></div>
            )}
          </div>
      
          <div className="revenue-item-body mb-0 mt-1" style={{height:"20px"}}>
            <span className="d-block fs-11 ff-secondary" style={{ minWidth: '100px' }}>
              ${formatThousand(ppc_monthly_spend)} / ${formatThousand(ppc_monthly_budget)}
            </span>
            <span
              className="d-block fs-11 ff-secondary"
              style={{
                color: spendPercentage > 100
                  ? 'var(--bs-danger)'
                  : spendPercentage > 75
                  ? 'var(--bs-warning)'
                  : 'var(--bs-success)',
              }}
            >
              {spendPercentage.toFixed(0)}%
            </span>
          </div>
        </div>
      );
      
}
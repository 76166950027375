import React from 'react';
import lightLogo from '../../../assets/img/img1.jpg';
import darkLogo from '../../../assets/img/img1.jpg';

export default function defaultLogo(theme) {
    if (theme === 'dark') {
        return <img src={darkLogo} alt="dark logo" className='placeholder-logo' />;
    }
    
    return <img src={lightLogo} alt="light logo" className='placeholder-logo' />;
}

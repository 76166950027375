import React from 'react';
import lightLogo from '../../../assets/svg/revbox-logo.svg';
import darkLogo from '../../../assets/svg/revbox-logo-white.svg';

export default function defaultLogo(custom_style) {
    return( <>
        <img src={darkLogo} className='logoDarkClass' style={custom_style}/>
        <img src={lightLogo} className='logoClass' style={custom_style}/>
    </>)
}

export function StatusRenderer(params) {
    const ppc_active = params?.data.ppc_active;
    const ppc_paused = params?.data.ppc_paused;

    let status = "Inactive"

    if (ppc_active === "Yes") status = "Active";
    if (ppc_paused === "Yes") status = "Paused";

    if (status === "Paused") {
        return (
            <span className="badge bg-warning ms-2">
                Paused
            </span>
        );
    }

    if (status === "Inactive") {
        return (
            <span className="badge bg-danger ms-2">
                Inactive
            </span>
        );
    }

    return (
        <span className="badge bg-success ms-2">
            Active
        </span>
    );
}
import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function getPubUsers({ search, page, column_name, column_order }) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;

        const params = {};
        if (search) {
            params.search = search;
        }
        if (page) {
            params.page = page;
        }
        if (column_name) {
            params.column_name = column_name;
        }
        if (column_order) {
            params.column_order = column_order;
        }

        const response = await axios.get(process.env.REACT_APP_HUB_URL + '/api/users/pub', {
            params
        })
        
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPubUsers;

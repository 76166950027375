import { AuctionMonthlyBudgetCardMap } from '../maps';
import DefaultAuctionMonthlyBudgetCard from './default';

export function AuctionMonthlyBudgetCardModule(publisher_id, props) {
    try {
        const isPublisherIdPresent = Object.keys(AuctionMonthlyBudgetCardMap).includes(String(publisher_id))

        if (isPublisherIdPresent) {
          return AuctionMonthlyBudgetCardMap[publisher_id](props);
        } else {
          return DefaultAuctionMonthlyBudgetCard(props);
        }
    } catch (error) {
        console.error('Error loading logo module:', error);
        return DefaultAuctionMonthlyBudgetCard(props);
    }
}

export function NameRenderer({ value }) {
    const name = value.split("@")[0];
    const visits = value.split("@")[1];

    return (
        <div className="table-item-name">
            {name}
        </div>
    );
    // return (
    //     <div>
    //         {name} <span className='badge bg-outline-secondary badg'>{visits} Visits</span>
    //     </div>
    // );
}

export function NameCellTooltipRenderer({ value }) {
    const name = value.split("@")[0];
    const visits = value.split("@")[1];

    return `Current invetory recived ${visits} visits per last 30 days`;
}
 
import { toCamelCase } from "./to-camel-case";

export function createColumnDefinitions(data, customColumns, colWidths) {
    const { currentColumns, periodColumns } = data;

    const currentChildren = currentColumns.map(column => {
        const customColumn = customColumns?.find((custom) => custom.field === toCamelCase(column))

        return ({
            field: toCamelCase(column),
            pinned: true,
            colId: toCamelCase(column),
            minWidth: colWidths[toCamelCase(column)],
            width: colWidths[toCamelCase(column)],
            cellRenderer: customColumn?.cellRenderer ? (params) =>
                customColumn.cellRenderer(params)
                : null,
        })
    });

    const periodChildren = periodColumns.map(column => ({
        field: toCamelCase(column),
        colId: toCamelCase(column),
        minWidth: colWidths[toCamelCase(column)],
        width: colWidths[toCamelCase(column)],
        sortable: false,
    }));

    return [
        {
            headerName: "Current",
            suppressStickyLabel: true,
            children: currentChildren,
        },
        {
            headerName: "Period",
            suppressStickyLabel: true,
            openByDefault: true,
            children: periodChildren,
        }
    ];
}
import { placeholderLogoModule } from "../../../modules/placeholderLogo";

export function ImageRenderer(params, publisherIdByAbbr, currentSkin) {
    if (params.value !== "Unknown") {
        return <img src={`${params.value}`} style={{ maxWidth: "50px", maxHeight: "50px" }} />
    } else {
        const placeholderSrc = placeholderLogoModule(publisherIdByAbbr, currentSkin).props.src;
        return (
            <img 
                src={placeholderSrc} 
                style={{ maxWidth: "30px", maxHeight: "30px", borderRadius: "5px" }} 
            />
        );
    }
}

const columnsWidths = {
    'topic': 340,
    'position': 130,
    'yourBid': 80,
    'topBid': 80,
    'status': 130,
    'missedClicksForecast': 190,
    'action': 240,
}

export function createColumnDefinitionsClusterPPC(columns, customColumns) {
    const toCamelCase = (str) => {
        if (str.toUpperCase() === 'ID') {
            return str.toLowerCase();
        } else
            return str
                .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
                    index === 0 ? match.toLowerCase() : match.toUpperCase()
                )
                .replace(/\s+/g, '')
                .replace(/^ID$/, 'id');
    };

    const current = columns.map(column => {
        const customColumn = customColumns?.find((custom) => custom.field === column)

        if (column === 'Path' || column === 'Collapse') {
            return null
        }

        return ({
            headerComponentParams: {
                template: (column === 'Missed Clicks Forecast' || column === 'Your Clicks') ? 
                `<div style="display: inline-flex; align-items: center;">${column} <i class="ri-information-line text-secondary align-icon"></i></div>` : column,
            },
            headerTooltip: customColumn?.headerTooltip || column,
            tooltipValueGetter: customColumn?.tooltipValueGetter || null,
            field: toCamelCase(column),
            colId: toCamelCase(column),
            hide: column === 'ID' || column === 'Name',
            pinned: column === 'Action' ? 'right' : column === 'Topic' ? 'left' : null,
            minWidth: columnsWidths[toCamelCase(column)],
            width: columnsWidths[toCamelCase(column)],
            suppressHeaderFilterButton: true,
            sortable: false,
            menuTabs: [],
            suppressMenu: true,
            cellRenderer: customColumn?.cellRenderer ? (params) =>
            {
                return customColumn.cellRenderer(params)
            } : null,
        })
    }).filter(column => column !== null);

    return current;
}
import React from "react";
import RecordCreate from "../../components/RecordCreate";

export default function TransactionCreate() {
    return (
        <RecordCreate
            title="Create Transaction"
            table_name="transactions"
            breadcrumb="Transaction"
            redirect="/pub/finance/transactions"
        />
    )
}
import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function getInventoryCampaignByInventoryAndCampaign(inventory_id, campaign_id) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.get(process.env.REACT_APP_HUB_URL + `/api/inventory-campaigns/inventory-campaign-by-inventory-and-campaign?inventory_id=${inventory_id}&campaign_id=${campaign_id}`)
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getInventoryCampaignByInventoryAndCampaign;

import { convertRowsToCamelCaseArray } from "./convert-rows-to-camel-case";
import { createColumnDefinitions } from "./create-column-definitions";

const statusMap = {
    1: "activeRecords",
    2: "pausedRecords",
    3: "inactiveRecords",
    0: "total",
}

export function getServerSideDatasourceSplitted(getData, currentPeriod, previousPeriod, colWidths, classifiedColumns, setColumns, setCountByStatus, status, search, customColumns, inventoryId, sortingParams) {
    return {
        getRows: async (params) => {
            const currentPage = params.api.paginationGetCurrentPage() + 1;
            const response = await getData(currentPeriod, previousPeriod, status, search, currentPage, inventoryId, sortingParams);

            setTimeout(() => {
                if (response.success) {
                    if (typeof setCountByStatus === 'function') {
                        setCountByStatus({
                            activeRecords: response.activeRecords,
                            inactiveRecords: response.inactiveRecords,
                            pausedRecords: response.pausedRecords,
                            total: response.total
                        });
                    }

                    const transformedData = convertRowsToCamelCaseArray(response.data);
                    const columnDefs = createColumnDefinitions(classifiedColumns, customColumns, colWidths);

                    if (columnDefs) {
                        setColumns(columnDefs);
                    }

                    const statusKey = status === null ? "null" : status;

                    params.success({
                        rowData: transformedData,
                        rowCount: status ? response[statusMap[statusKey]] : response.total,
                    });
                } else {
                    params.fail();
                }
            }, 200);
        },
    };
};

import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function getEstimatedPosition(id, bid, ppc_bid_active) {
    try {
        const token = getToken();
        let bid_active = '';

        if (ppc_bid_active !== undefined) {
            bid_active = '&ppc_bid_active=' + ppc_bid_active.toString();
        }

        const response = await axiosInstance.get(`/api/inventory-campaigns/${id}/estimate/position?bid=${bid}${bid_active}`, {
            headers: {
                'Authorization': token
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

export default getEstimatedPosition;

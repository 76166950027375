export const getUserName = (user) => {
    const userFirstName = user.first_name;
    const userLastName = user.last_name;

    if (userFirstName && userLastName) {
        return `${userFirstName} ${userLastName}`;
    } else {
        return 'User loading..';
    }
}

import React from "react";
import RecordEdit from "../../components/RecordEdit";

export default function PublisherCampaignEdit() {
    const urlParams = new URLSearchParams(window.location.search);
    const values = Object.fromEntries(urlParams.entries());

    return (
        <RecordEdit
            values={values}
            title="Edit Campaign"
            table_name="client_campaigns"
            breadcrumb="Campaign"
            redirect="/pub/customers/campaigns"
            item_name="Campaign"
        />
    )
}
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row, Form, Placeholder, Nav, Button } from "react-bootstrap";
import InfiniteScroll from 'react-infinite-scroller';

import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { getAllNotifications } from "../services/micro";
import { CookiesKeyNames, useCookiesContext } from "../contexts/CookiesContext";

export default function Notifications() {
    const { cookiesData } = useCookiesContext();
    const currentSkin = cookiesData[CookiesKeyNames.skinMode] ? 'dark' : '';
    const [notifications, setNotifications] = useState({ unread: {}, read: {} });
    const [fetching, setFetching] = useState(true);
    const [messagesStatus, setMessagesStatus] = useState("unread");
    const [hasMore, setHasMore] = useState(true);
    const [readOffset, setReadOffset] = useState(0);
    const [unreadOffset, setUnreadOffset] = useState(0);
    const [skin, setSkin] = useState(currentSkin);
    const [totalCount, setTotalCount] = useState({ read: 0, unread: 0 });
    const limit = 20

    const openPage = (url) => {
        window.open(url, '_blank');
    }

    const getData = (status) => {
        let offset = status === 'unread' ? unreadOffset : readOffset
        getAllNotifications(limit, offset, status).then(res => {
            const newTotalCount = JSON.parse(JSON.stringify(totalCount));
            Object.keys(res.data.notifications).forEach(date => {
                newTotalCount[status] += res.data.notifications[date].length;
            });

            if (newTotalCount[status] >= res.data.count) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }

            const updatedNotifications = {
                ...notifications,
                [status]: {
                    ...notifications[status],
                    ...Object.keys(res.data.notifications).reduce((acc, date) => {
                        acc[date] = notifications[status][date]
                            ? [...notifications[status][date], ...res.data.notifications[date]]
                            : res.data.notifications[date];
                        return acc;
                    }, {}),
                },
            };

            if (status === "unread") {
                setUnreadOffset(offset + limit)
            } else setReadOffset(offset + limit)

            setTotalCount(newTotalCount);
            setNotifications(updatedNotifications);
            setFetching(false);

        })
            .catch(e => {
                console.log("Server error");
                setFetching(false);
            });
    };

    useEffect(() => {
        if (messagesStatus === "unread") {
            return () => getData("unread")
        }
    }, []);

    function formatDateTime(dateString) {
        const date = new Date(dateString);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);

        return `${hours}:${minutes}`;
    }

    function handleTabChange(status) {
        setMessagesStatus(status);
        getData(status)
    }

    function onOpen(link) {
        window.open(link, '_blank');
    }

    const notificationsComponent = (data, title) => {
        return data && Object.keys(data)?.length ?
            <>
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <h5 className="section-title mb-0">{title}</h5>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={() => getData(messagesStatus)}
                    hasMore={hasMore}
                    loader={<></>}
                >
                    <ul className="activity-group mb-5">
                        {/* <li className="list-group-item" key={key} style={item.status === "read" ? { opacity: 0.35 } : {}} onClick={() => onReadNotification(item.id, item.link)}>
                        { item.icon_url ?
                            <div className={(item.status === "unread") ? "avatar online" : "avatar"} style={{ backgroundImage: 'url(' + item.icon_url + ')', backgroundPosition: 'center', backgroundSize: 'contain' }}/>
                            :
                            <div className={(item.status === "unread") ? "avatar online" : "avatar"}>
                            <span class="avatar-initial">{item.title ? item.title[0] : item.text[0]}</span>
                            </div> 
                        }
                        <div className="list-group-body">
                            <p>{item.title ? item.title : item.text}</p>
                            <span>{item.date}</span>
                        </div>
                    </li> */}
                        {
                            Object.keys(data).map(date => {
                                const messages = data[date].map((item, key) => {
                                    const beforeItemStyle = {
                                        position: 'absolute',
                                        top: '-6px',
                                        left: '-17px',
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '100%',
                                        backgroundColor: '#2845f9',
                                        color: 'rgba(255, 255, 255, 0.75)',
                                        zIndex: '5',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '16px',
                                        fontFamily: 'remixicon',
                                    }

                                    const itemStyle = {
                                        position: 'relative',
                                        padding: '10px 15px',
                                        marginLeft: '32px',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        backgroundColor: item.status === 'unread' ? '#f5f5f5' : 'white',
                                        borderRadius: '5px',
                                        marginBottom: '10px',
                                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                    }

                                    return (
                                        // <li className={`custom-activity-item ${message.type}`} key={key}>               
                                        //     <p className="d-sm-flex align-items-center mb-0">               
                                        //         <span className="fs-sm">{message.text}</span>               
                                        //         <span className="fs-xs text-secondary ms-auto">{formatDateTime(message.created_at)}</span>              
                                        //     </p>                
                                        // </li>   
                                        // .custom-activity-item::before {
                                        //     content: "\eb4d";
                                        //     position: absolute;
                                        //     top: -6px;
                                        //     left: -17px;
                                        //     width: 32px;
                                        //     height: 32px;
                                        //     border-radius: 100%;
                                        //     background-color: #2845f9;
                                        //     color: rgba(255, 255, 255, 0.75);
                                        //     z-index: 5;
                                        //     display: flex;
                                        //     align-items: center;
                                        //     justify-content: center;
                                        //     font-size: 16px;
                                        //     font-family: "remixicon";
                                        // }
                                        // { item.icon_url ?
                                        //     <div className={(item.status === "unread") ? "avatar online" : "avatar"} style={{ backgroundImage: 'url(' + item.icon_url + ')', backgroundPosition: 'center', backgroundSize: 'contain' }}/>
                                        //     :
                                        //     <div className={(item.status === "unread") ? "avatar online" : "avatar"}>
                                        //       <span class="avatar-initial">{item.title ? item.title[0] : item.text[0]}</span>
                                        //     </div> 
                                        //   }
                                        <li key={key} onClick={() => onOpen(item.link)} style={itemStyle}>
                                            <div className="list-group-body full-width">
                                                <div className="d-flex align-items-center">
                                                    {item.icon_url ?
                                                        <div className={(item.status === "unread") ? "avatar online" : "avatar"} style={{ backgroundImage: 'url(' + item.icon_url + ')', backgroundPosition: 'center', backgroundSize: 'contain' }} />
                                                        :
                                                        <div className={(item.status === "unread") ? "avatar online" : "avatar"}>
                                                            <span class="avatar-initial">{item.title ? item.title[0] : item.text[0]}</span>
                                                        </div>
                                                    }
                                                    <div style={{ marginLeft: 10 }}>{item.title ? item.title : item.text}</div>
                                                    <span className="fs-xs text-secondary ms-auto">{formatDateTime(item.created_at)}</span>
                                                </div>
                                                {item.title && <p style={{ marginTop: "20px" }}>Details: {item.text}</p>}
                                            </div>
                                        </li>
                                    );
                                });
                                return (
                                    <React.Fragment key={date}>
                                        <li className="activity-date">{date}</li>
                                        {messages}
                                    </React.Fragment>
                                );
                            })
                        }
                        {fetching &&
                            <div className="col">
                                <Placeholder as={Card.Title} animation="glow" className="h-100">
                                    <Placeholder className="w-100 h-100" />
                                </Placeholder>
                                <Placeholder as={Card.Title} animation="glow" className="h-100">
                                    <Placeholder className="w-100 h-100" />
                                </Placeholder>
                                <Placeholder as={Card.Title} animation="glow" className="h-100">
                                    <Placeholder className="w-100 h-100" />
                                </Placeholder>
                            </div>
                        }
                    </ul>
                </InfiniteScroll>
            </>
            :
            <div className="alert alert-info" role="alert">
                You don't have any notifications yet
            </div>
    }

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <Row className="g-5">
                    <Col xl="10">
                        <ol className="breadcrumb fs-sm mb-2">
                            <li className="breadcrumb-item"><Link to="/pub/dashboard/revenue">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Notifications</li>
                        </ol>

                        <h2 className="main-title mb-3">Notifications</h2>
                        <p className="text-secondary mb-5">Keep track of important events through system alerts</p>

                        <Nav className="nav-line mb-4">
                            {<Nav.Link href="#new" className={messagesStatus === 'unread' ? "active" : ''} onClick={() => handleTabChange('unread')}>New</Nav.Link>}
                            {<Nav.Link href="#seen" onClick={() => handleTabChange('read')}>Seen</Nav.Link>}
                        </Nav>
                        {messagesStatus === 'unread' ?
                            notificationsComponent(notifications.unread, "New Messages & Updates")
                            :
                            notificationsComponent(notifications.read, "Previously Seen")
                        }
                    </Col>
                    <Col xl="2">
                        <Button variant="outline-primary" size="md" onClick={() => openPage('/notifications/emails')}>
                            Mail Center
                        </Button>
                    </Col>
                </Row>
                <Footer />
            </div>
        </React.Fragment>
    );
}

import { formatThousand } from "../../../../helpers/thousand-number-format";

export function ClusterYourBidCellRenderer({ value }) {
    const your_bid = value.split("@")[0];
    const inherited_cpc = value.split("@")[1];
    const cluster_inherited_bid_mode_enabled = value.split("@")[2];
    const status = value.split("@")[3].toUpperCase();

    if (value === "-") {
        return "No bid";
    }

    if (status === "INACTIVE" || status === "DRAFT") {
        return (
            <div>
                <span className="text-secondary">{your_bid}</span>
            </div>
        )
    }

    if (cluster_inherited_bid_mode_enabled == "true") {
        if (status === "BID ACTIVE") {
            return (
                <div>
                    <span className="text-success">{your_bid}</span>
                </div>
            )
        } else if (status === "INHERITED") {
            const cpc = inherited_cpc === "null" ? your_bid : '$' + Number(inherited_cpc).toFixed(2);
            return ( 
                <div>
                    <span className="text-warning">{cpc}</span>
                </div>
            )
        } else {
            return (
                <div>
                    <span className="text-secondary">{your_bid}</span>
                </div>
            )
        }
    } else {
        return (
            <div>
                <span className="text-secondary">{your_bid}</span>
            </div>
        )
    }
}
 

export function ClusterYourBidCellTooltipRenderer({ value }) {
    const your_bid = value.split("@")[0];
    const inherited_cpc = value.split("@")[1];
    const cluster_inherited_bid_mode_enabled = value.split("@")[2];
    const status = value.split("@")[3].toUpperCase();

    if (value === "-") {
        return "Your bid is not set or campaign is paused or not active";
    }

    if (status === "INACTIVE" || status === "DRAFT") {
        return "Your bid is " + your_bid;
    }

    if (cluster_inherited_bid_mode_enabled == "true") {
        if (inherited_cpc == "null") {
            return "Your bid is " + your_bid;
        } else {
            return "Your bid is $" + formatThousand(Number(inherited_cpc).toFixed(2)) + ". This bid is inherited from parent topic cluster.";
        }
    }

    return "Your bid is " + your_bid;
}
 
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    token: null,
    email: null,
    first_name: null,
    last_name: null,
    role: null,
    role_alias: null,
    id: null,
    publisher_id: null,
    email_notifications: null,
    push_notifications: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.token = action.payload.token
      state.email = action.payload.email
      state.first_name = action.payload.first_name
      state.last_name = action.payload.last_name
      state.role = action.payload.role
      state.role_alias = action.payload.role_alias
      state.id = action.payload.id
      state.publisher_id = action.payload.publisher_id
      state.access_levels = action.payload.access_levels
      state.email_notifications = action.payload.email_notifications
      state.push_notifications = action.payload.push_notifications
    },
    clearUser: (state) => {
      state.token = null
      state.email = null
      state.first_name = null
      state.last_name = null
      state.role = null
      state.role_alias = null
      state.id = null
      state.publisher_id = null
      state.access_levels = null
      state.email_notifications = null
      state.push_notifications = null
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUser, clearUser } = userSlice.actions

export default userSlice.reducer
import axios from "axios";

async function register(firstName, lastName, email, password) {
    try {
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/auth/register', {
            firstName: firstName,
            lastName: lastName, 
            email: email,
            password: password
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default register;

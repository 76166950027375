import React from "react";
import RecordCreate from "../../components/RecordCreate";

export default function PublisherInventoryCreate() {
  return (
    <RecordCreate
        title="Create Publisher Inventory"
        table_name="publisher_inventory"
        breadcrumb="Inventory"
        redirect="/pub/inventory/all"
        item_name="Inventory"
    />
  )
}
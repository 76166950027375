import React, { useState, useEffect } from "react";
import NotificationsCard from "../components/NotificationsCard";
import { getAllRoles, getNotificationSettings } from "../services/micro";
import { logosModule } from "../modules/logos";

export default function NotificationSettings() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const unsubscribedFrom = urlParams.get("unsubscribedFrom"); // outbid_notifications

    const [user, setUser] = useState();
    const [userAccesses, setUserAccesses] = useState();

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getNotificationSettings(token);
                setUser(response.data);
                
                const rolesData = await getAllRoles();
                const newUserAccesses = rolesData.data.find((role) => role.id === response.data.role)?.avaliable_accesses;
                setUserAccesses(newUserAccesses);
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, [token]);

    if (!user) {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </React.Fragment>
        );
    }
    
    return (
        <React.Fragment>
            <div style={{ minWidth: 400, padding: 100, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <span className="d-flex justify-content-center align-items-center">
                    {logosModule(user.publisher_id, "white", { width: 120, maxWidth: 120 })}
                </span>
                {
                    unsubscribedFrom &&
                    <div className="alert alert-success mb-2 mt-2" role="alert" style={{ maxWidth: 500 }}>
                        You have successfully unsubscribed from {
                            unsubscribedFrom.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase())
                        } emails.
                    </div>
                }
                <NotificationsCard user={user} showOnlyEmails updateByNotificationToken userAccesses={userAccesses} />
            </div>
        </React.Fragment>
    );
}
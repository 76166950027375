import React from "react";
import RecordEdit from "../../components/RecordEdit";

export default function InventoryCampaignEdit() {
    const urlParams = new URLSearchParams(window.location.search);
    const values = Object.fromEntries(urlParams.entries());

    return (
        <RecordEdit
            values={values}
            title="Edit Queue Item"
            table_name="inventory_campaigns"
            breadcrumb="Queue"
            redirect="/pub/inventory/queue"
            item_name="Item"
        />
    )
}
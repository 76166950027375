import React, { useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { setUser } from '../features/user';
import { getMe } from "../services/micro";
import { loadersModule } from "../modules/loaders";

import { getPublisher } from "../services/pub";
import { toast } from "react-toastify";
import { mixpanelInstance } from "../mixpanel/instance";
import { GetPubAbbr } from "../helpers/get-pub-abbr";
import { CookiesKeyNames, useCookiesContext } from "../contexts/CookiesContext";

export const Auth = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const {updateCookiesProperty,cookiesData} = useCookiesContext()
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const navigate = useNavigate();
  
  const publisherIdByCookies = cookiesData[CookiesKeyNames.publisherId];
  const publisherIdByAbbr = GetPubAbbr()
  const publisherId = publisherIdByCookies || publisherIdByAbbr;


  const logoGif = loadersModule(publisherId);

  const fetchUser = async () => {
    try {
      if (user.publisher_id) {
        const publisherResponse = await getPublisher(publisherIdByCookies);

        if (publisherResponse.success) {
          const abbreviation = publisherResponse.data.abbreviation;
          updateCookiesProperty(CookiesKeyNames.abbreviation, abbreviation);
          
        } else {
          toast.error('Cannot get publisher');
          navigate('/pages/signin');
        }
        setIsLoading(false);
        return;
      }

      const response = await getMe();
      
      if (response.success === false) {
        navigate('/pages/signin');
      } else {
        const user = response.data;
        if (mixpanelInstance.people) {
          try {
            mixpanelInstance.people.set(user.id, {
              $firstname: user.first_name,
              $lastname: user.last_name,
              $email: user.email,
              $role: user.role,
              $publisherId: user.publisher_id,
            });

            mixpanelInstance.track('Sign Up', {
              'Signup Type': 'Login',
            });

          } catch (error) {
            console.error("Error in Mixpanel people.set:", error);
          }
        } else {
          console.error("Mixpanel people API is not available");
        }

        dispatch(setUser(user));
        updateCookiesProperty(CookiesKeyNames.publisherId, user.publisher_id);

        const publisherResponse = await getPublisher(user.publisher_id);

        if (publisherResponse.success) {
          const abbreviation = publisherResponse.data.abbreviation;
          updateCookiesProperty(CookiesKeyNames.abbreviation, abbreviation);
        } else {
          toast.error('Cannot get publisher');
          navigate('/pages/signin');
        }

        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      navigate('/pages/signin');
    }
  };

  useLayoutEffect(() => {
    fetchUser();
  }, []);

  if (isLoading) {
    return (
      <Form className="page-sign dark">
        {logoGif}
      </Form>
    );
  } else {
    return (
      <>
        {children} {/* Render children */}
      </>
    );
  }
};

import React from "react";
import RecordEdit from "../../components/RecordEdit";

export default function TransactionEdit() {
    const urlParams = new URLSearchParams(window.location.search);
    const values = Object.fromEntries(urlParams.entries());
    
    console.log('transaction edit values', values);

    return (
        <RecordEdit
            values={values}
            title="Edit Transaction"
            table_name="transactions"
            breadcrumb="Transactions"
            redirect="/pub/finance/transactions"
            item_name="Transaction"
        />
    )
}
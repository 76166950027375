import React from 'react';
import { Col, Row } from 'react-bootstrap';

const LogoUpload = ({ onLogoChange, logoInputRef, croppedLogoPreview, logoFile, dimensions }) => (
    <div className="setting-item">
        <Row className="g-2 align-items-center">
            <Col md="2">
                <h6>Product Logo</h6>
                <p>Required<br />{`${dimensions.width}x${dimensions.height}px`}, JPG/PNG</p>
            </Col>
            <Col md>
                <form className="file-input-form" method="post" encType="multipart/form-data">
                    <div>
                        <input
                            type="file"
                            id="logo_upload"
                            name="image_uploads"
                            accept=".png,.jpg,.jpeg"
                            onChange={onLogoChange}
                            ref={logoInputRef}
                            style={{ opacity: 0 }}
                        />
                    </div>
                    <div className="input-file-preview">
                        <label htmlFor="logo_upload">Upload Logo</label>
                        <p className='vertical-stick'></p>
                        <p id="logo-input-placeholder">No file currently selected for upload</p>
                    </div>
                </form>
                {(croppedLogoPreview || logoFile) && (
                    <div className="cropped-image-preview">
                        <img
                            src={croppedLogoPreview || logoFile}
                            alt="Logo Preview"
                            style={{ width: "150px", height: "150px" }}
                        />
                    </div>
                )}
            </Col>
        </Row>
    </div>
);

export default LogoUpload;

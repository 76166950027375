import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useState } from "react";

export default function SortableItem({ id, imageSrc, index, onRemove }) {
  const [isDragging, setIsDragging] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    activationConstraint: {
      delay: 150,
      tolerance: 5,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleMouseDown = (e) => {
    if (!e.target.closest(".remove-btn")) {
      setIsDragging(true);
    }
  };

  const handleMouseUp = (e) => {
    if (isDragging) {
      setIsDragging(false);
    } else {
      onRemove(index);
    }
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      className="sortable-item"
    >
      <div className="image-container">
        <img
          src={imageSrc}
          className="sortable-image"
          alt={`Screenshot ${index + 1}`}
        />
        <button
          className="remove-btn"
          onClick={(e) => {
            e.stopPropagation();
            onRemove(index);
          }}
        >
          &times;
        </button>
      </div>
    </div>
  );
}

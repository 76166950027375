import React, { useState, useEffect } from "react";
import { Card, Col, Nav, Row, Table, Placeholder } from "react-bootstrap";
import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";

import { getPeriodCampaignMap } from "../../../services/micro";
import "../../../scss/_variables.scss";

export default function CampaignMap(props) {
  const { currentPeriod, previousPeriod, campaignId } = props;
  const [response, setResponse] = useState();
  const [reset, setReset] = useState(0);

  const mapStartColor = getComputedStyle(document.documentElement).getPropertyValue('--map-start');
  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary');

  useEffect(() => {
    if (currentPeriod && previousPeriod) {
      getPeriodCampaignMap(currentPeriod, previousPeriod, campaignId).then(
        (response) => {
          setResponse(response);
        }
      );
    }
  }, [currentPeriod, previousPeriod, campaignId]);

  const onMapReset = () => {
    setReset(reset + 1);
  };

  useEffect(() => {
    setTimeout(() => {
      onMapReset();
    }, 2000);
  }, [response]);

  if (response) {
    return (
      <React.Fragment key={reset}>
        <Col md="12 show nav-publisher">
          <Card className="card-one">
            <Card.Header>
              <Card.Title as="h6">Impressions & Clicks By Country</Card.Title>
              <Nav className="nav-icon nav-icon-sm ms-auto">
                <Nav.Link href="#" onClick={() => onMapReset()}>
                  <i className="ri-refresh-line"></i>
                </Nav.Link>
              </Nav>
            </Card.Header>
            <Card.Body className="p-4">
              <Row className="align-items-center g-3">
                <Col
                  md="4"
                  className="d-flex flex-column justify-content-center"
                >
                  <Table className="table-one mb-4">
                    <thead>
                      <tr>
                        <th className="wd-40 pt-0">Top Countries</th>
                        <th className="wd-60 pt-0">Impressions</th>
                        <th className="wd-60 pt-0">Clicks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {response?.country_data?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <span
                              className={"badge-dot me-2 bg-" + item.bg}
                            ></span>{" "}
                            <span className="fw-medium">{item.country}</span>
                          </td>
                          <td>{item.impressions}</td>
                          <td>{item.clicks}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
                <Col md="8" className="mt-5 mt-md-0 h-100">
                  <div style={{ height: "300px" }}>
                    <VectorMap
                      map={worldMill}
                      backgroundColor="transparent"
                      regionStyle={{
                        initial: {
                          fill: "#e4e4e4",
                          "fill-opacity": 0.9,
                          stroke: "none",
                          "stroke-width": 0,
                          "stroke-opacity": 0,
                        },
                      }}
                      series={{
                        regions: [
                          {
                            values: response?.click_data,
                            scale: [mapStartColor, primaryColor],
                            attribute: "fill",
                          },
                        ],
                      }}
                      onRegionTipShow={(e, el, code) => {
                        el.html(
                          el.html() +
                            " - Clicks: " +
                            (response?.click_data[code] || 0) +
                            " | impressions: " +
                            (response?.impressions_data[code] || 0)
                        );
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Placeholder
          as={Card.Title}
          animation="glow"
          className="h-100"
          style={{ color: "var(--bs-body-color)" }}
        >
          <Placeholder className="w-100 h-100" />
        </Placeholder>
      </React.Fragment>
    );
  }
}

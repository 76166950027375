import { toast } from "react-toastify"

export function modules (
  publisher_id,
  mapType
) {
  try {
    const isPublisherIdPresent = Object.keys(mapType).includes(String(publisher_id))

    if (isPublisherIdPresent) {
      return mapType[publisher_id]
    } else {
      return mapType[0]
    }
  } catch (e) {
    toast.error("Something went wrong")
  }
}

import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

// token is optional
async function getCampaing(id, current_token) {

    let token = current_token || getToken();
    const response = await axiosInstance.get(`/api/client-campaigns/${id}`, {
        headers: {
            'Authorization': token
        }
    });

    return response.data;

}

export default getCampaing;

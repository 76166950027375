import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function getPublisherAndAssignEmails(filters, assigned_to) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/emails/get-publisher-and-assign-emails', {
            filters,
            assigned_to
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPublisherAndAssignEmails;

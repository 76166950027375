
export function primaryColorModule(publisher_id) {
    try {
        if (publisher_id === 1) {
            return '#4C5ECF';
        } else if (publisher_id === 10) {
            return '#4C5ECF';
        } else if (publisher_id === 67) {
            return '#4C5ECF';
        } else if (publisher_id === 34) {
            return '#3AB65D';
        } else if (publisher_id === 68) {
            return '#00A3E0';
        } else {
            return '#4C5ECF';
        }

    } catch (error) {
        console.error('Error loading logo module:', error);
        return '#4C5ECF';
    }
}

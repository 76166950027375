import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import { store } from './store';


// import css
import "./assets/css/remixicon.css";

// import rsuite css
import "./scss/rsuite-custom.scss";
import 'rsuite/dist/rsuite-no-reset.min.css';

import { Auth } from "./components/Auth";
import { TrackUserActions } from "./mixpanel/TrackUserActions";
import { abbreviationsMap } from "./modules/maps";
import { CookiesKeyNames, CookiesProvider, isCookiesEnabled } from "./contexts/CookiesContext";
import { isIOSOrSafari } from "./helpers/is-ios-or-safari";
import Cookies from "universal-cookie";

const cookies = new Cookies();

async function notifyUser(notificationText = "Thank you for enabling notifications!",
  options = {
    body: "Now you will receive notifications from Revbox",
    icon: "https://revbox-email-icons.s3.eu-central-1.amazonaws.com/logo_icon-white.png",
    dir: "ltr",
  }) {
  if (!("Notification" in window)) {
    alert("Browser does not support notifications");
  } else if (Notification.permission ===
    "granted") {
    const notification = new Notification(notificationText, options).onclick = function () {
      window.open("https://github.com/revbox/revbox-hub/tree/develop");
    };
  } else if (Notification.permission !==
    "denied") {
    await Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        const notification = new Notification(notificationText, options);
      }
    })
  }
}

function getPublisherId() {
  const searchParams = new URLSearchParams(window.location.search);
  const urlDomain = window.location.hostname;
  const pubParam = searchParams.get('pub');

  const publisherIdFromCookies = isCookiesEnabled ? cookies.get(CookiesKeyNames.publisherId) : localStorage.getItem(CookiesKeyNames.publisherId);
  
  if (pubParam) {
    if (abbreviationsMap[pubParam]) {
      return abbreviationsMap[pubParam];
    } else {
      return pubParam;
    }
  }

  if (urlDomain === "ppc.hoteltechreport.com") {
    return 67;
  }

  if (urlDomain === "app.netnetix.com") {
    return 34;
  }
  
  if (publisherIdFromCookies) {
    return Number(publisherIdFromCookies);
  }

  return 1;
}

// add pasword to App
export default function App() {
  console.log('%cStop!', 'color: red; font-size: 30px; font-weight: bold;');
  console.log(`This is a browser feature intended for developers. If someone told you to copy-paste something here to enable a Revbox feature or "hack" someone's account, it is a scam and will give them access to your Revbox account.`, 'color: black; font-size: 30px; font-weight: bold;');

  const publisher_id = getPublisherId();
  const [userResponded, setUserResponded] = useState(false);

  useEffect(
    () => {
      if (publisher_id) {
        let skinMode = isCookiesEnabled ? cookies.get(CookiesKeyNames.skinMode) : localStorage.getItem(CookiesKeyNames.skinMode);
        let HTMLTag = document.querySelector("html");

        import(`./modules/styles/${publisher_id}.scss`)
          .then(() => console.log("Stylesheet loaded"))
          .catch(() => {
            import(`./modules/styles/default.scss`)
              .then(() => console.log("Default stylesheet loaded"))
              .catch(() => console.log("No stylesheet loaded"));
          });

        if (skinMode) {
          HTMLTag.setAttribute("data-skin", skinMode);
        }
      } else {
        import(`./modules/styles/default.scss`)
          .then(() => console.log("Default stylesheet loaded"))
          .catch(() => console.log("No stylesheet loaded"));
      }
    },
    [publisher_id]
  );

  useEffect(() => {
    if (!isIOSOrSafari()) {
      if (!userResponded && Notification.permission !== "granted") {
        notifyUser().then(() => {
          setUserResponded(true)
        });
      }
    } else {
      console.log("Not running notifyUser since the browser is Safari or iOS.");
    }
  }, []);

  return (
    <>
      {/* <button onClick={() => notifyUser()}>CLICK ME</button> */}
      <CookiesProvider>
        <Provider store={store}>
          <React.Fragment>
            <BrowserRouter>
              <TrackUserActions />
              <Routes>
                {publicRoutes.map((route, index) => {
                  return (
                    <Route
                      path={route.path}
                      element={route.element}
                      key={index}
                    />
                  )
                })}
                <Route path="/" element={
                  <Auth>
                    <Main />
                  </Auth>
                }>
                  {protectedRoutes.map((route, index) => {
                    return (
                      <Route
                        path={route.path}
                        element={route.element}
                        key={index}
                      />
                    )
                  })}
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
            <ToastContainer />
          </React.Fragment>
        </Provider>
      </CookiesProvider>
    </>
  );
}
import { getToken } from "../../helpers/get-token";
import axiosInstance from "../../axios/instance";

async function getFileByUrl(file_url) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const response = await axiosInstance.get(process.env.REACT_APP_HUB_URL + `/api/files/images/by-url`,
            {
                params: {
                    file_url
                }
            }
        );
        return response.data.data;
    } catch (error) {
        console.log(error);
    }
}

export default getFileByUrl;

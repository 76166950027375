import React from 'react';
import { DateRangePicker } from 'rsuite';
import isAfter from 'date-fns/isAfter';


export default function DatePicker ({
    ranges,
    onChange,
    currentPeriod,
    isDateRangePickerOpen,
    setIsDateRangePickerOpen
}) {
  
  return (
    <div style={{position: "relative"}}>
      <div 
        style={{
          position: "fixed", 
          bottom: 0, 
          right: 0, 
          top: 0, 
          left: 0, 
          width:"100vw", 
          height:"100vh", 
          backgroundColor:"inherit", 
          zIndex: 1000, 
        }} 
       onClick={()=> setIsDateRangePickerOpen(false)}/>
          <DateRangePicker
            block
            ranges={ranges}
            onChange={onChange}
            value={currentPeriod}
            placement="bottomEnd"
            format="dd MMM yyyy"
            open={isDateRangePickerOpen}
            onOk={() => setIsDateRangePickerOpen(false)}
            shouldDisableDate={date => isAfter(date, new Date())}
        />
    </div>

  );
};
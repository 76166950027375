import React from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import { Col } from 'react-bootstrap';

const MultipleFilesUploadInput = ({
    onScreenshotsChange,
    screenshotsInputRef,
    croppedScreenshotsPreviews,
    handleRemoveScreenshot,
    handleDragEnd,
}) => (
    <Col md>
        <form className="file-input-form" method="post" encType="multipart/form-data">
            <div style={{ height: 0 }}>
                <input
                    type="file"
                    multiple
                    id="screenshots_upload"
                    name="screenshots_uploads"
                    accept=".png,.jpg,.jpeg"
                    onChange={onScreenshotsChange}
                    ref={screenshotsInputRef}
                    style={{ opacity: 0 }}
                />
            </div>
            <div className="input-file-preview">
                <label htmlFor="screenshots_upload">Upload Screenshots</label>
                <p className='vertical-stick'></p>
                <p id="screenshots-input-placeholder">{croppedScreenshotsPreviews ? "Screenshots loaded" : "No files selected"} </p>
            </div>
        </form>
        {croppedScreenshotsPreviews.length > 0 && (
            <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={croppedScreenshotsPreviews}
                    strategy={horizontalListSortingStrategy}
                >
                    <div className="sortable-list">
                        {croppedScreenshotsPreviews.map((preview, index) => (
                            <SortableItem
                                key={`${preview}-${index}`}
                                id={preview}
                                imageSrc={preview}
                                index={index}
                                onRemove={handleRemoveScreenshot}
                            />
                        ))}
                    </div>
                </SortableContext>
            </DndContext>
        )}
    </Col>
);

export default MultipleFilesUploadInput;

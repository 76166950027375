import {
  Col,
  Row,
  Form,
  Nav,
  Card,
  Modal,
  Button,
  Badge,
  Table,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { getUserRole } from "../helpers/user-role";
import {
  editUser,
  createUser,
  getMyUsers,
  getMe,
  changePassword,
  getAllSubRoles,
  getAllAccessLevels,
  deleteUser,
  getAllRoles,
} from "../services/micro";
import { setUser } from "../features/user";
import {
  getWebhook,
} from "../services/pub";
import NotificationsCard from "../components/NotificationsCard";
import { getUserProfile } from "../helpers/user-profile";
import { CookiesKeyNames, useCookiesContext } from "../contexts/CookiesContext";

const DEFAULT_ACCESS_LEVELS = [2, 3, 4, 5];

export const AdvertiserSettings = () => {
  const { cookiesData } = useCookiesContext();
  const currentSkin = cookiesData[CookiesKeyNames.skinMode] ? "dark" : "";
  const user = useSelector((state) => state.user);
  const [userProfile, setUserProfile] = useState();
  const [skin, setSkin] = useState(currentSkin);
  const [myUsers, setMyUsers] = useState([]);
  const [userAccesses, setUserAccesses] = useState([]);
  const [subRoles, setSubRoles] = useState([]);
  const [accessLevels, setAccessLevels] = useState([]);
  const [webhook, setWebhook] = useState();
  const [exUser, setEditUser] = useState({
    firstName: userProfile?.first_name,
    lastName: userProfile?.last_name,
    email: userProfile?.email,
    role: userProfile?.role,
    access_levels: userProfile?.access_levels,
  });

  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    access_levels: DEFAULT_ACCESS_LEVELS,
    role: 4,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    getWebhook(user.publisher_id)
      .then((res) => {
        if (res.success) {
          setWebhook(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getAllAccessLevels()
      .then((res) => {
        if (res.success) {
          setAccessLevels(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });


    getAllSubRoles()
      .then((res) => {
        if (res.success) {
          setSubRoles(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getAllRoles()
      .then((res) => {
        if (res.success) {

          const userAccessesData = res.data.find(
            (role) => role.name === user.role
          )?.avaliable_accesses;
          setUserAccesses(userAccessesData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const [showNew, setShowNew] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const handleClose = () => {
    setShowNew(false);
    setShowEdit(false);
  };

  const handleShowNew = () => {
    setShowNew(true);
  };

  const handleShowEdit = (user) => {
    setEditUser({
      id: user.id,
      firstName: user.name.split(" ")[0],
      lastName: user.name.split(" ")[1],
      email: user.email,
      role: user.role,
      access_levels: user.access_levels,
    });
    setShowEdit(true);
  };

  const handleDeleteUser = (user) => {
    deleteUser(user.id)
      .then((res) => {
        if (res.success) {
          toast.success("User deleted successfully");
          getMyUsers()
            .then((res) => {
              if (res.success) {
                setMyUsers(res.data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          toast.error("User deletion failed");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("User deletion failed");
      });
  };

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  useEffect(() => {
    getMyUsers()
      .then((res) => {
        if (res.success) {
          setMyUsers(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChangePassword = (user_id) => {
    changePassword(user_id)
      .then((res) => {
        if (res && res.success) {
          toast.success("We sent you an email to change your password");
        } else {
          toast.error("Password change failed");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Password change failed");
      });
  };

  const handleChangeProfile = (e, field) => {
    setUserProfile({
      ...userProfile,
      [field]: e.target.value,
    });
  };

  const handleChangeNewUser = (e, field) => {
    setNewUser({
      ...newUser,
      [field]: e.target.value,
    });
  };

  const getExUserPermission = (access_levels) => {
    if (!access_levels) {
      return "view";
    }

    const GET = accessLevels.find((level) => level.name === "GET");
    const POST = accessLevels.find((level) => level.name === "POST");
    const UPDATE = accessLevels.find((level) => level.name === "UPDATE");
    const DELETE = accessLevels.find((level) => level.name === "DELETE");

    if (
      access_levels.includes(GET.id) &&
      access_levels.includes(POST.id) &&
      access_levels.includes(UPDATE.id) &&
      access_levels.includes(DELETE.id)
    ) {
      return "all";
    } else if (
      access_levels.includes(GET.id) &&
      access_levels.includes(POST.id) &&
      access_levels.includes(UPDATE.id)
    ) {
      return "edit";
    } else {
      return "view";
    }
  };

  const handleChangeEditUserPermissions = (e) => {
    const GET = accessLevels.find((level) => level.name === "GET");
    const POST = accessLevels.find((level) => level.name === "POST");
    const UPDATE = accessLevels.find((level) => level.name === "UPDATE");
    const DELETE = accessLevels.find((level) => level.name === "DELETE");

    if (e.target.value === "view") {
      setEditUser({
        ...exUser,
        access_levels: [GET.id],
      });
    } else if (e.target.value === "edit") {
      setEditUser({
        ...exUser,
        access_levels: [GET.id, POST.id, UPDATE.id],
      });
    } else if (e.target.value === "all") {
      setEditUser({
        ...exUser,
        access_levels: [GET.id, POST.id, UPDATE.id, DELETE.id],
      });
    }
  };

  const handleChangeNewUserPermissions = (e) => {
    const GET = accessLevels.find((level) => level.name === "GET");
    const POST = accessLevels.find((level) => level.name === "POST");
    const UPDATE = accessLevels.find((level) => level.name === "UPDATE");
    const DELETE = accessLevels.find((level) => level.name === "DELETE");

    if (e.target.value === "view") {
      setNewUser({
        ...newUser,
        access_levels: [GET.id],
      });
    } else if (e.target.value === "edit") {
      setNewUser({
        ...newUser,
        access_levels: [GET.id, POST.id, UPDATE.id],
      });
    } else if (e.target.value === "all") {
      setNewUser({
        ...newUser,
        access_levels: [GET.id, POST.id, UPDATE.id, DELETE.id],
      });
    }
  };

  const handleChangeEditUser = (e, field) => {
    setEditUser({
      ...exUser,
      [field]: e.target.value,
    });
  };

  const handleSaveChanges = () => {
    editUser(userProfile.id, {
      email: userProfile.email,
      first_name: userProfile.firstName,
      last_name: userProfile.lastName,
    })
      .then(async (res) => {
        if (res.success) {
          const me = await getMe();
          dispatch(setUser(me.data));
          toast.success("Profile updated successfully");
        } else {
          toast.error("Profile update failed");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Profile update failed");
      });
  };

  const handleEditChanges = () => {
    editUser(exUser.id, {
      email: exUser.email,
      first_name: exUser.firstName,
      last_name: exUser.lastName,
      role: exUser.role,
      access_levels: exUser.access_levels,
    })
      .then(async (res) => {
        if (res.success) {
          const users = await getMyUsers();
          setMyUsers(users.data);
          setShowEdit(false);
          toast.success("Profile updated successfully");
        } else {
          toast.error("Profile update failed");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Profile update failed");
      });
  };

  const handleCreateNewUser = () => {
    createUser({
      email: newUser.email,
      first_name: newUser.firstName,
      last_name: newUser.lastName,
      access_levels: newUser.access_levels,
      role: newUser.role,
    })
      .then((res) => {
        if (res.success) {
          toast.success("New user created successfully");
        } else {
          toast.error("New user creation failed");
        }

        setNewUser({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          role: 4,
          access_levels: [],
        });

        getMyUsers()
          .then((res) => {
            if (res.success) {
              setMyUsers(res.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });

        handleClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error("New user creation failed");
        handleClose();
      });
  };
  useEffect(() => {
    if (user) {
      const newUserProfile = getUserProfile(user);
      setUserProfile(newUserProfile);
    }
  }, [user]);


  if (userProfile)
    return (
      <React.Fragment>
        <Header onSkin={setSkin} />
        <div className="main main-app p-3 p-lg-4">
          <ol className="breadcrumb fs-sm mb-2">
            <li className="breadcrumb-item">
              <Link to="#">{getUserRole(user)}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Settings
            </li>
          </ol>
          <h2 className="main-title">Settings</h2>
          <Nav className="nav-line mb-4">
            <Nav.Link href="#user">User Account</Nav.Link>
            {userAccesses.includes("user_account_management_access") && (
              <Nav.Link href="#account">User Management</Nav.Link>
            )}
            {userAccesses.includes("notifications_access") && (
              <Nav.Link href="#notifications">Notifications</Nav.Link>
            )}
          </Nav>

          <Card id="user" className="card-settings">
            <Card.Header>
              <Card.Title className="mb-0 d-flex justify-content-between">
                <div>
                  User Account{" "}
                  <Badge bg="primary" className="ms-2">
                    {getUserRole(user)}
                  </Badge>
                </div>
                <div>
                  <Button
                    variant="outline-primary"
                    onClick={handleSaveChanges}
                    size="sm"
                  >
                    Save Changes
                  </Button>
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="5">
                    <h6>First Name</h6>
                    <p>The first name of the user</p>
                  </Col>
                  <Col md>
                    <Form.Control
                      type="text"
                      placeholder="First name"
                      value={userProfile.firstName}
                      onChange={(e) => handleChangeProfile(e, "firstName")}
                    />
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="5">
                    <h6>Last Name</h6>
                    <p>The last name of the user</p>
                  </Col>
                  <Col md>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      value={userProfile.lastName}
                      onChange={(e) => handleChangeProfile(e, "lastName")}
                    />
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="5">
                    <h6>Email</h6>
                    <p>The email address of the user</p>
                  </Col>
                  <Col md>
                    <Form.Control
                      type="text"
                      placeholder="Enter email address"
                      value={userProfile.email}
                      onChange={(e) => handleChangeProfile(e, "email")}
                    />
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="5">
                    <h6>Password</h6>
                    <p>Should be at least 8 characters long</p>
                  </Col>
                  <Col md>
                    <Button
                      variant="outline-primary"
                      onClick={handleChangePassword}
                      size="sm"
                    >
                      Change Password
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>

          {userAccesses.includes("user_account_management_access") && (
            <Card id="account" className="card-settings mt-3">
              <Card.Header>
                <div className="d-flex justify-content-between">
                  <Card.Title>User Management</Card.Title>
                  <div className="card-settings">
                    <Button
                      variant="outline-primary"
                      onClick={handleShowNew}
                      size="sm"
                      title="This feature is not available yet"
                    >
                      Add User
                    </Button>
                    <Modal show={showNew} onHide={handleClose} centered>
                      <Modal.Header className="card-one" closeButton>
                        <Modal.Title>Add User</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="p-4 card-one">
                        <Row className="g-2">
                          <Col>
                            <Form.Label htmlFor="firstName">
                              First name
                            </Form.Label>
                            <Form.Control
                              id="firstName"
                              type="text"
                              placeholder="First name"
                              aria-label="First name"
                              onChange={(e) =>
                                handleChangeNewUser(e, "firstName")
                              }
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="lastName">
                              Last name
                            </Form.Label>
                            <Form.Control
                              id="lastName"
                              type="text"
                              placeholder="Last name"
                              aria-label="Last name"
                              onChange={(e) =>
                                handleChangeNewUser(e, "lastName")
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2 g-2">
                          <Col>
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control
                              id="email"
                              type="email"
                              placeholder="Email"
                              aria-label="Email"
                              onChange={(e) => handleChangeNewUser(e, "email")}
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="role">Role</Form.Label>
                            <Form.Select
                              id="role"
                              aria-label="Default select example"
                              onChange={(e) => handleChangeNewUser(e, "role")}
                            >
                              {subRoles &&
                                subRoles.map((role, index) => (
                                  <option key={index} value={role.id}>
                                    {role.alias}
                                  </option>
                                ))}
                            </Form.Select>
                          </Col>
                        </Row>
                        <Row className="mt-2 g-2">
                          <Col>
                            <Form.Label htmlFor="permission">
                              Permissions
                            </Form.Label>
                            <Form.Select
                              isMulti
                              id="permission"
                              aria-label="Default select example"
                              onChange={(e) =>
                                handleChangeNewUserPermissions(e)
                              }
                            >
                              <option value="view">View</option>
                              <option value="edit">View & Edit</option>
                              <option value="all">View, Edit & Delete</option>
                            </Form.Select>
                          </Col>
                        </Row>
                        {/* <Row className="mt-2 g-2">
                        <Col>
                          <Form.Label htmlFor="password">Password</Form.Label>
                          <Form.Control id="password" type="password" placeholder="********" aria-label="Password" onChange={(e) => handleChangeNewUser(e, 'password')} />
                        </Col>
                      </Row> */}
                      </Modal.Body>
                      <Modal.Footer className="card-one">
                        <Button variant="outline-primary" onClick={handleClose}>
                          Discard
                        </Button>
                        <Button variant="primary" onClick={handleCreateNewUser}>
                          Invite User
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <Modal show={showEdit} onHide={handleClose} centered>
                      <Modal.Header className="card-one" closeButton>
                        <Modal.Title>Edit User</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="p-4 card-one">
                        <Row className="g-2">
                          <Col>
                            <Form.Label htmlFor="firstName">
                              First name
                            </Form.Label>
                            <Form.Control
                              id="firstName"
                              type="text"
                              placeholder="First name"
                              aria-label="First name"
                              onChange={(e) =>
                                handleChangeEditUser(e, "firstName")
                              }
                              value={exUser.firstName}
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="lastName">
                              Last name
                            </Form.Label>
                            <Form.Control
                              id="lastName"
                              type="text"
                              placeholder="Last name"
                              aria-label="Last name"
                              onChange={(e) =>
                                handleChangeEditUser(e, "lastName")
                              }
                              value={exUser.lastName}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2 g-2">
                          <Col>
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control
                              id="email"
                              type="email"
                              placeholder="Email"
                              aria-label="Email"
                              onChange={(e) => handleChangeEditUser(e, "email")}
                              value={exUser.email}
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="role">Role</Form.Label>
                            <Form.Select
                              id="role"
                              aria-label="Default select example"
                              onChange={(e) => handleChangeEditUser(e, "role")}
                              value={exUser.role}
                            >
                              {subRoles &&
                                subRoles.map((role, index) => (
                                  <option key={index} value={role.id}>
                                    {role.alias}
                                  </option>
                                ))}
                            </Form.Select>
                          </Col>
                        </Row>
                        <Row className="mt-2 g-2">
                          <Col>
                            <Form.Label htmlFor="permission">
                              Permissions
                            </Form.Label>
                            <Form.Select
                              isMulti
                              id="permission"
                              aria-label="Default select example"
                              onChange={(e) =>
                                handleChangeEditUserPermissions(e)
                              }
                              value={getExUserPermission(exUser.access_levels)}
                            >
                              <option value="view">View</option>
                              <option value="edit">View & Edit</option>
                              <option value="all">View, Edit & Delete</option>
                            </Form.Select>
                          </Col>
                        </Row>
                        {/* <Row className="mt-2 g-2">
                        <Col>
                          <Form.Label htmlFor="password">Password</Form.Label>
                          <Form.Control id="password" type="password" placeholder="********" aria-label="Password" onChange={(e) => handleChangeNewUser(e, 'password')} />
                        </Col>
                      </Row> */}
                      </Modal.Body>
                      <Modal.Footer className="card-one">
                        <Button variant="outline-primary" onClick={handleClose}>
                          Discard
                        </Button>
                        <Button
                          variant="outline-primary"
                          onClick={() => handleChangePassword(exUser.id)}
                        >
                          Send Password Reset
                        </Button>
                        <Button variant="primary" onClick={handleEditChanges}>
                          Update User
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="p-0">
                <div className="setting-item">
                  <Row className="g-2">
                    <Col md="5">
                      <h6>Account Users</h6>
                      <p>The list of account users</p>
                    </Col>
                    <Col md>
                      <Table className="mb-0" responsive>
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Role</th>
                            <th scope="col">Last Active</th>
                            <th scope="col">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {user && (
                            <tr style={{ opacity: 0.5 }}>
                              <td>
                                <div className="d-flex align-items-center gap-2">
                                  <div className="avatar">
                                    <span class="avatar-initial bg-primary">
                                      {user.first_name[0]}
                                    </span>
                                  </div>
                                  <span>
                                    {user.first_name} {user.last_name}
                                  </span>
                                </div>
                              </td>
                              <td>{user.email}</td>
                              <td>{getUserRole(user)}</td>
                              <td>Now</td>
                              <td></td>
                            </tr>
                          )}
                          {myUsers &&
                            myUsers.map((user, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="d-flex align-items-center gap-2">
                                    <div className="avatar">
                                      <span class="avatar-initial bg-primary">
                                        {user.name[0]}
                                      </span>
                                    </div>
                                    <span>{user.name}</span>
                                  </div>
                                </td>
                                <td>{user.email}</td>
                                <td>
                                  {
                                    subRoles.find(
                                      (role) => role.id === user.role
                                    )?.alias
                                  }
                                </td>
                                <td>
                                  {user.date
                                    ? new Date(
                                      Number(user.date)
                                    ).toLocaleDateString()
                                    : "N/A"}
                                </td>
                                <td>
                                  <Nav as="nav">
                                    <Link
                                      to=""
                                      onClick={() => handleShowEdit(user)}
                                    >
                                      <i className="ri-pencil-line"></i>
                                    </Link>
                                    <Link
                                      to=""
                                      onClick={() => handleDeleteUser(user)}
                                    >
                                      <i className="ri-delete-bin-line"></i>
                                    </Link>
                                  </Nav>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          )}

          {userAccesses.includes("notifications_access") &&
            (<NotificationsCard user={user} userAccesses={userAccesses} />)
          }
          <Footer />
        </div>
      </React.Fragment>
    );
};

import React, { useEffect, useState } from "react";
import { getAllCategories } from "../../services/pub";
import Header from "../../layouts/Header";
import { Breadcrumbs } from "./components/Breadcrumbs";
import Footer from "../../layouts/Footer";
import { AddItemButton } from "./components/AddItemButton";
import { switchSkin } from "../../helpers/switch-skin";
import BasicTable from "../../components/BasicTable";
import { CookiesKeyNames, useCookiesContext } from "../../contexts/CookiesContext";

const colWidth = {
  name: 330,
  min_cpc: 200,
  url: 400,
  external_id: 200,
};

const hiddenColumns = [];

const rowFields = ["name", "website_id", "min_cpc", "url", "external_id"]

export default function PublisherCategories() {
  const title = "Categories"
  const breadcrumb = "Inventory"
  const new_btn_title = "New Category"
  const new_btn_redirect = "/pub/category/new"
  const edit_redirect = "/pub/category/edit"
  const { cookiesData } = useCookiesContext();

  const currentSkin = cookiesData[CookiesKeyNames.skinMode] ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app d-flex flex-column p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Breadcrumbs breadcrumb={breadcrumb} title={title} />
          <AddItemButton new_btn_title={new_btn_title} new_btn_redirect={new_btn_redirect} />
        </div>

        <BasicTable getData={getAllCategories} url={edit_redirect} hiddenColumns={hiddenColumns} colWidth={colWidth} rowFields={rowFields} skin={skin} />

        <Footer />
      </div>
    </React.Fragment>
  )
}
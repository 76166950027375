import React from "react";
import RecordEdit from "../../components/RecordEdit";

export default function PublisherWebsiteEdit() {
    const urlParams = new URLSearchParams(window.location.search);
    const values = Object.fromEntries(urlParams.entries());
    
    return (
        <RecordEdit
            values={values}
            title="Edit Publisher Website"
            table_name="publisher_websites"
            breadcrumb="Website"
            redirect="/pub/websites"
            item_name="Website"
        />
    )
}
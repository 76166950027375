import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function updateEmailCustomization(new_customization) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.put(process.env.REACT_APP_HUB_URL + '/api/emails/update-email-customization', {
            new_customization
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default updateEmailCustomization;

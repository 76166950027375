import { createSlice } from '@reduxjs/toolkit'

export const advertiserSlice = createSlice({
  name: 'advertiser',
  initialState: {
    id: null
  },
  reducers: {
    setAdvertiser: (state, action) => {
      if (action.payload.id) {
        state.id = action.payload.id
      }
    },
    clearAdvertiser: (state) => {
      state.id = null
    }
  },
})

// Action creators are generated for each case reducer function
export const { setAdvertiser, clearAdvertiser } = advertiserSlice.actions

export default advertiserSlice.reducer
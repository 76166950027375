import axios from "axios";

async function login(email, password) {
    try {
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/auth/login', {
            email: email,
            password: password
        })
        
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default login;

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../../helpers/get-cropped-img';

const resizeImage = (imageBlob, width, height) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    img.onload = () => {
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob(resolve, "image/jpeg", 0.95);
    };

    img.onerror = reject;
    img.src = URL.createObjectURL(imageBlob);
  });
};

const ImageCropModal = React.forwardRef(({
  modalShow,
  modalHandleClose,
  imageFiles,
  onSave,
  dimensions,
}, ref) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [startZoom, setStartZoom] = useState(1);
  const [zoom, setZoom] = useState(startZoom);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  const { width, height } = dimensions;

  useEffect(() => {
    setStartZoom(dimensions.width < 800 ? 0.9 : 1)

    if (imageFiles && imageFiles?.length > 0 && currentImageIndex < imageFiles?.length) {
      const imageFile = imageFiles[currentImageIndex];
      const objectUrl = URL.createObjectURL(imageFile);
      setImageSrc(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [imageFiles, currentImageIndex]);

  const onCropComplete = (croppedArea, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  };

  const handleSave = async () => {
    try {
      const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      const resizedImageBlob = await resizeImage(croppedImageBlob, width, height);

      const resizedFile = new File([resizedImageBlob], imageFiles[currentImageIndex].name, {
        type: imageFiles[currentImageIndex].type,
      });

      onSave(resizedFile, currentImageIndex);

      if (currentImageIndex < imageFiles?.length - 1) {
        setCurrentImageIndex(currentImageIndex + 1);
      } else {
        modalHandleClose();
        setCurrentImageIndex(0)
      }
    } catch (error) {
      console.error("Error cropping or resizing the image:", error);
    }
  };

  const cropWidth = width < 800 ? width * 3 : 800;
  const cropHeight = height < 450 ? height * 3 : 450;

  return (
    <Modal
      show={modalShow}
      onHide={() => {
        if (ref.current) {
          ref.current.value = "";
        }
        modalHandleClose();
      }}
      size="xl"
      className="image-crop-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Crop this image ({currentImageIndex + 1} / {imageFiles?.length})</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ position: "relative", width: "100%", height: "500px" }}>
          {imageSrc ? (
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              maxZoom={1.6}
              minZoom={startZoom}
              aspect={width / height}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropSize={{ width: cropWidth, height: cropHeight }}
            />
          ) : (
            <p>Loading image...</p>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3" style={{ width: "100%" }}>
          <Form.Group as={Row} className="align-items-center" style={{ flex: 1 }}>
            <Col xs="auto">
              <Form.Label id="Zoom" style={{ color: "black" }}>Zoom</Form.Label>
            </Col>
            <Col>
              <Form.Range
                value={zoom}
                min={startZoom}
                max={1.6}
                step={0.1}
                onChange={(e) => setZoom(e.target.value)}
                aria-labelledby="Zoom"
              />
            </Col>
          </Form.Group>

          <Button
            style={{ marginLeft: "40px" }}
            variant="outline-primary"
            size="sm"
            onClick={handleSave}
          >
            {`Crop & Save (${currentImageIndex + 1} / ${imageFiles?.length})`}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default ImageCropModal;

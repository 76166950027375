import { Link } from "react-router-dom";

export function Breadcrumbs({ breadcrumb, breadcrumb_redirect, title }) {
    return (
        <div>
            <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                    <Link href="/pub/dashboard/revenue" to="/pub/dashboard/revenue">
                        PUB Home
                    </Link>
                </li>
                <li className="breadcrumb-item">
                    {breadcrumb}
                </li>
                <li className="breadcrumb-item" aria-current="page">
                    {title}
                </li>
            </ol>
            <h2 className="main-title mb-0">{title}</h2>
        </div>
    )
}
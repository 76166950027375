export function StatusRenderer({ value }) {
  if (!value) {
    return null;
  }
    const statusMap = {
      PENDING: "badge bg-secondary w-100",
      LIVE: "badge bg-success w-100",
      INACTIVE: "badge bg-danger w-100",
      PAUSED: "badge bg-warning w-100",
      INHERITED: "badge bg-warning w-100",
      "BID ACTIVE": "badge bg-success w-100",
    };
  
    let status = value.split("@")[0].toUpperCase();
    let views = Number(value.split("@")[1]);
    let className = statusMap[status];

    // if (status === "LIVE") {
    //   status = "Bid Active".toUpperCase();
    // } 

    // if (views === 0) {
    //   status = "Inherited".toUpperCase();
    //   className = "badge bg-warning w-100";
    // }
    

    return className ? <span className={className}>{status.toUpperCase()}</span> : 
    <span className="badge bg-secondary w-100">PENDING</span>;
}

export function StatusCellTooltipRenderer({ value }) {
  if (!value) {
    return null;
  }
  
  let status = value.split("@")[0].toUpperCase();

  if (status === "PENDING") {
    return "Your bid is pending, your campaign will begin delivering shortly";
  }

  if (status === "PAUSED") {
    return "Your campaign is paused because you've reached your monthly budget limit. To resume, please increase your budget or wait until it resets next month.";
  }

  return "Your status is " + status;
}

  
import axios from "axios";
import { getToken } from "../../helpers/get-token";
import { startOfDayUTC } from "../../helpers/start-of-day-utc";
import { endOfDayUTC } from "../../helpers/end-of-day-utc";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";

async function getPeriodInventoryInsightsRevenue(currentPeriod, previousPeriod, inventoryId) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/analytics/inventory/insights/period/revenue', {
            current_period: [startOfDayUTC(normalizeToUTC(currentPeriod[0])), endOfDayUTC(normalizeToUTC(currentPeriod[1]))],
            previous_period: [startOfDayUTC(normalizeToUTC(previousPeriod[0])), endOfDayUTC(normalizeToUTC(previousPeriod[1]))],
            inventory_id: inventoryId
        })
        return response.data?.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodInventoryInsightsRevenue;

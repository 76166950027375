import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

export default function TextInput({ label, required, placeholder, value, onChange, type = "text" }) {
    return (
        <div className="setting-item">
            <Row className="g-2 align-items-center">
                <Col md="2">
                    <h6>{label}</h6>
                    {required && <p>Required</p>}
                </Col>
                <Col md>
                    <Form.Control
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                    />
                </Col>
            </Row>
        </div>
    )
}



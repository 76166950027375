import React from "react";
import RecordCreate from "../../components/RecordCreate";

export default function PublisherCampaignCreate() {
    return (
        <RecordCreate
            title="Create Campaign"
            table_name="client_campaigns"
            breadcrumb="Campaign"
            redirect="/pub/customers/campaigns"
        />
    )
}
import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function getAllNotifications(limit, offset, status) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.get(process.env.REACT_APP_HUB_URL + '/api/notifications/all', {params: {limit, offset, status} })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getAllNotifications;

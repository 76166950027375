import React from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import { Col, Row } from 'react-bootstrap';

const ScreenshotsUpload = ({
  onScreenshotsChange,
  screenshotsInputRef,
  croppedScreenshotsPreviews,
  handleRemoveScreenshot,
  handleDragEnd,
  dimensions,
}) => (
  <div className="setting-item">
    <Row className="g-2 align-items-center">
      <Col md="2">
        <h6>Screenshots</h6>
        <p>1 min, add up to 4<br />{`${dimensions.width}x${dimensions.height}px`}, JPG/PNG</p>
      </Col>
      <Col md>
        <form className="file-input-form" method="post" encType="multipart/form-data">
          <div>
            <input
              type="file"
              multiple
              id="screenshots_upload"
              name="screenshots_uploads"
              accept=".png,.jpg,.jpeg"
              onChange={onScreenshotsChange}
              ref={screenshotsInputRef}
              style={{ opacity: 0 }}
            />
          </div>
          <div className="input-file-preview">
            <label htmlFor="screenshots_upload">Upload Screenshots</label>
            <p className='vertical-stick'></p>
            <p id="screenshots-input-placeholder">No files currently selected for upload</p>
          </div>
        </form>
        {croppedScreenshotsPreviews.length > 0 && (
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={croppedScreenshotsPreviews}
              strategy={horizontalListSortingStrategy}
            >
              <div className="sortable-list">
                {croppedScreenshotsPreviews.map((preview, index) => (
                  <SortableItem
                    key={`${preview}-${index}`}
                    id={preview}
                    imageSrc={preview}
                    index={index}
                    onRemove={handleRemoveScreenshot}
                  />
                ))}
              </div>
            </SortableContext>
          </DndContext>
        )}
      </Col>
    </Row>
  </div>
);

export default ScreenshotsUpload;

import chroma from 'chroma-js';

export function generateCompatibleColors(primaryColor, numColors = 10) {
    const colors = [primaryColor]; 

    for (let i = 1; i < numColors; i++) { 
        const hueShift = i * (360 / numColors);
        const newColor = chroma(primaryColor).set('hsl.h', hueShift).hex();
        colors.push(newColor);
    }

    return colors;
}
export default async function getCroppedImg(imageSrc, crop) {
    const image = new Image();
    image.src = imageSrc;

    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const img = new Image();
            img.addEventListener('load', () => resolve(img));
            img.addEventListener('error', (err) => reject(err));
            img.src = url;
        });

    const loadedImage = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
        loadedImage,
        crop.x, crop.y, crop.width, crop.height, // source image
        0, 0, crop.width, crop.height            // destination canvas
    );

    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            resolve(blob);
        }, 'image/jpeg');
    });
}

import axios from 'axios';
import { getToken } from "../helpers/get-token";

export default function verifyToken() {
    const token = getToken();

    if (!token) {
        window.location.href = '/pages/signin'; 
        return false;
    }
  
    const response = axios.get(process.env.REACT_APP_HUB_URL + '/api/auth/verify-token/' + token);
    
    response.then((res) => {
        if (!res.data.success) {
            window.location.href = '/pages/signin'; 
            return false;
        } else {
            return true;
        }
    }).catch((err) => {
        window.location.href = '/pages/signin'; 
        return false;
    });
}

import React from "react";
import RecordCreate from "../../components/RecordCreate";

export default function PublisherCategoryCreate() {
    return (
        <RecordCreate
            title="Create Publisher Category"
            table_name="publisher_categories"
            breadcrumb="Category"
            redirect="/pub/categories"
            item_name="Category"
        />
    )
}
import React from "react";
import RecordEdit from "../../components/RecordEdit";

export default function PublisherUserEdit() {
    const urlParams = new URLSearchParams(window.location.search);
    const values = Object.fromEntries(urlParams.entries());

    return (
        <RecordEdit
            values={values}
            title="Edit User"
            table_name="users"
            breadcrumb="User"
            redirect="/pub/customers/users"
            item_name="User"
        />
    )
}
export function convertToCamelCaseArray(data) {
    const { rows, columns } = data;

    const toCamelCase = (str) => {
        if (str.toUpperCase() === 'ID') {
            return str.toUpperCase();
        } else
            return str
                .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
                    index === 0 ? match.toLowerCase() : match.toUpperCase()
                )
                .replace(/\s+/g, '')
                .replace(/^ID$/, 'id');
    };

    const camelCaseColumns = columns.map(toCamelCase);

    const result = rows.map(row => {
        return row.reduce((obj, value, index) => {
            obj[camelCaseColumns[index]] = value;
            return obj;
        }, {});
    });

    return result;
}

import React from "react";
import { Button } from "react-bootstrap";

export function ActionRenderer({ value }) {
  if (!value) {
    return null;
  }

  const status = value.toUpperCase();

  if (status === "INACTIVE" || status === "DRAFT") {
    return (
      <button class="btn bg-success btn-sm text-white" style={{marginLeft: '5px', width: '100px'}}>
        Start Bidding
      </button>
    );
  } else {
    return (
      <div>
        <Button variant="outline-primary" size="sm" class="fs-11" style={{marginLeft: '5px', width: '100px'}}>
          Update Bid
        </Button>
      </div>
    );
  }
}

export function ActionCellTooltipRenderer({ value }) {
  if (!value) {
    return null;
  }
  
  const status = value.toUpperCase();

  if (status === "INACTIVE" || status === "DRAFT") {
    return "Start bidding";
  } else {
    return "Update your bid";
  }
}
  

import { getToken } from "../../helpers/get-token";
import axiosInstance from "../../axios/instance";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";

async function getWebsiteClicksByCluster(inventoryId, campaignId) {
    try {
        const currentPeriod = [
            new Date(new Date().setDate(new Date().getDate() - 32)),
            new Date(),
        ];

        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const response = await axiosInstance.post(process.env.REACT_APP_HUB_URL + '/api/analytics/website/period/clicks-by-cluster', {
            current_period: [
                normalizeToUTC(currentPeriod[0]).getTime(),
                normalizeToUTC(currentPeriod[1]).getTime()
            ],
            campaign_id: Number(campaignId),
            inventory_id: Number(inventoryId)
        })
        return response.data.data;
    } catch (error) {
        console.log(error);
    }
}

export default getWebsiteClicksByCluster;

const validByDefault = ['ID', "CPC", "PPC"]

export function toCamelCase(str) {
    if (validByDefault.includes(str.toUpperCase())) {
        return str.toUpperCase();
    } else
        return str
            .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
                index === 0 ? match.toLowerCase() : match.toUpperCase()
            )
            .replace(/\s+/g, '')
            .replace(/^ID$/, 'id');
};
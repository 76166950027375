import React from "react";
import RecordCreate from "../../components/RecordCreate";

export default function PublisherWebsiteCreate() {
    return (
        <RecordCreate
            title="Create Publisher Website"
            table_name="publisher_websites"
            breadcrumb="Website"
            redirect="/pub/websites"
        />
    )
}
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

import { getToken } from "../../helpers/get-token";

async function loadImages(values) {
    if (!values) {
        return;
    }
    
    let formData = new FormData();
    const fileExtensionMap = {
        'image/png': '.png',
        'image/jpeg': '.jpg',
      };

    function renameFile(originalFile, newName) {
        const fileExtension = fileExtensionMap[originalFile.type];
        const newFileName = `${uuidv4()}${fileExtension}`;

        return new File([originalFile], newFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }

    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        
        const valuesToSend = Object.values(values)

        if (values.length) {
            valuesToSend.map(img => {
                const renamedImg = renameFile(img, uuidv4())

                formData.append('files',renamedImg)
            })
        } else {
            formData.append('files[]', [])
        }
        
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/files/images', formData)

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default loadImages;

import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function getMe(oldToken) {
    try {
        const token = oldToken || getToken();

        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.get(process.env.REACT_APP_HUB_URL + '/api/users/me')
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getMe;

import React from 'react';
import { Col } from 'react-bootstrap';

export const FileUploadInput = ({ onLogoChange, logoInputRef, croppedLogoPreview, logoFile }) => (
    <Col md>
        <form className="file-input-form" method="post" encType="multipart/form-data">
            <div style={{ height: 0 }}>
                <input
                    type="file"
                    id="logo_upload"
                    name="image_uploads"
                    accept=".png,.jpg,.jpeg"
                    onChange={onLogoChange}
                    ref={logoInputRef}
                    style={{ opacity: 0 }}
                />
            </div>
            <div className="input-file-preview">
                <label htmlFor="logo_upload">Upload Image</label>
                <p className='vertical-stick'></p>
                <p id="logo-input-placeholder">{croppedLogoPreview || logoFile ? "Logo loaded" : 'No file currently selected for upload'}</p>
            </div>
        </form>
        {(croppedLogoPreview || logoFile) && (
            <div className="cropped-image-preview">
                <img
                    src={croppedLogoPreview || logoFile}
                    alt="Logo Preview"
                    style={{ width: "150px", height: "150px" }}
                />
            </div>
        )}
    </Col>
);

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Modal,
  Row,
  Badge,
  Tooltip,
  Alert
} from "react-bootstrap";
import { mixpanelInstance } from "../../../mixpanel/instance";
import { formatThousand } from "../../../helpers/thousand-number-format";
import { placeholderLogoModule } from "../../../modules/placeholderLogo";
import { editInventoryCampaign, getInventoryCampaign, getInventoryCampaignByInventoryAndCampaign } from "../../../services/micro";
import getMinCpc from "../../../services/micro/get_min_cpc";
import { getCampaign, getEstimatedPosition, getEstimatedPositionOne } from "../../../services/adv";
import { GetPubAbbr } from "../../../helpers/get-pub-abbr";
import { getPublisher } from "../../../services/pub";
import { CookiesKeyNames, useCookiesContext } from "../../../contexts/CookiesContext";

const REVIEW_STATUS = 3;
const DRAFT_STATUS = 4;
const LIVE_STATUS = 1;
const REJECTED_STATUS = 2;

const HTR_STAGE_PUBLISHER_ID = 10;
const HTR_PUBLISHER_ID = 67;
const DEFAULT_ID = 1;

function returnMessage(isHTR, estimatedMissedPositionOneClickMax, estimatedMissedClickMax, estClickOpportunity, top_bid, cpc, active, bid_amount, position, min_bid, min_increment) {
    if (!active) {
        let incerement = 0;
        if (top_bid > 0) {
            incerement = top_bid + min_increment;
        } else {
            incerement = min_bid + min_increment;
        }

        let clicks = Number(estimatedMissedPositionOneClickMax);

        if (isNaN(clicks)) {
            return '';
        }

        return `Bid $${incerement} to earn the #1 position and generate ~${clicks} clicks over the next 30 days`;
    }

    if (position === 1) {
        if (isNaN(estimatedMissedPositionOneClickMax)) {
            return '';
        }

        return `You are in the top position winning the most clicks in the inventory. Monthly clicks forecast = ${estimatedMissedPositionOneClickMax} clicks/mo`;
    }

    if (estimatedMissedClickMax === 0 && !active) {
        return '';
    }

    else if (position <= bid_amount && position !== null) {
        let incerement = 0;
        if (top_bid > 0) {
            incerement = (top_bid - cpc) + min_increment;
        } else {
            incerement = min_bid + min_increment;
        }

        if (isNaN(estimatedMissedClickMax)) {
            return '';
        }
        
        if (incerement > 0) {
            return `Your current position received ${estimatedMissedClickMax} fewer clicks over the last 30 days than the top position. Increase your bid by $${incerement} to receive ${estClickOpportunity}x the click volume.`;
        } else {
            return `You are in the top position winning the most clicks in the inventory. Monthly clicks forecast = ${estimatedMissedPositionOneClickMax} clicks/mo`;
        }
    }

    else {
        let incerement = 0;
        if (top_bid > 0) {
            incerement = top_bid + min_increment;
        } else {
            incerement = min_bid + min_increment;
        }

        let clicks = Number(estimatedMissedPositionOneClickMax);

        if (isNaN(clicks)) {
            return '';
        }

        return `Bid $${incerement} to earn the #1 position and generate ~${clicks} clicks over the next 30 days`;
    }
}


function renderStatus(campaign_active, status, ppc_bid_active, ppc_paused, inherited_cpc, inherited_bid_mode, clusterLevel) {
    if (inherited_bid_mode) {
        if (status === REVIEW_STATUS || status === "REVIEW") {
            return (
                <div className="badge bg-secondary">
                    <span>Pending</span>
                </div>
            );
        } else if (status === "REJECTED" || status === REJECTED_STATUS) {
            return (
                <div className="badge bg-danger">
                    <span>Inactive</span>
                </div>
            );
        } else {
            if (!ppc_bid_active && clusterLevel != 1) {
                return (
                    <div className="badge bg-warning">
                        <span>Inherited</span>
                    </div>
                );
            } else if (!ppc_bid_active && clusterLevel == 1) {
                return (
                    <div className="badge bg-danger">
                        <span>Inactive</span>
                    </div>
                );
            } else {
                return (
                    <div className="badge bg-success">
                        <span>Bid Active</span>
                    </div>
                );
            }
            // if (inherited_cpc === null) {
            //     return (
            //         <div className="badge bg-success">
            //             <span>Bid Active</span>
            //         </div>
            //     );
            // } else {
            //     return (
            //         <div className="badge bg-warning">
            //             <span>Inherited</span>
            //         </div>
            //     );
            // }
        }
    }
    
    if (campaign_active && ppc_bid_active && !ppc_paused) {
        return (
            <div className="badge bg-success">
            <span>Live</span>
            </div>
        );
    }

    if (campaign_active && !ppc_bid_active) {
        return (
            <div className="badge bg-danger">
            <span>Inactive</span>
            </div>
        );
    }

    if (campaign_active && ppc_paused) {
        return (
            <div className="badge bg-warning">
            <span>Paused</span>
            </div>
        );
    }

    if (campaign_active && !ppc_paused && (status === "DRAFT" || status === DRAFT_STATUS || status === REVIEW_STATUS || status === "REVIEW")) {
        return (
            <div className="badge bg-secondary">
            <span>Pending</span>
            </div>
        );
    }

    return (
        <div className="badge bg-danger">
        <span>Inactive</span>
        </div>
    );
};

export default function PPCManagmentModal({
    show,
    handleClose,
    campaignId,
    inventoryId,
    updatePage,
    token,
    setUpdatePage,
    isClusterInventoryCampaign,
    clusterLevel,
    allChildrenCount,
    anyActiveChild
}) {
    const { updateCookiesProperty, removeCookiesProperty, cookiesData } = useCookiesContext();
    const currentSkin = cookiesData[CookiesKeyNames.skinMode] ? "dark" : "";
    
    const [skin, setSkin] = useState(currentSkin);
    const [campaign, setCampaign] = useState();
    const [oldInventoryCampaign, setOldInventoryCampaign] = useState();
    const [inventoryCampaign, setInventoryCampaign] = useState();
    const [estimatedPosition, setEstimatedPosition] = useState();
    const [canPositionBeEstimated, setCanPositionBeEstimated] = useState(false);
    const [publisher, setPublisher] = useState();
    const [loading, setLoading] = useState(true);

    const publisherIdByAbbr = GetPubAbbr();

    useEffect(() => {
        if (inventoryId && campaignId) {
            getInventoryCampaignByInventoryAndCampaign(inventoryId, campaignId).then((response) => {
                if (response) {
                    setInventoryCampaign(response.data);
                    setOldInventoryCampaign(response.data);

                    getEstimatedPosition(response.data?.id, response.data?.cpc, response.data?.ppc_bid_active).then((res) => {
                        setEstimatedPosition(res.data);
                        setCanPositionBeEstimated(true);
                    });
                }
            });

            getCampaign(campaignId, token).then((response) => {
                if (response) {
                    setCampaign(response.data);
                }
            });
        }

        if (publisherIdByAbbr && publisherIdByAbbr !== "undefined" && publisherIdByAbbr !== "null") {
            getPublisher(publisherIdByAbbr).then((response) => {
                if (response) {
                    setPublisher(response.data);
                }
            });
        }
    }, [inventoryId, campaignId, token]);


    const isHTR = publisherIdByAbbr === HTR_PUBLISHER_ID || publisherIdByAbbr === HTR_STAGE_PUBLISHER_ID;
    const isDefault = publisherIdByAbbr === DEFAULT_ID;
    const estimatePosition = (canPositionBeEstimated || (campaign?.ppc_active && !campaign?.ppc_paused));

    const handleSave = () => {
        setLoading(true);
    
        if (inventoryCampaign?.custom_url?.length > 0) {
          if (inventoryCampaign?.custom_url?.length > 255) {
            toast.warn("Custom URL can't be longer than 255 characters");
            return false;
          }
    
          if (inventoryCampaign?.custom_url?.includes(" ")) {
            toast.warn("Custom URL can't contain spaces");
            return false;
          }
    
          if (!inventoryCampaign?.custom_url?.includes("http")) {
            toast.warn("Custom URL must contain http or https");
            return false;
          }
    
          if (inventoryCampaign?.custom_url?.includes("http://")) {
            toast.warn("Custom URL must contain https");
            return false;
          }
    
          if (!inventoryCampaign?.custom_url?.includes(".")) {
            toast.warn("Custom URL must contain a domain");
            return false;
          }
        }
    
        const trackInventoryCampaignChanging = (message) => {
          mixpanelInstance.track(message, {
            campaignId,
            inventoryCampaignId: inventoryCampaign.id,
            cpc: Number(inventoryCampaign.cpc),
            custom_url: inventoryCampaign.custom_url,
            ppc_bid_active: inventoryCampaign.ppc_bid_active,
          });
        };
    
        editInventoryCampaign(inventoryCampaign.id, {
          cpc: Number(inventoryCampaign.cpc),
          custom_url: inventoryCampaign.custom_url,
          position: inventoryCampaign.cpc !== oldInventoryCampaign.cpc ? 
            estimatedPosition?.estimated_position : oldInventoryCampaign.position,
          ppc_bid_active: inventoryCampaign.ppc_bid_active,
        })
          .then((res) => {
            handleClose();
    
            toast.success("Queue item saved successfully");
    
            trackInventoryCampaignChanging(
              `inventoryCampaign ${inventoryCampaign.id} saved successfully`
            );
    
            setUpdatePage(updatePage + 1);
          })
          .catch((err) => {
            toast.error("Error saving queue item");
    
            trackInventoryCampaignChanging(
              `Error saving inventoryCampaign ${inventoryCampaign.id}`
            );
          });
    };

    const updateCPC = (sign) => {
        const change = sign === "plus" ? Number(publisher?.min_increment) : -Number(publisher?.min_increment);
        const newCpc = Number((Number(inventoryCampaign.cpc) + change).toFixed(1));
    
        setInventoryCampaign({ ...inventoryCampaign, cpc: newCpc });
    
        const blockCpc = document.getElementById("blockCpc");
        blockCpc.value = newCpc;
    
        mixpanelInstance.track(
          `Change cpc for ${campaign?.name} in bid management popup`,
          {
            campaignId,
            sign,
            newCpc,
          }
        );
    };
    
    const handleSubmitBid = () => {
        setLoading(true);
    
        let submit_data = {
          cpc: Number(inventoryCampaign.cpc),
          custom_url: inventoryCampaign.custom_url,
        };
    
        if (inventoryCampaign.inventory_campaign_status !== 1) {
          submit_data = {
            ...submit_data,
            inventory_campaign_status: REVIEW_STATUS,
          };
        }
    
        editInventoryCampaign(inventoryCampaign.id, submit_data)
          .then((res) => {
            handleClose();
    
            toast.success("Bid submitted successfully");
    
            setUpdatePage(updatePage + 1);
          })
          .catch((err) => {
            toast.error("Error submitting bid");
          });
    };
    
    const handleWithdrawBid = () => {
        setLoading(true);
    
        editInventoryCampaign(inventoryCampaign.id, {
          inventory_campaign_status: DRAFT_STATUS,
        })
          .then((res) => {
            handleClose();
    
            toast.success("Bid withdrawn successfully");
    
            setUpdatePage(updatePage + 1);
          })
          .catch((err) => {
            toast.error("Error withdrawing bid");
          });
    };
    
    const trackBidChange = (message, inventoryCampaignId) => {
        mixpanelInstance.track(
          `${message} for inventory campaign ${inventoryCampaignId}`,
          {
            campaignId,
            inventoryCampaignId,
          }
        );
    };

    const handleDeactivateBid = (id) => {
        setLoading(true);
    
        const isClicked = cookiesData[CookiesKeyNames.clickedDeactivate];
    
        if (!isClicked) {
          updateCookiesProperty(CookiesKeyNames.clickedDeactivate, 'true')
    
          editInventoryCampaign(id, {
            ppc_bid_active: false,
          })
            .then((res) => {
              toast.success("Bid deactivated successfully");
              trackBidChange("Bid deactivated successfully", id);
    
              setUpdatePage(updatePage + 1);
            })
            .catch((err) => {
              toast.error("Error deactivating bid");
              removeCookiesProperty("clickedDeactivate");
              trackBidChange("Error deactivating bid", id);
            });
        }
    };

    const onChangeCpc = (e) => {
        setTimeout(() => {
          getMinCpc('inventory_campaigns', null, inventoryCampaign.inventory_id, inventoryCampaign.campaign_id).then((res) => {
            let updatedCpc = 0;
    
            if (Number(e.target.value) < res.data || isNaN(e.target.value)) {
              toast.warn("CPC can't be lower than the minimum CPC");
              setInventoryCampaign({ ...inventoryCampaign, cpc: res.data });
              const blockCpc = document.getElementById("blockCpc");
              blockCpc.value = res.data;
              updatedCpc = res.data;
            } else {
              const newCpc = Number(e.target.value);
              setInventoryCampaign({ ...inventoryCampaign, cpc: newCpc });
              const blockCpc = document.getElementById("blockCpc");
              blockCpc.value = newCpc;
              updatedCpc = newCpc;
            }
    
            getEstimatedPosition(
              inventoryCampaign.id,
              updatedCpc,
              inventoryCampaign.ppc_bid_active
            )
              .then((res) => {
                setEstimatedPosition(res.data);
                setCanPositionBeEstimated(true);
              })
              .catch((err) => {
                console.log("Error fetching estimated position");
              });
          });
        }, 2000);
    };
    
    const onChangeCpcByButton = (sign) => {
        setTimeout(() => {
          getMinCpc('inventory_campaigns', null, inventoryCampaign.inventory_id, inventoryCampaign.campaign_id).then((res) => {
            if (sign === "minus" && Number(inventoryCampaign.cpc) <= res.data) {
              toast.warn("CPC can't be lower than the minimum CPC");
              setInventoryCampaign({ ...inventoryCampaign, cpc: res.data });
              const blockCpc = document.getElementById("blockCpc");
              blockCpc.value = res.data;
              return;
            }
    
            updateCPC(sign);
    
            console.log("inventoryCampaign.cpc", inventoryCampaign.cpc);
            console.log("publisher?.min_increment", publisher?.min_increment);
    
    
            let diff = 0;
            if (sign === "plus") {
              diff = Number(publisher?.min_increment);
            } else {
              diff = -Number(publisher?.min_increment);
            }
    
            console.log("diff", diff);
    
            getEstimatedPosition(
              inventoryCampaign.id,
              Number((Number(inventoryCampaign.cpc) + diff).toFixed(1)),
              inventoryCampaign.ppc_bid_active
            )
              .then((res) => {
                setEstimatedPosition(res.data);
                setCanPositionBeEstimated(true);
              })
              .catch((err) => {
                console.log("Error fetching estimated position");
              });
          });
        }, 200);
    };
    
    const onChangePPCActive = (e) => {
        mixpanelInstance.track(
          `Change ppc active toggle for ${campaign?.name} in bid management popup`,
          {
            campaignId,
            ppcActive: e.target.checked,
          }
        );

        if (
            publisher.cluster_enforce_mode_enabled &&
            publisher.cluster_inherited_bid_mode_enabled &&
            clusterLevel === 1
        ) {
            if (!e.target.checked && allChildrenCount > 0 && !anyActiveChild) {
                toast.warn("If you want to deactivate this bid, you need to deactivate all sub-clusters first");
    
                mixpanelInstance.track(
                    `Warn deactivating bid for cluster with sub-clusters and hirarchy bidding mode`,
                    {
                        campaignId,
                        inventoryCampaignId: inventoryCampaign.id,
                    }
                );

                return;
            }
        }
    
        if (
            publisher.cluster_inherited_bid_mode_enabled &&
            publisher.cluster_enforce_mode_enabled &&
            clusterLevel !== 1
        ) {
            if (!e.target.checked && allChildrenCount > 0) {
                toast.warn("If you deactivate this bid, all sub-clusters will be inherited from the parent cluster");
    
                mixpanelInstance.track(
                    `Warn deactivating bid for cluster with sub-clusters and inherited bid mode`,
                    {
                        campaignId,
                        inventoryCampaignId: inventoryCampaign.id,
                    }
                );
            }
        }

        if (
            publisher.cluster_enforce_mode_enabled &&
            !publisher.cluster_inherited_bid_mode_enabled
        ) {
            if (!e.target.checked && allChildrenCount > 0 && !anyActiveChild) {
                toast.warn("If you want to deactivate this bid, you need to deactivate all sub-clusters first");
    
                mixpanelInstance.track(
                    `Warn deactivating bid for cluster with sub-clusters and hirarchy bidding mode`,
                    {
                        campaignId,
                        inventoryCampaignId: inventoryCampaign.id,
                    }
                );

                return;
            }
        }

        setInventoryCampaign({
          ...inventoryCampaign,
          ppc_bid_active: e.target.checked,
        });

        getEstimatedPosition(
          inventoryCampaign.id,
          Number(inventoryCampaign.cpc),
          e.target.checked
        )
          .then((res) => {
            setEstimatedPosition(res.data);
            setCanPositionBeEstimated(true);
          })
          .catch((err) => {
            console.log("Error fetching estimated position");
          });
    };
    
    const onChangeCustomUrl = (e) => {
        setInventoryCampaign({ ...inventoryCampaign, custom_url: e.target.value.trim() });
    };

    const tooltipContent = returnMessage(
        isHTR,
        estimatedPosition?.position_one_missed_clicks?.est_clicks,
        estimatedPosition?.missed_clicks?.est_missed_clicks,
        estimatedPosition?.missed_clicks?.est_click_opportunity,
        estimatedPosition?.top_bid ? estimatedPosition?.top_bid : inventoryCampaign?.stat?.top_cpc_bid,
        inventoryCampaign?.cpc,
        inventoryCampaign?.ppc_bid_active,
        estimatedPosition?.bid_amount,
        estimatedPosition?.current_position,
        inventoryCampaign?.min_bid,
        publisher?.min_increment
    )

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton className="card-one pb-2">
                <Modal.Title>
                <div className="d-flex align-items-center">
                    <span>{inventoryCampaign?.inventory?.name}</span>
                    { isClusterInventoryCampaign &&
                        <Badge bg="secondary" className="ms-2">
                            Level {clusterLevel} Topic Cluster
                        </Badge>
                    }
                </div>
                <p
                    className="d-flex gap-2 mb-0"
                    style={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#6e7985",
                    }}
                >
                    {(HTR_PUBLISHER_ID !== publisherIdByAbbr &&
                    HTR_STAGE_PUBLISHER_ID !== publisherIdByAbbr &&
                    DEFAULT_ID !== publisherIdByAbbr)
                    ? inventoryCampaign?.inventory?.related_categories_ids
                        ?.map((category) => {
                        return campaign?.columns
                            ?.find(
                            (c) => c.name === "related_categories_ids"
                            )
                            ?.options?.find((o) => o.value === category)
                            ?.label;
                        })
                        .filter((category) => category)
                        .join(", ")
                    : ""}
                </p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="card-one pt-0">
                <Row>
                <Col
                    sm="12"
                    className="text-left"
                    onClick={() =>
                    mixpanelInstance.track(
                        "Placement 1 button clicked in bid management popup",
                        {
                        campaignId,
                        }
                    )
                    }
                >
                    <OverlayTrigger
                    placement="bottom"
                    overlay={
                        inventoryCampaign?.inventory ? (
                        <Tooltip placement="bottom">
                            {inventoryCampaign?.inventory.anchor_name}
                        </Tooltip>
                        ) : (
                        <></>
                        )
                    }
                    >
                    <a
                        href={inventoryCampaign?.inventory.url}
                        target="_blank"
                        className="btn btn-sm btn-outline-primary me-1"
                    >
                        Placement 1
                        <i
                        className="ri-external-link-line"
                        style={{ marginLeft: "5px" }}
                        ></i>
                    </a>
                    </OverlayTrigger>
                    {inventoryCampaign?.alt_versions &&
                    inventoryCampaign?.alt_versions.length > 0 &&
                    inventoryCampaign?.alt_versions.map(
                        (alt_version, index) => {
                        return (
                            <OverlayTrigger
                            key={index}
                            placement="bottom"
                            overlay={
                                alt_version?.anchor_name ? (
                                <Tooltip placement="bottom">
                                    {alt_version?.anchor_name}
                                </Tooltip>
                                ) : (
                                <></>
                                )
                            }
                            >
                            <a
                                href={alt_version.custom_url}
                                target="_blank"
                                className="btn btn-sm btn-outline-primary me-1"
                            >
                                {`Placement ${index + 2}`}
                                <i
                                className="ri-external-link-line"
                                style={{ marginLeft: "5px" }}
                                ></i>
                            </a>
                            </OverlayTrigger>
                        );
                        }
                    )}
                </Col>
                <Col sm="12">
                    <div className="divider mt-2">
                    <span>Bid Management</span>
                    </div>
                </Col>
                <Col sm="12">
                    <Form>
                    <Row className="">
                        <Col sm="6">
                        <div className="text-center">
                            <Form.Label
                            htmlFor="blockCpc"
                            className="dollar-sign"
                            >
                            $
                            </Form.Label>
                            <Form.Control
                            id="blockCpc"
                            placeholder={inventoryCampaign?.min_bid}
                            onChange={(e) => onChangeCpc(e)}
                            defaultValue={
                                inventoryCampaign?.cpc ||
                                inventoryCampaign?.min_bid
                            }
                            type="text"
                            className="cpc-field"
                            />
                            <Button
                            variant="white"
                            className="button-icon-button minus"
                            onClick={() => onChangeCpcByButton("minus")}
                            >
                            <i className="ri-indeterminate-circle-line button-icon"></i>
                            </Button>
                            <Button
                            variant="white"
                            className="button-icon-button plus"
                            onClick={() => onChangeCpcByButton("plus")}
                            >
                            <i className="ri-add-circle-line button-icon"></i>
                            </Button>
                        </div>
                        <div className="auction-info">
                            <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip placement="bottom">
                                Activate your PPC bid to boost your
                                position.
                                </Tooltip>
                            }
                            >
                            <div className="auction-info-item">
                                {
                                inventoryCampaign?.ppc_bid_active ? (
                                    <i className="ri-cursor-line"></i>
                                ) : (
                                    <i className="ri-cursor-line text-danger"></i>
                                )
                                }
                                {
                                inventoryCampaign?.ppc_bid_active ? (
                                    <span className="">PPC Bid Active</span>
                                ) : (
                                    <span className="text-danger">PPC Bid Inactive</span>
                                )
                                }
                                <span className="switch fw-bold">
                                <div class="form-check form-switch">
                                    <input
                                    type="checkbox"
                                    className={
                                        inventoryCampaign?.ppc_bid_active
                                        ? "form-check-input bg-primary"
                                        : "form-check-input bg-danger unchecked"
                                    }
                                    checked={
                                        inventoryCampaign?.ppc_bid_active
                                    }
                                    onChange={(e) => onChangePPCActive(e)}
                                    />
                                </div>
                                </span>
                            </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip placement="bottom">
                                {inventoryCampaign?.inventory_campaign_status ===
                                    REVIEW_STATUS ? (
                                    "We're currently reviewing this bid. You will feature on this inventory if/when it is approved."
                                ) : (
                                    <></>
                                )}
                                {inventoryCampaign?.inventory_campaign_status ===
                                    DRAFT_STATUS ? (
                                    "You need to submit this bid for review to feature on this inventory."
                                ) : (
                                    <></>
                                )}
                                {inventoryCampaign?.inventory_campaign_status ===
                                    LIVE_STATUS ? (
                                    "You are currently live on this inventory. As long as your position is high enough you will be featured."
                                ) : (
                                    <></>
                                )}
                                </Tooltip>
                            }
                            >
                            <div className="auction-info-item">
                                <i className="ri-information-line"></i>
                                <span>Bid Status</span>
                                <span className="switch">
                                <div style={{ marginRight: "10px" }}>
                                    {
                                        renderStatus(
                                            campaign?.ppc_active,
                                            inventoryCampaign?.inventory_campaign_status,
                                            inventoryCampaign?.ppc_bid_active,
                                            campaign?.ppc_paused,
                                            inventoryCampaign?.inherited_cpc,
                                            publisher?.cluster_inherited_bid_mode_enabled,
                                            clusterLevel
                                        )
                                    }
                                </div>
                                </span>
                            </div>
                            </OverlayTrigger>
                            <div className="auction-info-item">
                            <i className="ri-auction-line"></i>
                            <span>{estimatedPosition?.current_position != inventoryCampaign?.position ? "Estimated Position" : "Current Position"}</span>
                            <span className="number inline-block">
                                {
                                    !estimatePosition ? 
                                        estimatedPosition?.bid_amount <= estimatedPosition?.estimated_position ?
                                        // if the bid amount is less than the estimated position
                                        (
                                            <div class="d-flex align-items-center">
                                                <small>
                                                    Not shown
                                                </small>
                                            </div>
                                        )
                                        :
                                        // if the bid amount is less than the current position
                                        (
                                            "#" + estimatedPosition?.estimated_position
                                        )
                                        :
                                        estimatedPosition?.current_position != estimatedPosition?.estimated_position ? (
                                        estimatedPosition?.bid_amount <= estimatedPosition?.estimated_position ?
                                            // if the bid amount is less than the estimated position
                                            (
                                            <div class="d-flex align-items-center">
                                                <small>
                                                    {estimatedPosition?.current_position !== 0 ? "#" + estimatedPosition?.current_position : "Not shown"}
                                                    {/* was beafore: Not shown */}
                                                </small>
                                            </div>
                                            )
                                            : estimatedPosition?.bid_amount <= estimatedPosition?.current_position ?
                                            // if the bid amount is less than the current position
                                            (
                                                <div class="d-flex align-items-center">
                                                <small
                                                    style={{ marginRight: 5 }}
                                                >
                                                    Not shown
                                                </small>
                                                <i
                                                    class="ri-arrow-right-line"
                                                    data-bs-toggle="tooltip"
                                                    title="Estimated position"
                                                ></i>
                                                    <span
                                                        style={{ marginLeft: 5 }}
                                                    >
                                                        #{estimatedPosition?.estimated_position}
                                                    </span>
                                                </div>
                                                // if the bid amount is greater than the current position
                                            ) : (
                                                <div class="d-flex align-items-center">
                                                {
                                                    estimatedPosition?.current_position ?
                                                    // if the current position is defined
                                                    (
                                                        <>
                                                        <small
                                                            style={{ marginRight: 5 }}
                                                        >
                                                            {"#" + estimatedPosition?.current_position}
                                                        </small>
                                                        <i
                                                            class="ri-arrow-right-line"
                                                            data-bs-toggle="tooltip"
                                                            title="Estimated position"
                                                        ></i>
                                                            <span
                                                                style={{ marginLeft: 5 }}
                                                            >
                                                                #{estimatedPosition?.estimated_position}
                                                            </span>
                                                        </>
                                                    )
                                                    // if the current position is not defined
                                                    :
                                                    (
                                                        <>
                                                            <span
                                                                style={{ marginLeft: 5 }}
                                                            >
                                                                #{estimatedPosition?.estimated_position}
                                                            </span>
                                                        </>
                                                    )
                                                }
                                                </div>
                                            )
                                    // if the bid amount is greater than the estimated position
                                    ) : (
                                        "#" + estimatedPosition?.estimated_position
                                    )
                                }
                            </span>
                            </div>
                            <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip placement="bottom">
                                The minimum required bid for this
                                inventory.
                                </Tooltip>
                            }
                            >
                            <div className="auction-info-item">
                                <i className="ri-coins-line"></i>
                                <span>Minimum Bid</span>
                                <span className="number">
                                ${inventoryCampaign?.min_bid}
                                </span>
                            </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip placement="bottom">
                                The current top bid on this inventory.
                                </Tooltip>
                            }
                            >
                            <div className="auction-info-item">
                                <i className="ri-vip-line"></i>
                                <span>Current Top Bid</span>
                                <span className="number">
                                {/* ${
                                    inventoryCampaign?.stat?.top_cpc_bid
                                } */}
                                ${
                                    estimatedPosition?.top_bid != '0' ?
                                    estimatedPosition?.top_bid :
                                    inventoryCampaign?.stat?.top_cpc_bid == '0' ?
                                    inventoryCampaign?.min_bid :
                                    inventoryCampaign?.stat?.top_cpc_bid
                                }
                                </span>
                            </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip placement="bottom">
                                The amount of views this inventory
                                received over the last 30 days.
                                </Tooltip>
                            }
                            >
                            <div className="auction-info-item">
                                <i className="ri-funds-line"></i>
                                <span>30 Day Views</span>
                                <span className="number">
                                {formatThousand(inventoryCampaign?.stat?.total_views)}
                                </span>
                            </div>
                            </OverlayTrigger>
                        </div>
                        </Col>
                        <Col sm="6" className="d-none d-sm-block">
                        <div className="auction-preview">
                            <Row>
                            <Col xs="12">
                                <div id="bid-management">
                                <div className="mb-3">
                                    <span className="section-title">
                                    Auction Preview
                                    </span>
                                    {!inventoryCampaign?.ppc_bid_active && (
                                    <Badge
                                        bg="danger"
                                        className="ms-2 inline-block"
                                    >
                                        PPC Inactive
                                    </Badge>
                                    )}
                                </div>
                                <ol className="vendor-list">
                                    {estimatedPosition?.inventory_campaigns?.map(
                                    (item, index) => {
                                        return (
                                        <li
                                            key={index}
                                            data-position={item.position}
                                            className={
                                            item.id ===
                                                inventoryCampaign?.id
                                                ? "vendor current"
                                                : "vendor"
                                            }
                                        >
                                            <div className="d-sm-flex align-items-center">
                                            <div
                                                className="avatar avatar-xs"
                                                style={{ minWidth: 20 }}
                                            >
                                                {item.id ===
                                                inventoryCampaign?.id ? (
                                                campaign?.logo_file ? (
                                                    <img
                                                    src={
                                                        campaign?.logo_file
                                                    }
                                                    alt="logo"
                                                    className="avatar-img"
                                                    />
                                                ) : (
                                                    placeholderLogoModule(
                                                    publisherIdByAbbr,
                                                    currentSkin,
                                                    item.logo
                                                    )
                                                )
                                                ) : (
                                                <div className="avatar-img" />
                                                )}
                                            </div>
                                            <span className="ms-2 fs-sm">
                                                {/* // TODO: 
                                                    - if placement name is defined in alt ver => show it
                                                    - check why the est list is not showing
                                                */}
                                                {item.id ===
                                                inventoryCampaign?.id ? (
                                                <strong>
                                                    {item.inventory_name}
                                                </strong>
                                                ) : (
                                                <span>
                                                    {item.inventory_name}
                                                </span>
                                                )}
                                                {item.id ===
                                                inventoryCampaign?.id &&
                                                inventoryCampaign.inventory_campaign_status ===
                                                REVIEW_STATUS ? (
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                    <Tooltip placement="bottom">
                                                        We’re currently
                                                        reviewing this
                                                        bid. You will
                                                        feature on this
                                                        inventory if/when
                                                        it is approved.
                                                    </Tooltip>
                                                    }
                                                >
                                                    <span className="badge bg-outline-warning ms-2">
                                                    Under Review
                                                    </span>
                                                </OverlayTrigger>
                                                ) : (
                                                <></>
                                                )}
                                                {item.id ===
                                                inventoryCampaign?.id &&
                                                inventoryCampaign.inventory_campaign_status ===
                                                DRAFT_STATUS ? (
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                    <Tooltip placement="bottom">
                                                        You need to submit
                                                        this bid for
                                                        review to feature
                                                        on this inventory.
                                                    </Tooltip>
                                                    }
                                                >
                                                    <span className="badge bg-outline-secondary ms-2">
                                                    Draft
                                                    </span>
                                                </OverlayTrigger>
                                                ) : (
                                                <></>
                                                )}
                                                {item.id ===
                                                inventoryCampaign?.id &&
                                                inventoryCampaign.inventory_campaign_status ===
                                                LIVE_STATUS ? (
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                    <Tooltip placement="bottom">
                                                        You are currently
                                                        live on this
                                                        inventory. As long
                                                        as your position
                                                        is high enough you
                                                        will be featured.
                                                    </Tooltip>
                                                    }
                                                >
                                                    <span className="badge bg-outline-success ms-2">
                                                    Approved
                                                    </span>
                                                </OverlayTrigger>
                                                ) : (
                                                <></>
                                                )}
                                                {item.id ===
                                                inventoryCampaign?.id &&
                                                inventoryCampaign.inventory_campaign_status ===
                                                REJECTED_STATUS ? (
                                                <span className="badge bg-outline-danger ms-2">
                                                    Rejected
                                                </span>
                                                ) : (
                                                <></>
                                                )}
                                            </span>
                                            </div>
                                        </li>
                                        );
                                    }
                                    )}
                                </ol>
                                </div>
                            </Col>
                            </Row>
                        </div>
                        </Col>
                    </Row>
                    {
                        tooltipContent.length > 0 &&
                            <Row className="mt-2 mb-2">
                                <Col sm="12">
                                    <div class="ppc-danger-system-alert show mb-0">
                                    <div class="items-center w-100 d-flex align-items-center">
                                        <div class="icon" style={{ marginRight: 10 }}>
                                        <i class="ri-information-line"
                                            style={{ fontSize: 20 }}
                                        ></i>
                                        </div>
                                        {tooltipContent}
                                    </div>
                                    </div>
                                </Col>
                            </Row>
                    }
                    <Row className="mt-2">
                        <Col sm="12">
                        <div className="divider mt-2">
                            <span>Landing Page URL</span>
                            {/* <span>Landing Page URL(s)</span> */}
                        </div>
                        </Col>
                        <Col sm="12" className="text-center">
                        <Form className="row align-items-center">
                            <div className="col-12">
                            <div className="input-group">
                                <Form.Control
                                type="url"
                                id="landingPageLink"
                                placeholder={campaign?.ppc_default_url}
                                value={inventoryCampaign?.custom_url}
                                onChange={(e) => onChangeCustomUrl(e)}
                                />
                            </div>
                            </div>
                        </Form>
                        </Col>
                    </Row>
                    </Form>
                </Col>
                <Col sm="12" className="text-center">
                    {inventoryCampaign?.inventory_campaign_status !==
                    DRAFT_STATUS &&
                    inventoryCampaign?.inventory_campaign_status !==
                    REVIEW_STATUS ? (
                    <Button
                        variant={
                            oldInventoryCampaign?.cpc ===
                            inventoryCampaign?.cpc &&
                            oldInventoryCampaign?.custom_url ===
                            inventoryCampaign?.custom_url &&
                            oldInventoryCampaign?.ppc_bid_active ===
                            inventoryCampaign?.ppc_bid_active
                            ? "outline-primary"
                            : "primary"
                        }
                        className="block w-100 mt-3"
                        onClick={handleSave}
                        disabled={
                            (
                                oldInventoryCampaign?.cpc ===
                                inventoryCampaign?.cpc &&
                                oldInventoryCampaign?.custom_url ===
                                inventoryCampaign?.custom_url &&
                                oldInventoryCampaign?.ppc_bid_active ===
                                inventoryCampaign?.ppc_bid_active &&
                                !publisher?.cluster_bidding_mode_enabled
                            )
                        }
                    >
                        {
                            oldInventoryCampaign?.cpc !==
                            inventoryCampaign?.cpc
                            ? inventoryCampaign?.ppc_bid_active
                            ? "Set Bid"
                            : "Save (Bid Inactive)"
                            : "Save"
                        }
                    </Button>
                    ) : (
                    <>
                        {inventoryCampaign?.inventory_campaign_status ===
                        DRAFT_STATUS ? (
                        <>
                            <Row className="g-2">
                                {/* <Col sm="6">
                                    <Button
                                    variant="outline-primary"
                                    className="block w-100 mt-3"
                                    onClick={handleSave}
                                    disabled={
                                        oldInventoryCampaign?.cpc ===
                                        inventoryCampaign?.cpc &&
                                        oldInventoryCampaign?.custom_url ===
                                        inventoryCampaign?.custom_url &&
                                        oldInventoryCampaign?.ppc_bid_active ===
                                        inventoryCampaign?.ppc_bid_active
                                    }
                                    >
                                    Save
                                    </Button>
                                </Col> */}
                                <Col sm="12">
                                    <Button
                                        variant="primary"
                                        className="block w-100 mt-3"
                                        onClick={handleSubmitBid}
                                        disabled={
                                            inventoryCampaign?.ppc_bid_active ===
                                            false
                                        }
                                    >
                                        Submit Bid
                                    </Button>
                                </Col>
                            </Row>
                        </>
                        ) : (
                        <></>
                        )}

                        {inventoryCampaign?.inventory_campaign_status ===
                        REVIEW_STATUS ? (
                        <>
                            <Row className="g-2">
                            <Col sm="6">
                                <Button
                                variant="outline-primary"
                                className="block w-100 mt-3"
                                onClick={handleSave}
                                disabled={
                                    oldInventoryCampaign?.cpc ===
                                    inventoryCampaign?.cpc &&
                                    oldInventoryCampaign?.custom_url ===
                                    inventoryCampaign?.custom_url &&
                                    oldInventoryCampaign?.ppc_bid_active ===
                                    inventoryCampaign?.ppc_bid_active
                                }
                                >
                                Save
                                </Button>
                            </Col>
                            <Col sm="6">
                                <Button
                                variant="danger"
                                className="block w-100 mt-3"
                                onClick={handleWithdrawBid}
                                >
                                Withdraw Bid
                                </Button>
                            </Col>
                            </Row>
                        </>
                        ) : (
                        <></>
                        )}
                    </>
                    )}
                    {!campaign?.ppc_active && (
                    <Alert variant="danger" className="mt-3 mb-0">
                        <strong>Your campaign is inactive</strong> at the
                        moment. This is just a preview of the auction.
                    </Alert>
                    )}
                    {
                    campaign?.ppc_active && campaign?.ppc_paused && (
                        <Alert variant="warning" className="mt-3 mb-0">
                        <strong>Your campaign is paused</strong> at the
                        moment. This is just a preview of the auction.
                        </Alert>
                    )}
                </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
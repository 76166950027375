import { getToken } from "../../helpers/get-token";
import axiosInstance from "../../axios/instance";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";

async function getPeriodInventoryInsightsCampaignsAndPlaceholders(
    currentPeriod,
    previousPeriod,
    status = null,
    search = "",
    currentPage = 1,
    inventoryId,
    sortingParams
) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;

        let url = `${process.env.REACT_APP_HUB_URL}/api/analytics/inventory/insights/period/campaigns-and-placeholders?page=${currentPage}`;

        if (search) {
            url += `&search=${encodeURIComponent(search)}`;
        }

        if (sortingParams?.columnName) {
            url += `&column_name=${sortingParams.columnName}`;
        }
        if (sortingParams?.sortOrder) {
            url += `&column_order=${sortingParams.sortOrder}`;
        }

        const response = await axiosInstance.post(url, {
            current_period: [normalizeToUTC(currentPeriod[0]).getTime(), normalizeToUTC(currentPeriod[1]).getTime()],
            previous_period: [normalizeToUTC(previousPeriod[0]).getTime(), normalizeToUTC(previousPeriod[1]).getTime()],
            inventory_id: inventoryId
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodInventoryInsightsCampaignsAndPlaceholders;

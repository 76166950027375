import mixpanel from 'mixpanel-browser';
import { isCookiesEnabled } from '../contexts/CookiesContext';

const token = process.env.REACT_APP_MIXPANEL_TOKEN;
const persistance = isCookiesEnabled ? 'cookie' : 'localStorage';

mixpanel.init(token, {
    debug: false,
    track_pageview: false,
    persistence: persistance
});

export const mixpanelInstance = mixpanel;

import React from "react";
import RecordEdit from "../../components/RecordEdit";

export default function PublisherInventoryEdit() {
    const urlParams = new URLSearchParams(window.location.search);
    const values = Object.fromEntries(urlParams.entries());

    return (
        <RecordEdit
            values={values}
            title="Edit Publisher Inventory"
            table_name="publisher_inventory"
            breadcrumb="Inventory"
            redirect="/pub/inventory/all"
            item_name="Inventory"
        />
    )
}
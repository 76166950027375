import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import 'bootstrap/dist/css/bootstrap.min.css';
import siteLogo from "../assets/svg/revbox-logo.svg";

class Invoice extends React.Component {
    render() {
        const { invoiceData } = this.props;
        const {
            customerName, customerEmail, customerCompany, address, items, subtotal, vatTotal, total, invoiceNumber, dateOfIssue, dueDate
        } = invoiceData;
        const { bankData } = this.props;
        const {
            bankName, accountName, accountNumber, sortCode, iban, swift
        } = bankData;

        return (
            <Container className="invoice">
                <Row className="logo-section">
                    <Col className="text-left">
                        <object type="image/svg+xml" data={siteLogo} className="w-100 invoice-logo" aria-label="svg image"></object>
                    </Col>
                </Row>

                <Row className="business-info">
                    <Col md={12}>
                        <table className='w-100'>
                            <tbody>
                                <tr>
                                    <td>
                                        <p><strong className='fs-4'>Revbox Ltd</strong></p>
                                        <div className='address'>
                                            <p className='fst-italic'>info@revbox.co</p>
                                            <p>20-22 Wenlock Road</p>
                                            <p>N1 7GU London</p>
                                            <p>United Kingdom</p>
                                        </div>
                                    </td>
                                    <td className="text-end">
                                        <p><strong className='text-uppercase fs-3'>Invoice #: {invoiceNumber}</strong></p>
                                        <p><span className='text-uppercase'>Issue Date:</span> {dateOfIssue}</p>
                                        <p><span className='text-uppercase'>Due Date:</span> {dueDate}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>

                <Row className="customer-details">
                    <Col>
                        <h4 className='mb-3'>Bill To</h4>
                        <p><strong>{customerCompany}</strong></p>
                        <div className='address'>
                            <p>{address}</p>
                            <p>c/o {customerName}</p>
                            <p className='fst-italic'>{customerEmail}</p>
                        </div>
                    </Col>
                </Row>

                <Row className="items-section mb-0">
                    <Col>
                        <h4>Campaign Spend</h4>
                        <div className='item-wrapper'>
                            <Table striped hover className='mb-0'>
                                <thead>
                                    <tr>
                                        <th>Item Name</th>
                                        <th width="100">Price</th>
                                        <th width="100">VAT (20%)</th>
                                        <th width="160" className='text-end'>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, idx) => (
                                        <tr key={idx}>
                                            <td>{item.name}</td>
                                            <td>${item.price.toFixed(2)}</td>
                                            <td>${(item.price * 0.2).toFixed(2)}</td>
                                            <td className='text-end'>${(item.price * 1.2).toFixed(2)}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan="3"><strong>Subtotal</strong></td>
                                        <td className='text-end'>${subtotal.toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3">VAT</td>
                                        <td className='text-end'>${vatTotal.toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3"><strong>Grand Total</strong></td>
                                        <td className='text-end'><strong>${total.toFixed(2)}</strong></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>

                <Row className="payment-details">
                    <Col>
                        <h4>Payment Details</h4>
                        <p>Payment Status: <strong className='text-uppercase'>Unpaid</strong></p>
                        <p>Currency conversion at date of transaction @ USD1.00 = EUR1.05
                        <table className='mb-0'>
                            <tbody>
                                <tr>
                                    <td>USD Tax @ 20%:</td>
                                    <td>USD{vatTotal.toFixed(2)}</td>
                                    <td>|</td>
                                    <td>EUR Tax @ 20%:</td>
                                    <td>EUR{(vatTotal*1.05).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>USD Invoice Total:</td>
                                    <td>USD{total.toFixed(2)}</td>
                                    <td>|</td>
                                    <td>EUR Invoice Total:</td>
                                    <td>EUR{(total*1.05).toFixed(2)}</td>
                                </tr>
                                <tr>
                                </tr>
                                <tr>
                                </tr>
                            </tbody>
                        </table>
                        </p>
                        <p className='dashed-seperator'>Please make payment of USD{total.toFixed(2)} to the following account using the reference below:</p>
                        <p><span className='text-uppercase'>Bank: {bankName}</span>  | <span className='text-uppercase'>Account Name:</span> {accountName} | <span className='text-uppercase'>Account Number:</span> {accountNumber} | <span className='text-uppercase'>Sort Code:</span> {sortCode} | <span className='text-uppercase'>IBAN:</span> {iban} | <span className='text-uppercase'>BIC/SWIFT:</span> {swift} | <span className='text-uppercase'>Reference:</span> {invoiceNumber}</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

class App extends React.Component {
    render() {
        const items = [
            { name: 'PPC Campaign Spend', price: 1350.00 },
            { name: '1x Lead [john@doe.com]', price: 300 },
            { name: '1x Lead [steve@monster.com]', price: 250 },
        ];

        const subtotal = items.reduce((acc, item) => acc + item.price, 0);
        const vatTotal = subtotal * 0.2;
        const total = subtotal + vatTotal;

        const invoiceData = {
            customerName: 'John Doe',
            customerEmail: 'john@doe.com',
            customerCompany: 'Doe Inc.',
            address: '123 Street, City, Country',
            items: items,
            subtotal: subtotal,
            vatTotal: vatTotal,
            total: total,
            invoiceNumber: 'INV12345',
            dateOfIssue: '27th August 2023',
            dueDate: '10th September 2023'
        };

        const bankData = {
            bankName: 'DemoBank Inc.',
            accountName: 'Revbox Ltd.',
            accountNumber: '1234 5678 90',
            sortCode: '12-34-56',
            iban: 'GB12DEMO1234567890',
            swift: 'DEMOGB2L'
        };

        return (
            <div>
                <Invoice ref={(el) => (this.componentRef = el)} invoiceData={invoiceData} bankData={bankData} />
            </div>
        );
    }
}

export default App;

import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function getEmailTemplate(customization, email_template_type) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/emails/get-email-template', {
            customization,
            email_template_type
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getEmailTemplate;

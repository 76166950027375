import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../scss/components/_ag-table.scss";
import { getServerSideDatasource } from "../helpers/get-serverside-datasource";

const heightMarkers = [
    "logo_file"
]

export default function BasicTable({ getData, hiddenColumns, colWidth, rowFields, url, status, setCountByStatus, customColumns, additionalInfo, modalHandleSwitch, skin }) {
    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
    const gridStyle = useMemo(() => ({ minHeight: "200px", width: "100%" }), []);

    const [columnDefs, setColumnDefs] = useState([]);
    const [search, setSearch] = useState('');

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
        };
    }, []);

    const [sortingParams, setSortingParams] = useState({
        columnName: null,
        sortOrder: null
    });

    const heightMarkersIncludes = rowFields.some(field => heightMarkers.includes(field));

    const rowHeight = useMemo(() => heightMarkersIncludes ? 53 : null, [heightMarkersIncludes])

    const [gridApi, setGridApi] = useState(null);

    const onFilterTextBoxChanged = (e) => {
        setSearch(e.target.value);
    };

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        const datasource = getServerSideDatasource(
            getData,
            hiddenColumns,
            colWidth,
            rowFields,
            setColumnDefs,
            status,
            setCountByStatus,
            search,
            customColumns,
            additionalInfo
        );

        params.api.setGridOption("serverSideDatasource", datasource);
    }, [status]);

    const onRowClick = (event) => {
        const id = event.data.id;
        const urlWithId = `${url}?id=${id}`;

        if (event.event.metaKey || event.event.ctrlKey) {
            window.open(urlWithId, "_blank");
        } else {
            window.open(urlWithId, "_self");
        }
    };

    const getContextMenuItems = (params) => {
        const customItems = [
            {
                name: 'Open in New Window',
                action: () => {
                    const id = params.node.data.id;
                    const urlWithId = `${url}?id=${id}`;
                    window.open(urlWithId, "_blank");
                },
            },
        ];

        return customItems;
    };

    const onSortChanged = (params) => {
        setSortingParams({
            columnName: params.columns[0].colId,
            sortOrder: params.columns[0].sort
        });
    };
    

    useEffect(() => {
        if (gridApi) {
            const datasource = getServerSideDatasource(
                getData,
                hiddenColumns,
                colWidth,
                rowFields,
                setColumnDefs,
                status,
                setCountByStatus,
                search,
                customColumns,
                additionalInfo,
                sortingParams
            );

            gridApi.setGridOption("serverSideDatasource", datasource);
        }
    }, [status, gridApi, search, sortingParams]);

    
    return (
        <div style={containerStyle}>
            <div
                style={gridStyle}
                className={skin === "dark" ? "ag-theme-quartz-dark" : "ag-theme-quartz"}
            >
                <div style={{ marginBottom: "10px" }}>
                    <input
                        type="text"
                        className="input-search"
                        placeholder="Search..."
                        onInput={onFilterTextBoxChanged}
                    />
                </div>
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowModelType={"serverSide"}
                    rowHeight={rowHeight}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={false}
                    cacheBlockSize={10}
                    onGridReady={onGridReady}
                    onRowClicked={url ? onRowClick : modalHandleSwitch}
                    domLayout={"autoHeight"}
                    getContextMenuItems={getContextMenuItems}
                    onSortChanged={onSortChanged}
                />
            </div>
        </div>
    );
};

import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Card, Col, Container, Form, Nav, Row } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { CookiesKeyNames, useCookiesContext } from "../../contexts/CookiesContext";

export default function PublisherInventoryCampaign() {

  const selectOptions = [
    { value: '1', label: 'ABC Bank' },
    { value: '2', label: 'Finance Inc.' },
    { value: '3', label: 'Duval Industries' }
  ];
  const { cookiesData } = useCookiesContext();

  const currentSkin = cookiesData[CookiesKeyNames.skinMode] ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app main-docs mt-5 p-3 p-lg-4">
        <Container>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item"><Link href="#">PUB Home</Link></li>
                <li className="breadcrumb-item">Inventory</li>
                <li className="breadcrumb-item">Queue</li>
                <li className="breadcrumb-item active" aria-current="page">1</li>
              </ol>
              <h2 className="main-title mb-0">Form assembly toolkit</h2>
            </div>
          </div>
          <Card className="card-settings mt-4">
            <Card.Body className="p-0">
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="3">
                    <h6>Normal input</h6>
                    <p>Optional/Required</p>
                  </Col>
                  <Col md>
                    <Form.Control type="text" placeholder="Enter Name" />
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="3">
                    <h6>Disabled input</h6>
                    <p>Optional/Required</p>
                  </Col>
                  <Col md>
                  <Form.Control className="mb-2" type="text" placeholder="Disabled input" disabled />
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="3">
                    <h6>Small input</h6>
                    <p>Optional/Required</p>
                  </Col>
                  <Col md="2">
                    <Form.Control type="text" placeholder="#" />
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="12">
                    <h6>Large textarea</h6>
                    <p>Optional/Required</p>
                  </Col>
                  <Col md>
                    <Form.Control as="textarea" rows="10" placeholder="Enter text" />
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2">
                  <Col md="3">
                    <h6>Small textarea</h6>
                    <p>Optional/Required</p>
                  </Col>
                  <Col md>
                    <Form.Control as="textarea" rows="2" placeholder="Enter text" />
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="3">
                    <h6>Select</h6>
                    <p>Optional/Required</p>
                  </Col>
                  <Col md>
                    <Select className="multi-select" options={selectOptions} placeholder="Select items" isSearchable={false} />
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="3">
                    <h6>Select with search</h6>
                    <p>Optional/Required</p>
                  </Col>
                  <Col md>
                    <Select className="multi-select" options={selectOptions} placeholder="Select items" isSearchable={true} />
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="3">
                    <h6>Multi-select with search</h6>
                    <p>Optional/Required</p>
                  </Col>
                  <Col md>
                    <Select className="multi-select" options={selectOptions} placeholder="Select multiple items" isSearchable={true} isMulti />
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="3">
                    <h6>File upload</h6>
                    <p>Optional/Required</p>
                  </Col>
                  <Col md>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Control type="file" />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="3">
                    <h6>Multi-file upload</h6>
                    <p>Optional/Required</p>
                  </Col>
                  <Col md>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                      <Form.Control type="file" multiple />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="3">
                    <h6>Dropdown</h6>
                    <p>Optional/Required</p>
                  </Col>
                  <Col md>
                  <Form.Select aria-label="Default select example">
                    <option>Set Auction Model</option>
                    <option value="1">Live Auction</option>
                    <option value="2">Interval Auction</option>
                  </Form.Select>
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="3">
                    <h6>Check boxes</h6>
                    <p>Optional/Required</p>
                  </Col>
                  <Col md>
                    <Form.Check type="checkbox" label="Unchecked" />
                    <Form.Check type="checkbox" checked label="Checked" />
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md="3">
                    <h6>Radio boxes</h6>
                    <p>Optional/Required</p>
                  </Col>
                  <Col md>
                    <Form.Check type="radio" label="Default radio" />
                    <Form.Check type="radio" checked label="Checked radio" />
                  </Col>
                </Row>
              </div>
              <div className="setting-item">
                <Row className="g-2 align-items-center">
                  <Col md>
                  <Button variant="" className="btn-white">Save Changes</Button>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  );
}
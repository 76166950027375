import axios from "axios";
import { getToken } from "../../helpers/get-token";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";

async function getSpecificInventoryPositions(inventoryId, currentPeriod) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/missed-clicks/inventory-specific-positions/' + inventoryId, {
            current_period: [
                normalizeToUTC(currentPeriod[0]).getTime(),
                normalizeToUTC(currentPeriod[1]).getTime()
            ]
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getSpecificInventoryPositions;

import axios from "axios";
import { getToken } from "../../helpers/get-token";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";

async function getPeriodTopCampaigns(currentPeriod, previousPeriod) {
  try {
    if (
      !currentPeriod ||
      !previousPeriod ||
      currentPeriod.length !== 2 ||
      previousPeriod.length !== 2
    ) {
      throw new Error(
        "Invalid periods provided. Both periods must be arrays of length 2."
      );
    }

    const token = getToken();
    if (!token) {
      throw new Error("Authorization token is missing.");
    }

    axios.defaults.headers.common["authorization"] = token;

    const url =
      process.env.REACT_APP_HUB_URL +
      "/api/analytics/revenue/period/top-campaigns";
    if (!process.env.REACT_APP_HUB_URL) {
      throw new Error(
        "REACT_APP_HUB_URL is not defined in environment variables."
      );
    }

    const response = await axios.post(url, {
      current_period: [
        normalizeToUTC(currentPeriod[0]).getTime(),
        normalizeToUTC(currentPeriod[1]).getTime(),
      ],
      previous_period: [
        normalizeToUTC(previousPeriod[0]).getTime(),
        normalizeToUTC(previousPeriod[1]).getTime(),
      ],
    });

    if (!response || !response.data) {
      throw new Error("Invalid response received from the server.");
    }

    return response.data;
  } catch (error) {
    console.error("getPeriodTopCampaigns Error:", error);
    return null;
  }
}

export default getPeriodTopCampaigns;

import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement
} from "@stripe/react-stripe-js";
import { Button, Card } from "react-bootstrap";
import createBillingMethod from "../services/adv/create_billing_method";
import { toast } from "react-toastify";
import StripeBadgesImage from "../assets/img/stripe-badges.png";

export default function CheckoutForm({
    dpmCheckerLink,
    formValues,
    setIsVatNumberValid,
    checkFormValues,
    customerId,
    isVatNumberValid,
    setEmptyFieldsValidation,
    isEmailValid,
    isContactNameValid,
    clientSecret,
    billingMethod,
    companyName,
    contactName,
    billingAddress,
    vatNumber,
    countries,
    country,
    campaignId,
    budget
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    console.log("Stripe: ", stripe);
    console.log("Payment Element: ", PaymentElement);

    if (!formValues.vatNumber) {
        delete formValues.vatNumber;
        setIsVatNumberValid(true);
    }

    const emptyCheck = checkFormValues(formValues); // returns true if all neccessary fields are filled

    setEmptyFieldsValidation(emptyCheck);

    if (emptyCheck && isVatNumberValid &&
        isEmailValid &&
        isContactNameValid) {
        
        const cardElement = elements.getElement(CardElement);

        const {
            error,
            paymentMethod
        } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        }).then((result) => {
            console.log("Payment Method Result: ", result);
            return result;
        }).catch((error) => {
            console.log("Error: ", error);
            return error;
        });

        console.log("Payment Method: ", paymentMethod);

        createBillingMethod({
            ppc_billing_type: Number(billingMethod),
            invoice_cc_emails: [formValues.email],
            billing_contact_name: contactName,
            billing_company_name: companyName,
            billing_registered_address: billingAddress.line1,
            additional_billing_registered_address: billingAddress.line2,
            billing_registered_address_zip_code: billingAddress.postCode,
            billing_registered_address_city: billingAddress.town,
            billing_registered_address_country_id: countries
                .find((c) => c.code === country.code).id,
            billing_registered_address_state: billingAddress.region,
            billing_vat_number: vatNumber,
            campaign_id: Number(campaignId),
            stripe_customer_id: customerId,
            stripe_payment_method_id: paymentMethod.id,
            last4: paymentMethod.card.last4,
        }).then(async (response) => {
            toast.success("Billing method created successfully.");
            window.location.href = `/adv/campaign/activate?campaignId=${campaignId}&budget=${budget}&contactName=${contactName}`;
            console.log("Billing Method: ", response.data);
        }).catch((error) => {
            toast.error("An error occurred while creating billing method.");
        });
    }
  };

  const paymentElementOptions = {
  }

  return (
    <div className="checkout-form d-flex flex-column">
        <Card className="card-settings mt-3">
            <Card.Header className="d-flex justify-content-between">
                <h5 className="card-title">Card Information</h5>
                <img src={StripeBadgesImage} alt="stripe-payment" style={{width: "120px"}} />
            </Card.Header>
            <Card.Body style={{padding: "20px"}}>
                <CardElement id="payment-element" options={paymentElementOptions} />
                {message && <div id="payment-message">{message}</div>}
            </Card.Body>
        </Card>

        <div className="d-flex justify-content-end mt-3">
            <Button variant="primary" onClick={() => handleSubmit()}>Create Billing Method</Button>
        </div>
    </div>
  );
}
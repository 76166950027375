import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { startOfDay, endOfDay, addDays, subDays } from "date-fns";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import InventoryInsightsRevenue from "./analytics/inventory/insights/InventoryInsightsRevenue";
import InventoryInsightsTotals from "./analytics/inventory/insights/InventoryInsightsTotals";
import InventoryInsightsCampaignsAndPlaceholders from "./analytics/inventory/insights/InventoryInsightsCampaignsAndPlaceholders";
import InventoryInsightsMap from "./analytics/inventory/insights/InventoryInsightsMap";
import { getPublisherInventory } from "../services/pub";
import DatePicker from "./components/DatePicker";
import SpecificInventoryPositionsChart from "./analytics/inventory/insights/SpecificInventoryPositionsChart";
import SpecificInventoryClickDistributionChart from "./analytics/inventory/insights/SpecificInventoryClickDistributionChart";
import { abbreviationsMap } from "../modules/maps";
import { generateCompatibleColors } from "../helpers/generate-compatible-colors";
import { primaryColorModule } from "../modules/primaryColor";
import Cookies from "universal-cookie";
import {
  CookiesKeyNames,
  isCookiesEnabled,
  useCookiesContext,
} from "../contexts/CookiesContext";

const cookies = new Cookies();

const Ranges = [
  {
    label: "today",
    value: [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    label: "yesterday",
    value: [
      startOfDay(addDays(new Date(), -1)),
      endOfDay(addDays(new Date(), -1)),
    ],
  },
  {
    label: "Last 30 Days",
    value: [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())],
  },
];

function getPublisherId() {
  const searchParams = new URLSearchParams(window.location.search);
  const urlDomain = window.location.hostname;
  const pubParam = searchParams.get("pub");
  const publisherIdFromCookies = isCookiesEnabled
    ? cookies.get(CookiesKeyNames.publisherId)
    : localStorage.getItem(CookiesKeyNames.publisherId);

  if (pubParam) {
    if (abbreviationsMap[pubParam]) {
      return abbreviationsMap[pubParam];
    } else {
      return pubParam;
    }
  }

  if (urlDomain === "ppc.hoteltechreport.com") {
    return 67;
  }

  if (publisherIdFromCookies) {
    return Number(publisherIdFromCookies);
  }

  return 1;
}

export default function PublisherInventoryInsights() {
  // get 30 days back for begin date
  const [currentPeriod, setCurrentPeriod] = useState([null, null]);
  // get 60 days back for begin date
  const [previousPeriod, setPreviousPeriod] = useState([null, null]);

  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const { cookiesData, updateCookiesProperty } = useCookiesContext();

  const publisher_id = getPublisherId();

  const loadDateRange = () => {
    const storedCurrentPeriod = isCookiesEnabled
      ? cookiesData[CookiesKeyNames.currentPeriod]
      : JSON.parse(cookiesData[CookiesKeyNames.currentPeriod]);
    const storedPreviousPeriod = isCookiesEnabled
      ? cookiesData[CookiesKeyNames.previousPeriod]
      : JSON.parse(cookiesData[CookiesKeyNames.previousPeriod]);

    if (storedCurrentPeriod && storedPreviousPeriod) {
      return {
        currentPeriod: storedCurrentPeriod.map((date) => new Date(date)),
        previousPeriod: storedPreviousPeriod.map((date) => new Date(date)),
      };
    }

    const defaultCurrentPeriod = [subDays(new Date(), 30), new Date()];
    const defaultPreviousPeriod = [
      subDays(new Date(), 60),
      subDays(new Date(), 30),
    ];

    return {
      currentPeriod: defaultCurrentPeriod,
      previousPeriod: defaultPreviousPeriod,
    };
  };

  useEffect(() => {
    const { currentPeriod, previousPeriod } = loadDateRange();
    setCurrentPeriod(currentPeriod);
    setPreviousPeriod(previousPeriod);
  }, []);

  useEffect(() => {
    if (
      currentPeriod &&
      currentPeriod.length === 2 &&
      currentPeriod[0] &&
      currentPeriod[1]
    ) {
      updateCookiesProperty(CookiesKeyNames.currentPeriod, currentPeriod);
      updateCookiesProperty(CookiesKeyNames.previousPeriod, previousPeriod);
    }
  }, [currentPeriod, previousPeriod]);

  const [response, setResponse] = useState();
  const [editLink, setEditLink] = useState();
  const inventoryId = new URLSearchParams(window.location.search).get("id");

  useEffect(() => {
    if (!inventoryId) {
      return;
    }

    getPublisherInventory(inventoryId).then((res) => {
      if (!res?.data) {
        return;
      }

      const columns = Object.keys(res.data);
      const values = columns.map(column => {
        return {
          value: res.data[column],
          column: column
        }
      });

      const newEditLink = '/pub/inventory/edit?' + values.map(value => `${value.column}=${value.value}`).join('&');
      setEditLink(newEditLink);
      setResponse(res);
    });
  }, [inventoryId]);

  const formatDate = (date) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Intl.DateTimeFormat("en-UK", options).format(date);
  };

  const handleOpenDateRangePicker = () => {
    setIsDateRangePickerOpen(true);
  };

  const handleCloseDateRangePicker = () => {
    setIsDateRangePickerOpen(false);
  };

  const handleDateRange = (e) => {
    const curBegin = new Date(e[0]);
    const curEnd = new Date(e[1]);

    curBegin.setHours(0, 0, 0, 0);
    curEnd.setHours(23, 59, 59, 999);

    const diff = curEnd.getTime() - curBegin.getTime() + 1;

    const prevBegin = new Date(curBegin.getTime() - diff);
    const prevEnd = new Date(curEnd.getTime() - diff);

    setPreviousPeriod([prevBegin, prevEnd]);
    setCurrentPeriod([curBegin, curEnd]);
  };

  const chartOption = {
    indexAxis: "y",
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        grid: {
          borderColor: "#000",
          color: "#f3f5f9",
        },
        ticks: {
          color: "#212830",
          font: {
            size: 10,
            weight: "500",
          },
        },
      },
      y: {
        grid: {
          borderWidth: 0,
          color: "#f3f5f9",
        },
        ticks: {
          color: "#212830",
          font: {
            size: 12,
          },
        },
      },
    },
  };

  const currentSkin = cookiesData[CookiesKeyNames.skinMode] ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  if (skin === "dark") {
    chartOption.scales["x"].grid.color = "#222b41";
    chartOption.scales["x"].ticks.color = "rgba(255,255,255,.65)";
    chartOption.scales["x"].grid.borderColor = "#222b41";
    chartOption.scales["y"].grid.color = "#222b41";
    chartOption.scales["y"].ticks.color = "rgba(255,255,255,.65)";
  } else {
    chartOption.scales["x"].grid.color = "#edeff6";
    chartOption.scales["x"].ticks.color = "#42484e";
    chartOption.scales["x"].grid.borderColor = "#edeff6";
    chartOption.scales["y"].grid.color = "#edeff6";
    chartOption.scales["y"].ticks.color = "#42484e";
  }

  const colors = generateCompatibleColors(primaryColorModule(publisher_id), 10);

  console.log("inventoryId", inventoryId);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">PUB Home</Link>
              </li>
              <li className="breadcrumb-item">Dashboard</li>
              <li className="breadcrumb-item">Inventory</li>
              <li className="breadcrumb-item active" aria-current="page">
                ID {inventoryId}
              </li>
            </ol>
            <h2 className="main-title mb-0">{response?.data?.name}</h2>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Link to={editLink}>
              <Button
                variant=""
                className="d-flex align-items-center gap-2 btn btn-outline-primary"
              >
                Edit Inventory
              </Button>
            </Link>
            <Button
              variant="btn-outline-primary"
              className={`d-flex align-items-center gap-2 btn btn-outline-primary`}
              onClick={handleOpenDateRangePicker}
            >
              <span className="fw-semibold">Date Range</span>
              <i className="ri-arrow-right-s-line"></i>
              {currentPeriod && currentPeriod.length === 2
                ? ` ${formatDate(currentPeriod[0])} - ${formatDate(
                    currentPeriod[1]
                  )}`
                : ""}
            </Button>
            {isDateRangePickerOpen && (
              <DatePicker
                isDateRangePickerOpen={isDateRangePickerOpen}
                setIsDateRangePickerOpen={setIsDateRangePickerOpen}
                ranges={Ranges}
                onChange={handleDateRange}
                currentPeriod={currentPeriod}
              />
            )}
          </div>
        </div>

        {inventoryId && currentPeriod && (
          <Row className="g-3 justify-content-center">
            <InventoryInsightsRevenue
              currentPeriod={currentPeriod}
              previousPeriod={previousPeriod}
              inventoryId={inventoryId}
            />
            <InventoryInsightsTotals
              currentPeriod={currentPeriod}
              previousPeriod={previousPeriod}
              inventoryId={inventoryId}
            />
            <InventoryInsightsCampaignsAndPlaceholders
              currentPeriod={currentPeriod}
              previousPeriod={previousPeriod}
              inventoryId={inventoryId}
              skin={skin}
            />
            <Row className="g-3 p-0" style={{ marginTop: "5px" }}>
              <SpecificInventoryPositionsChart
                currentPeriod={currentPeriod}
                inventoryId={inventoryId}
                colors={colors}
              />
              <SpecificInventoryClickDistributionChart
                currentPeriod={currentPeriod}
                inventoryId={inventoryId}
                colors={colors}
              />
            </Row>
            <InventoryInsightsMap
              currentPeriod={currentPeriod}
              previousPeriod={previousPeriod}
              inventoryId={inventoryId}
            />
          </Row>
        )}

        <Footer />
      </div>
    </React.Fragment>
  );
}

import axios from "axios";

async function saveNewPassword(token, recovery_token, password) {
    try {
        const incodedPasswordForUrl = encodeURIComponent(password);
        const response = await axios.get(process.env.REACT_APP_HUB_URL + `/api/users/save-new-password?recovery_token=${recovery_token}&password=${incodedPasswordForUrl}&token=${token}`);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default saveNewPassword;

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { mixpanelInstance } from "./instance";
import { getMe } from "../services/micro";

export const TrackUserActions = () => {
    const location = useLocation();
    const [user, setUser] = useState(null);

    useEffect(() => {
      async function fetchUser() {
          try {
            if (!location.pathname.includes('settings/notifications')) {
              const userData = await getMe();
              if (userData) {
                setUser(userData.data);
              }
            }
          } catch (error) {
              console.error('Error fetching user:', error);
          }
      }

      fetchUser();      
    }, []);

    useEffect(() => {
      if (!location.pathname.includes('settings/notifications')) {
        const isInAdvertiserMode = location.pathname.includes('adv');
    
        if (isInAdvertiserMode) {
          // Track user actions for advertiser mode
          mixpanelInstance.track('Page Visit', { page: location.pathname, user });
        }
      }
    }, [location]);
  
    return null; // Since this component is only for tracking, return null
};

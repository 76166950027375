import React from "react";
import RecordEdit from "../../components/RecordEdit";

export default function PublisherCategoryEdit() {
    const urlParams = new URLSearchParams(window.location.search);
    const values = Object.fromEntries(urlParams.entries());

    return (
        <RecordEdit
            values={values}
            title="Edit Publisher Category"
            table_name="publisher_categories"
            breadcrumb="Category"
            redirect="/pub/categories"
            item_name="Category"
        />
    )
}
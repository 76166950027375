export function StatusRenderer({ value }) {
  if (!value) {
    return null;
  }
  
  const statusMap = {
    DRAFT: "badge bg-secondary",
    REVIEW: "badge bg-warning",
    REJECTED: "badge bg-danger",
    LIVE: "badge bg-success",
    ACTIVE: "badge bg-success",
    PAUSED: "badge bg-warning",
    INACTIVE: "badge bg-danger",
  };

  const status = value.toUpperCase();
  const className = statusMap[status];

  return className ? <span className={className}>{status.charAt(0) + status.slice(1).toLowerCase()}</span> : null;
}

import { toast } from 'react-toastify';

export const validateImageDimensions = async (files, currentDimensions) => {
    const validFiles = [];
    const invalidFiles = [];

    const imagePromises = files.map((file) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                if (img.width >= currentDimensions.width && img.height >= currentDimensions.height) {
                    validFiles.push(file);
                } else {
                    invalidFiles.push(file);
                    toast.error(`Image ${file.name} does not meet the required dimensions of at least ${currentDimensions.width}x${currentDimensions.height}px.`);
                }
                resolve();
            };

            img.onerror = () => {
                invalidFiles.push(file);
                toast.error(`Could not load image ${file.name}.`);
                resolve();
            };
        });
    });

    await Promise.all(imagePromises);
    return { validFiles, invalidFiles };
};

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../scss/components/_ag-table.scss";
import { getServerSideDatasourceInvStats } from "../helpers/get-serverside-datasource-inv-stats";

export default function CampaignInventoryStatsTable({
    getData,
    hiddenColumns,
    colWidth,
    skin,
    currentPeriod,
    previousPeriod,
    campaignId,
    token,
    view
}) {
    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
    const gridStyle = useMemo(() => ({ minHeight: "200px", width: "100%" }), []);

    const [columnDefs, setColumnDefs] = useState([]);
    const [search, setSearch] = useState('');

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
        };
    }, []);

    const [sortingParams, setSortingParams] = useState({
        columnName: null,
        sortOrder: null
    });

    const [gridApi, setGridApi] = useState(null);

    const onFilterTextBoxChanged = (e) => {
        setSearch(e.target.value);
    };

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        const datasource = getServerSideDatasourceInvStats(
            getData,
            hiddenColumns,
            colWidth,
            setColumnDefs,
            search,
            currentPeriod,
            previousPeriod,
            campaignId,
            token,
            view
        );

        params.api.setGridOption("serverSideDatasource", datasource);
    }, []);

    const onSortChanged = useCallback((params) => {
        setSortingParams({
            columnName: params.columns[0].colId,
            sortOrder: params.columns[0].sort
        });
    }, []);

    useEffect(() => {
        if (gridApi) {
            const datasource = getServerSideDatasourceInvStats(
                getData,
                hiddenColumns,
                colWidth,
                setColumnDefs,
                search,
                currentPeriod,
                previousPeriod,
                campaignId,
                token,
                view
            );

            gridApi.setGridOption("serverSideDatasource", datasource);
        }
    }, [gridApi, search, currentPeriod, previousPeriod]);


    return (
        <div style={containerStyle}>
            <div
                style={gridStyle}
                className={skin === "dark" ? "ag-theme-quartz-dark" : "ag-theme-quartz"}
            >
                <div style={{ marginBottom: "10px" }}>
                    <input
                        type="text"
                        className="input-search"
                        placeholder="Search..."
                        onInput={onFilterTextBoxChanged}
                    />
                </div>
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowModelType={"serverSide"}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={false}
                    cacheBlockSize={10}
                    onGridReady={onGridReady}
                    domLayout={"autoHeight"}
                    onSortChanged={onSortChanged}
                />
            </div>
        </div>
    );
};

import axios from 'axios';
import { logIssue } from '../mixpanel/logIssue';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000, // Timeout after 10 seconds
  headers: {
    'Content-Type': 'application/json',
  }
});


axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const responseError = error.response.data

        logIssue(responseError.message, {
            error: responseError.error,
            inputParams: responseError.input_parameters,
            code: responseError.code,
            type: responseError.type,
        });
        // Any status codes that fall outside the range of 2xx cause this function to trigger
        return Promise.reject(error);
    }
);

export default axiosInstance;
import React from "react";
import { Card, Row, Col, OverlayTrigger, Tooltip, Button } from "react-bootstrap";

export default function DefaultAuctionCampaignStatusCard(props) {
    const { campaign, campaignCategories, publisherIdByAbbr, currentSkin } = props;


    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                campaign.ppc_active || campaign.ppc_paused ? (
                <Tooltip placement="bottom">
                    Please <strong>contact us</strong> to alter or cancel this
                    campaign.
                </Tooltip>
                ) : (
                <></>
                )
            }
            >
            <Card className="card-one">
                <Card.Body>
                    <Row>
                        <Col xs="12">
                            {campaign.ppc_active && !campaign.ppc_paused && (
                                <h3 className="card-value fw-bold text-success mb-0">
                                ACTIVE
                                </h3>
                            )}
                            {!campaign.ppc_active && !campaign.ppc_paused && (
                                <h3 className="card-value fw-bold text-danger mb-0">
                                INACTIVE
                                </h3>
                            )}
                            {campaign.ppc_paused && (
                                <h3 className="card-value fw-bold text-warning mb-0">
                                PAUSED
                                </h3>
                            )}
                            <label className="card-title fw-medium text-dark mb-1">
                                Campaign Status
                            </label>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer style={{ height: '60px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Row>
                        <Col xs="12">
                            <span className="d-block fs-11 text-secondary lh-4">
                                Categories:{" "}
                                {campaignCategories?.length > 2
                                ? campaignCategories?.slice(0, 2).join(", ") +
                                    ", " +
                                    String(campaignCategories?.length - 2) +
                                    " more"
                                : campaignCategories?.join(", ")}
                            </span>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </OverlayTrigger>
    );
}
import React from "react";
import { Card, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { formatThousand } from "../../../helpers/thousand-number-format";

export default function DefaultAuctionCreditBalanceCard(props) {
  const { campaign } = props;

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        campaign.ppc_active || campaign.ppc_paused ? (
          <Tooltip placement="bottom">Credit balance for your campaign</Tooltip>
        ) : (
          <></>
        )
      }
    >
      <Card className="card-one">
        <Card.Body>
          <Row>
            <Col xs="12">
              <h3 className="card-value mb-0 flex justify-content-between">
                <b>
                  $
                  {formatThousand(
                    Number(
                      campaign.ppc_paid_credits + campaign.ppc_free_credits
                    ).toFixed(2)
                  )}
                </b>
              </h3>
              <label className="card-title fw-medium text-dark mb-1">
                Credit Balance
              </label>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer
          style={{
            height: "60px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Row>
            <Col xs="12">
              <span className="d-block fs-11 text-secondary lh-4">
                Auto top-up below: $150
              </span>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </OverlayTrigger>
  );
}

import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function editInventory(id, values) {
  try {
    const token = getToken();

    const response = await axiosInstance.put(`/api/publisher-inventory/${id}`, values, {
      headers: {
        'Authorization': token
      }
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error; // Re-throw the error for further handling if needed
  }
}

export default editInventory;

import React, { useState, useEffect } from "react";
import { Card, Col, Nav, Row, Table, Placeholder } from "react-bootstrap";
import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";

import { formatThousand } from "../../../helpers/thousand-number-format";
import "../../../scss/_variables.scss";
import getTotalsForMapCluster from "../../../services/micro/get_totals_for_map_cluster";

export default function GeographicDistribution({ inventoryId, campaignId }) {
  const [response, setResponse] = useState();
  const [reset, setReset] = useState(0);
  const mapStartColor = getComputedStyle(document.documentElement).getPropertyValue('--map-start');
  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary');

  useEffect(() => {
    getTotalsForMapCluster(inventoryId, campaignId).then((response) => {    
      if (response) {
        setResponse({
          bg_colors: {},
          click_data: response.click_data,
          country_data: response.country_data,
          impressions_data: response.impressions_data,
        }); 
      }
    });
  }, [inventoryId, campaignId]);

  const onMapReset = () => {
    setReset(reset + 1);
  };

  if (response) {
    return (
      <React.Fragment key={reset}>
        <Col md="12">
          <Card className="card-one">
            <Card.Header>
              <Card.Title as="h6">Geographic Distribution</Card.Title>
              <Nav className="nav-icon nav-icon-sm ms-auto">
                <Nav.Link href="" onClick={onMapReset}>
                  <i className="ri-refresh-line"></i>
                </Nav.Link>
              </Nav>
            </Card.Header>
            <Card.Body>
              <Row className="align-items-center g-3">
                <div
                  className="d-flex flex-column justify-content-center"
                >
                  <Table className="table-one mb-4">
                    <thead>
                      <tr>
                        <th className="wd-40 pt-0">Top Countries</th>
                        <th className="wd-60 pt-0">Impressions</th>
                        <th className="wd-60 pt-0">Clicks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {response.country_data.slice(0, 4).map((item, index) => (
                        <tr key={index}>
                          <td>
                            <span className="fw-medium">{item.country}</span>
                          </td>
                          <td>{formatThousand(item.impressions)}</td>
                          <td>{formatThousand(item.clicks)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <Row md="8" className="mt-5 mt-md-0 h-100">
                  <div style={{ height: "300px" }}>
                    <VectorMap
                      map={worldMill}
                      backgroundColor="transparent"
                      regionStyle={{
                        initial: {
                          fill: "#e4e4e4",
                          "fill-opacity": 0.9,
                          stroke: "none",
                          "stroke-width": 0,
                          "stroke-opacity": 0,
                        },
                      }}
                      series={{
                        regions: [
                          {
                            values: response.click_data,
                            scale: [mapStartColor, primaryColor],
                            attribute: "fill",
                          },
                        ],
                      }}
                      onRegionTipShow={(e, el, code) => {
                        el.html(
                          el.html() +
                          " - Clicks: " +
                          (formatThousand(response.click_data[code]) || 0) +
                          " | Views: " +
                          (formatThousand(response.impressions_data[code]) || 0)
                        );
                      }}
                    />
                  </div>
                </Row>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Placeholder as={Card.Title} animation="glow" style={{ color: "var(--bs-body-color)" }}>
          <Placeholder style={{ height: 400, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  }
}
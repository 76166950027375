import axios from "axios";
import { getToken } from "../../helpers/get-token";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";

async function getPeriodRevenueTotals(currentPeriod, previousPeriod) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/analytics/revenue/period/totals', {
            current_period: [normalizeToUTC(currentPeriod[0]).getTime(), normalizeToUTC(currentPeriod[1]).getTime()],
            previous_period: [normalizeToUTC(previousPeriod[0]).getTime(), normalizeToUTC(previousPeriod[1]).getTime()]
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodRevenueTotals;

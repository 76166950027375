import React, { useEffect, useState } from "react";
import { getPeriodInventoryInsightsTotals } from "../../../../services/micro";
import { Card, Placeholder, Col, Row } from "react-bootstrap";

export default function InventoryInsightsTotals(props) {
  const { currentPeriod, previousPeriod, inventoryId } = props;
  const [response, setResponse] = useState([]);

  useEffect(() => {
    if (currentPeriod && previousPeriod) {
      if (
        currentPeriod[0] &&
        currentPeriod[1] &&
        previousPeriod[0] &&
        previousPeriod[1]
      ) {
        getPeriodInventoryInsightsTotals(
          currentPeriod,
          previousPeriod,
          inventoryId
        ).then((response) => {
          setResponse(response);
        });
      }
    }
  }, [props]);

  if (response && response.length) {
    return (
      <React.Fragment>
        <Col md="6" xl="6">
          <Row className="g-3">
            {response.map((card, index) => (
              <div className="col-xl-6 col-lg-6 col-md-12" key={index}>
                <Card className="card-one" key={index}>
                  <Card.Body>
                    <h2 className="card-value mb-1">{card.value}</h2>
                    <Card.Title
                      as="label"
                      className="d-block mb-1 fw-medium text-dark"
                    >
                      {card.label}
                    </Card.Title>
                    <small className="subtext">
                      <span
                        className={
                          "d-inline-flex text-" +
                          (card.status === "up" ? "success" : "danger")
                        }
                      >
                        {card.change}{" "}
                        <i
                          className={
                            "ri-arrow-" +
                            (card.status === "up" ? "up" : "down") +
                            "-line"
                          }
                        ></i>
                      </span>{" "}
                      than previous period
                    </small>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Row>
        </Col>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Col md="6" xl="6" style={{ color: "var(--bs-body-color)" }}>
          <Row className="g-3 mb-3">
            <Col className="g-3">
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder style={{ height: 115, width: "100%" }} />
              </Placeholder>
            </Col>
            <Col className="g-3">
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder style={{ height: 115, width: "100%" }} />
              </Placeholder>
            </Col>
          </Row>
          <Row className="g-3 mb-3">
            <Col className="g-3">
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder style={{ height: 115, width: "100%" }} />
              </Placeholder>
            </Col>
            <Col className="g-3">
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder style={{ height: 115, width: "100%" }} />
              </Placeholder>
            </Col>
          </Row>
          <Row className="g-3">
            <Col className="g-3">
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder style={{ height: 115, width: "100%" }} />
              </Placeholder>
            </Col>
            <Col className="g-3">
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder style={{ height: 115, width: "100%" }} />
              </Placeholder>
            </Col>
          </Row>
        </Col>
      </React.Fragment>
    );
  }
}

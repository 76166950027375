export default function HTRBlankPage (){
    return (
        <div className="iframe-popup-container">
            <div style={{
                paddingTop: "20vh",  
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                }}
                >
                <img src="/images/htr-icon.svg" alt="something went wrong" style={{width: "100px", marginBottom: "15px"}}/>
                <h2 style={{marginBottom: "15px"}}><b>Uh oh, something went wrong...</b></h2>
                <p style={{textAlign: "center"}}>It looks like PPC isn't loading for you. Please reach out to our support team
                    <br/> via the live chat to let us know about the issue you are experiencing
                </p>
            </div>
        </div>
    );
}
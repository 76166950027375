import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function changePassword(user_id) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        let response
        if (user_id) {
            response = await axios.put(process.env.REACT_APP_HUB_URL + '/api/users/change-password?user_id=' + user_id)
        } else {
            response = await axios.put(process.env.REACT_APP_HUB_URL + '/api/users/change-password')
        }
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default changePassword;

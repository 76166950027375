import Cookies from "universal-cookie";
import { CookiesKeyNames, isCookiesEnabled } from "../contexts/CookiesContext";
import { abbreviationsMap } from "../modules/maps";

export function GetPubAbbr() {
    const urlParams = new URLSearchParams(window.location.search);
    const pubParam = urlParams.get('pub');
    const cookies = new Cookies();
    const publisher_id = isCookiesEnabled ? cookies.get(CookiesKeyNames.publisherId) : localStorage.getItem(CookiesKeyNames.publisherId);

    if (
        pubParam !== null &&
        pubParam !== undefined &&
        pubParam !== '' &&
        pubParam !== 'undefined' &&
        pubParam !== 'null'
    ) {
        return abbreviationsMap[pubParam];
    }

    return publisher_id;
}
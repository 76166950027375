import React, { useEffect, useState } from "react";
import { getPeriodCampaignTotals } from "../../../services/micro";
import { Card, Placeholder, Col, Row } from "react-bootstrap";

export default function AdvertiserTotals(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const { currentPeriod, previousPeriod, campaignId } = props;
  const [response, setResponse] = useState([]);

  useEffect(() => {
    if (currentPeriod && previousPeriod) {
      getPeriodCampaignTotals(currentPeriod, previousPeriod, campaignId, token).then(
        (response) => {
          setResponse(response);
        }
      );
    }
  }, [props]);

  // todo: credits spend for htr instead of balance (BE work)
  if (response) {
    return (
      <React.Fragment>
        {response.map((card, index) => (
          <Col md="6" xl="4" key={index}>
            <Card className="card-one">
              <Card.Body>
                <Row>
                  <Col xs="12">
                    <h3 className={`${card.class} fw-bold card-value mb-1`}>
                      {card.value}
                    </h3>
                    <label className="card-title fw-medium text-dark mb-1">
                      {card.label}
                    </label>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer style={{ height: '60px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <span className="d-block text-muted fs-11 ff-secondary lh-4">
                  {card.description}
                </span>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment style={{ color: "var(--bs-body-color)" }}>
        <Placeholder as={Card.Title} animation="glow">
          <Placeholder style={{ height: 200, width: "100%" }} />
        </Placeholder>
        <Placeholder as={Card.Title} animation="glow">
          <Placeholder style={{ height: 200, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  }
}

import axios from "axios";
import { getToken } from "../../helpers/get-token";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";

async function getPeriodBrandingPPCAuctions({ search = '', page = 1 } = {}, campaignId) {
    try {
        const currentPeriod = [
            new Date(new Date().setDate(new Date().getDate() - 32)),
            new Date()
        ];
        
        const previousPeriod = [
            new Date(new Date().setDate(new Date().getDate() - 62)),
            new Date(new Date().setDate(new Date().getDate() - 32))
        ];

        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/analytics/campaign/period/branding-ppc-auctions?page=' + page + '&search=' + search, {
            current_period: [normalizeToUTC(currentPeriod[0]).getTime(), normalizeToUTC(currentPeriod[1]).getTime()],
            previous_period: [normalizeToUTC(previousPeriod[0]).getTime(), normalizeToUTC(previousPeriod[1]).getTime()],
            campaign_id: Number(campaignId)
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodBrandingPPCAuctions;

import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function editPublisher(id, data) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.put(process.env.REACT_APP_API_URL + '/api/publishers/' + id, data)
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default editPublisher;

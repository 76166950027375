import darkLoader from '../../../assets/gifs/netnetiX_black.gif';
import lightLoader from '../../../assets/gifs/netnetiX_white.gif';

export default function loader() {
    return( 
        <div style={{
            margin: 0,
            position: "absolute",
            top: "35%",
            left: "40%"
            }}>
                <img className="logoGif loaderDark" src={lightLoader} alt="loading" style={{maxWidth: "300px"}}/>
                <img className="logoGif loaderLight" src={darkLoader} alt="loading" style={{maxWidth: "300px"}}/>
        </div>
        )
}
import React from "react";
import RecordCreate from "../../components/RecordCreate";

export default function PublisherUserCreate() {
    return (
        <RecordCreate
            title="Create Customer User"
            table_name="users"
            breadcrumb="User"
            redirect="/pub/customers/users"
        />
    )
}
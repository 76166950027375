import React from "react";
import RecordCreate from "../../components/RecordCreate";

export default function BillingMethodCreate() {
    return (
        <RecordCreate
            title="Create Billing Method"
            table_name="billing_methods"
            breadcrumb="Billing Methods"
            redirect="/pub/finance/billing"
            item_name="Billing Method"
        />
    )
}
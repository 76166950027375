import { getToken } from "../../helpers/get-token";
import { startOfDayUTC } from "../../helpers/start-of-day-utc";
import { endOfDayUTC } from "../../helpers/end-of-day-utc";
import axiosInstance from "../../axios/instance";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";

async function getPeriodInventoryPerformance(
    currentPeriod,
    previousPeriod,
    status = null,
    search = "",
    page = 1
) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;

        let url = `${process.env.REACT_APP_HUB_URL}/api/analytics/inventory/period/performance?page=${page}`;

        if (search.length) {
            url += `&search=${encodeURIComponent(search)}`;
        }

        const response = await axiosInstance.post(url, {
            current_period: [
                startOfDayUTC(normalizeToUTC(currentPeriod[0])),
                endOfDayUTC(normalizeToUTC(currentPeriod[1]))
            ],
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodInventoryPerformance;

import { getCampaign, getCustomer } from "../services/adv";
import { getTransaction, getBillingMethod } from "../services/finance";
import { getInventoryCampaign, getUser } from "../services/micro";
import { getCategory, getWebsite, getPublisherInventory } from "../services/pub";

async function getRecord(id, table_name) {
    try {
        if (!table_name) {
            throw new Error("Table name is required");
        }
        
        let response;

        if (table_name === 'publisher_categories') {
            response = await getCategory(id);
        }   
        
        if (table_name === 'publisher_websites') {
            response = await getWebsite(id);
        }

        if (table_name === 'publisher_inventory') {
            response = await getPublisherInventory(id);
        }

        if (table_name === 'clients_accounts') {
            response = await getCustomer(id);
        }

        if (table_name === 'client_campaigns') {
            response = await getCampaign(id);
        }

        if (table_name === 'users') {
            response = await getUser(id);
        }

        if (table_name === 'publisher_inventory') {
            response = await getPublisherInventory(id);
        }

        if (table_name === 'inventory_campaigns') {
            response = await getInventoryCampaign(id);
        }

        if (table_name === 'transactions') {
            response = await getTransaction(id);
        }

        if (table_name === 'billing_methods') {
            response = await getBillingMethod(id);
        }

        return response;
    } catch (error) {
        console.log(error);
    }
}

export default getRecord;

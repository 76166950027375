import React from "react";
import {
  Button,
  Col,
  Offcanvas,
  Form,
  Row
} from "react-bootstrap";

import getShortMonthName from "../../../helpers/get-short-month-name";

export default function CampaignBudget({ showBottom, handleCloseBottom, campaign, publisher, days_diff, onChangeMonthlyBudget, onSaveBudget }) {

    return (
        <Offcanvas
            show={showBottom}
            onHide={handleCloseBottom}
            placement="bottom"
            className="card-one"
            style={{ minHeight: "200px" }}
        >
            <Offcanvas.Header closeButton style={{ marginRight: 20 }}>
                <Col xl="12" className="m-auto">
                <Row
                    className="justify-content-center"
                    style={{ width: "100%", marginLeft: 20 }}
                >
                    <Offcanvas.Title style={{ width: "max-content" }}>
                    {campaign.name} - Monthly Budget
                    </Offcanvas.Title>
                </Row>
                </Col>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Row className="mb-3">
                <Col xl="12">
                    <Form>
                    <Col sm="12">
                        <Row className="justify-content-center align-items-center">
                        <Form.Label
                            htmlFor="blockCpc"
                            className="mb-0"
                            style={{
                            width: "max-content",
                            }}
                        >
                            $
                        </Form.Label>
                        <Form.Control
                            type="number"
                            id="blockBudget"
                            defaultValue={
                            Number(campaign?.ppc_monthly_budget?.replace(',', '')) || publisher?.default_budget
                            }
                            style={{ width: "200px" }}
                            onChange={(e) => onChangeMonthlyBudget(e)}
                        />
                        <Button
                            variant="primary"
                            style={{
                            width: "max-content",
                            marginLeft: "10px",
                            }}
                            onClick={() => onSaveBudget()}
                        >
                            Save Budget
                        </Button>
                        </Row>
                    </Col>
                    <Col sm="12">
                        <Row className="justify-content-end">
                        <Col xl="12">
                            <span className="d-block text-muted fs-11 ff-secondary lh-4 mt-4 text-center">
                            Your spend this month:{" "}
                            <b>${campaign.ppc_monthly_spend}</b>
                            </span>
                        </Col>
                        <span className="d-block text-muted fs-11 ff-secondary lh-4 text-center">
                            Your next billing day:{" "}
                            <b>
                            {campaign.ppc_invoice_day}.{" "}
                            {getShortMonthName(new Date())}
                            </b>{" "}
                            (in {days_diff} {days_diff == 1 ? "day" : "days"})
                        </span>
                        </Row>
                    </Col>
                    </Form>
                </Col>
                </Row>
            </Offcanvas.Body>
        </Offcanvas>
    );
}
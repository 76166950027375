import React from "react";
import RecordEdit from "../../components/RecordEdit";

export default function BillingMethodEdit() {
    const urlParams = new URLSearchParams(window.location.search);
    const values = Object.fromEntries(urlParams.entries());
    
    return (
        <RecordEdit
            values={values}
            title="Edit Billing Method"
            table_name="billing_methods"
            breadcrumb="Billing Methods"
            redirect="/pub/finance/billing"
            item_name="Billing Method"
        />
    )
}
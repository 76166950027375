import React from "react";
import RecordCreate from "../../components/RecordCreate";

export default function PublisherClientAccountCreate() {
    return (
        <RecordCreate
            title="Create Customer Account"
            table_name="clients_accounts"
            breadcrumb="Account"
            redirect="/pub/customers/accounts"
        />
    )
}
export const getUserRole = (user, toUpperCase = false) => {
    if (!user) {
        return 'Role not found';
    }
    
    if (user.role_alias) {
        return user.role_alias
    }

    const userRole = user.role;

    if (toUpperCase) {
        return userRole.toUpperCase();
    }

    if (userRole && userRole !== 'undefined' && userRole !== 'null') {
        return userRole.charAt(0).toUpperCase() + userRole.slice(1).toLowerCase();
    } else {
        return 'Role not found';
    }
}

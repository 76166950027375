import React from "react";
import NotFound from "../pages/NotFound";
import { Signin } from "../pages/Signin";
import Signup from "../pages/Signup";
import ChangePassword from "../pages/ChangePassword";
import CustomerDashboardIframe from "../iframes/customer-dashboard";
import CustomerAnalyticsIframe from "../iframes/customer-analytics";
import NotificationSettings from "../pages/NotificationSettings";
import CustomerClicksIframe from "../iframes/customer-clicks";

const publicRoutes = [
  { path: "pages/signin", element: <Signin /> },
  { path: "pages/signup", element: <Signup /> },
  { path: "/change-password", element: <ChangePassword /> },
  { path: "pages/error-404", element: <NotFound /> },
  { path: "notifications/settings", element: <NotificationSettings /> },
  { path: "customer", element: <CustomerDashboardIframe /> },
  { path: "customer-analytics", element: <CustomerAnalyticsIframe /> },
  { path: "customer-clicks", element: <CustomerClicksIframe /> }
];

export default publicRoutes;
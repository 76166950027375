import React, { useEffect, useState } from "react";
import {
  Col,
  Card,
  ProgressBar,
  Nav,
  Placeholder,
  Table,
} from "react-bootstrap";
import { distributeAmount } from "../../../../helpers/distribute-amount";
import { formatThousand } from "../../../../helpers/thousand-number-format";
import getSpecificInventoryPositions from "../../../../services/micro/get_specific_inventory_positions";
import { abbreviationsMap } from "../../../../modules/maps";
import {
  CookiesKeyNames,
  isCookiesEnabled,
} from "../../../../contexts/CookiesContext";
import Cookies from "universal-cookie";

const cookies = new Cookies();

function getPublisherId() {
  const searchParams = new URLSearchParams(window.location.search);
  const urlDomain = window.location.hostname;
  const pubParam = searchParams.get("pub");
  const publisherIdFromCookies = isCookiesEnabled
    ? cookies.get(CookiesKeyNames.publisherId)
    : localStorage.getItem(CookiesKeyNames.publisherId);

  if (pubParam) {
    if (abbreviationsMap[pubParam]) {
      return abbreviationsMap[pubParam];
    } else {
      return pubParam;
    }
  }

  if (urlDomain === "ppc.hoteltechreport.com") {
    return 67;
  }

  if (publisherIdFromCookies) {
    return Number(publisherIdFromCookies);
  }

  return 1;
}

export default function SpecificInventoryPositionsChart({
  inventoryId,
  colors,
  currentPeriod
}) {
  const [response, setResponse] = useState();
  const [reset, setReset] = useState(0);
  const publisher_id = getPublisherId();

  useEffect(() => {
    if (inventoryId) {
      if (currentPeriod && currentPeriod[0] && currentPeriod[1]) {
        getSpecificInventoryPositions(inventoryId, currentPeriod).then((response) => {
          console.log("SpecificInventoryPositionsChart", response);
          setResponse(response.data);
        });
      }
    }
  }, [currentPeriod]);

  if (!response) {
    return (
      <React.Fragment>
        <Placeholder
          as={Card.Title}
          animation="glow"
          style={{ color: "var(--bs-body-color)" }}
        >
          <Placeholder style={{ height: 400, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  } else {
    const dataWithPercent = distributeAmount(
      response.total,
      response.positions,
      colors,
      publisher_id
    );

    return (
      <Col lg="12" xl="5">
        <Card className="click-distribution card-one">
          <Card.Header>
            <Card.Title as="h6">Click Destribution by Position</Card.Title>
            <Nav className="nav-icon nav-icon-sm ms-auto">
              <Nav.Link
                href=""
                onClick={() => {
                  setReset(reset + 1);
                }}
              >
                <i className="ri-refresh-line"></i>
              </Nav.Link>
            </Nav>
          </Card.Header>
          <Card.Body className="p-3" key={reset}>
            <label className="d-block fs-sm grey-text">
              <span className="click-distribution-total">
                {formatThousand(response.total)}
              </span>
            </label>
            <label className="d-block fs-sm mb-2 grey-text">
              <span>Clicks</span>
            </label>
            <ProgressBar className="ht-10 mb-4">
              {dataWithPercent.map((item, index) => (
                <ProgressBar
                  key={index}
                  now={item.percentValue}
                  style={{ backgroundColor: item.color }}
                />
              ))}
            </ProgressBar>
            <div
              style={{
                maxHeight: "265px",
                overflowY: "auto",
              }}
            >
              <Table className="table-ratings mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Position</th>
                    <th>Clicks</th>
                    <th>%</th>
                    <th>Missed</th>
                    <th>Rel. Opp.</th>
                  </tr>
                </thead>
                <tbody>
                  {dataWithPercent.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div
                          className="circle"
                          style={{ backgroundColor: item.color }}
                        >
                          <div className="dot"></div>
                        </div>
                      </td>
                      <td>
                        <strong>Position #{item.position}</strong>
                      </td>
                      <td className="d-flex justify-content-evenly">
                        {formatThousand(item.clicks)}
                      </td>
                      <td>{item.percent}</td>
                      <td className="d-flex justify-content-md-end">
                        {formatThousand(item.missedClicks)}
                      </td>
                      <td>
                        {Number(item.opportunity)
                          ? Number(item.opportunity).toFixed(1) + "x"
                          : "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  }
}

import whiteLoader from '../../../assets/gifs/white.gif';
import blackLoader from '../../../assets/gifs/short-black.gif';

export default function defaultLoader() {
    return( 
        <div style={{
            margin: 0,
            position: "absolute",
            top: "40%",
            left: "40%"
            }}>
                <img className="logoGif loaderDark" src={whiteLoader} alt="loading" style={{maxWidth: "250px"}}/>
                <img className="logoGif loaderLight" src={blackLoader} alt="loading" style={{maxWidth: "250px"}}/>
        </div>
        )
}
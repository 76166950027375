
export function YourBidCellRenderer({ value }) {
    if (value === "-") {
        return "No bid";
    }

    return value;
}
 

export function YourBidCellTooltipRenderer({ value }) {
    if (value === "-") {
        return "Your bid is not set or campaign is paused or not active";
    }

    return "Your bid is " + value;
}
 
export function BrandingNameRenderer({ value }) {
    const name = value.split("@")[0];
    let views = value.split("@")[1];
    const inventory_type = value.split("@")[2];

    let type = ''

    console.log(inventory_type)

    if (inventory_type == 1) {
        type = 'DEPARTMENT'
    } else if (inventory_type == 3) {
        type = 'HOMEPAGE'
    } else if (inventory_type == 4) {
        type = 'BANNER'
    } else if (inventory_type == 5) {
        type = 'CLUSTER'
    }

    if (views.includes(',')) {
        views = views.split(',')[0] + views.split(',')[1].slice(0, 1) + 'k';
    }

    return (
        <div className='d-flex flex-column'>
            <div style={{ lineHeight: '14px', marginTop: '5px', marginBottom: '5px' }}>
                {name}
                <div
                    className='badge bg-outline-secondary ms-2'
                    // title={`Current invetory recived ${visits} visits per last 30 days`}
                >
                    {views} Views
                </div>
            </div>
            <div 
                className="align-items-center text-secondary"
                style={{ fontSize: '12px', marginTop: '-15px' }}
            >
                <span className='fw-bold'>{type}</span>
            </div>
            {/* <div className="align-items-center" style={{ fontSize: '12px', lineHeight: '12px' }}>
                <div className='badge bg-outline-secondary badg'>{type}</div>
            </div> */}
        </div>
    );
}

 
import React, { useEffect, useState } from "react";
import { getPeriodRevenueTotals } from "../../../services/micro";
import { Card, Placeholder, Row, Col } from "react-bootstrap";

export default function Totals(props) {
  const { currentPeriod, previousPeriod } = props;
  const [response, setResponse] = useState();

  useEffect(() => {
    if (currentPeriod && previousPeriod) {
      if (currentPeriod[0] && currentPeriod[1] && previousPeriod[0] && previousPeriod[1]) {
        getPeriodRevenueTotals(currentPeriod, previousPeriod).then((res) => {
          const newResponse = [
            {
              label: "Customer Campaigns",
              value: res.data.campaigns.total_customer_campaigns_current_period,
              change: res.data.campaigns.difference,
              status: res.data.campaigns.status,
            },
            {
              label: "Customer Users",
              value: res.data.users.total_customer_users_current_period,
              change: res.data.users.difference,
              status: res.data.users.status,
            },
            {
              label: "Paid Clicks",
              value: res.data.clicks.total_clicks_for_current_period,
              change: res.data.clicks.difference,
              status: res.data.clicks.status,
            },
            {
              label: "Inventory Monetization",
              value: res.data.monetized.total_monetized_inventory_current_period,
              change: res.data.monetized.difference,
              status: res.data.monetized.status,
            },
          ];

          setResponse(newResponse);
        });
      }
    }
  }, [props]);

  if (response) {
    return (
      <React.Fragment>
        <Row className="g-3">
          {response &&
            response.map((card, index) => (
              <Col xs="6" xl="3" key={index}>
                <Card className="card-one">
                  <Card.Body>
                    <h2 className="card-value mb-1">{card.value}</h2>
                    <Card.Title
                      as="label"
                      className="d-block mb-1 fw-medium text-dark"
                    >
                      {card.label}
                    </Card.Title>
                    <small className="subtext">
                      <span
                        className={
                          "d-inline-flex text-" +
                          (card.status === "up" ? "success" : "danger")
                        }
                      >
                        {card.change}{" "}
                        <i
                          className={
                            "ri-arrow-" +
                            (card.status === "up" ? "up" : "down") +
                            "-line"
                          }
                        ></i>
                      </span>{" "}
                      than previous period
                    </small>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Row className="g-3" style={{color: "var(--bs-body-color)"}}>
          <Col xs="6" xl="3">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder style={{ height: 110, width: "100%" }} />
            </Placeholder>
          </Col>
          <Col xs="6" xl="3">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder style={{ height: 110, width: "100%" }} />
            </Placeholder>
          </Col>
          <Col xs="6" xl="3">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder style={{ height: 110, width: "100%" }} />
            </Placeholder>
          </Col>
          <Col xs="6" xl="3">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder style={{ height: 110, width: "100%" }} />
            </Placeholder>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

import React from "react";
import { Card, Row, Col, Button, OverlayTrigger, Tooltip, Placeholder } from "react-bootstrap";

export default function DefaultAuctionCreditBalanceCard(props) {
    const { 
        campaign, budgetLoading, handleShowBottom, days_diff
    } = props;

    const ppc_monthly_budget = campaign?.ppc_monthly_budget ? parseFloat(campaign?.ppc_monthly_budget?.replace(/,/g, '')) : 0;
    const ppc_monthly_spend = campaign?.ppc_monthly_spend ? parseFloat(campaign?.ppc_monthly_spend?.replace(/,/g, '')) : 0;
    const spendPercentage = Math.round((ppc_monthly_spend / ppc_monthly_budget) * 100);

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip placement="bottom">
                    {
                        campaign?.ppc_active ? "Click to adjust your budget" : "Please activate your campaign to adjust your budget"
                    }
                </Tooltip>
            }
            >
            {budgetLoading ? (
                <React.Fragment>
                <Placeholder
                    as={Card.Title}
                    animation="glow"
                    style={{ color: "var(--bs-body-color)" }}
                >
                    <Placeholder style={{ height: 100, width: "100%" }} />
                </Placeholder>
                </React.Fragment>
            ) : (
                <Card
                    className="card-one cursor-pointer"
                    onClick={campaign?.ppc_active ? handleShowBottom : null}
                >
                    <Card.Body>
                        <Row>
                            <Col xs="12">
                                <h3 className="card-value mb-0 flex justify-content-between">
                                <b>${campaign.ppc_monthly_budget}</b>
                                <Button variant="outline-primary" size="sm" disabled={!campaign?.ppc_active}>
                                    Update
                                </Button>
                                </h3>
                                <label className="card-title fw-medium text-dark mb-1">
                                    Monthly Budget
                                </label>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer style={{ height: '60px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Row>
                            <Col xs="12">
                                <div className="revenue-item">
                                    <div className="revenue-item-body mb-0 mt-1">
                                        <span className="d-block fs-11 text-secondary">${campaign?.ppc_monthly_spend} spent this month</span>
                                        <span className="d-block fs-11 text-secondary" style={{ width: '100px' }}>{days_diff == 1 ? "resets tomorrow" : "resets in " + days_diff + " days"}</span>
                                    </div>
                                    <div className="progress mt-1" style={{height: '6px'}}>
                                        {
                                            spendPercentage > 100 ? <div role="progressbar" className="progress-bar bg-danger" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: spendPercentage + "%"}}>
                                            </div> : spendPercentage > 75 ? <div role="progressbar" className="progress-bar bg-warning" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: spendPercentage + "%"}}>
                                            </div> : <div role="progressbar" className="progress-bar bg-success" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: spendPercentage + "%"}}>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            )}
        </OverlayTrigger>
    );
}